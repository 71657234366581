import React, { useState, useRef } from "react";
import { useQuery } from "react-query";
import debounce from "lodash.debounce";
import { connect } from "react-redux";
import _ from "../../i18n";
import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../common/Filter/TTPFilterHorizontalBar";
import { getCollaboratorsSuggestions } from "../../api";
import {
  DYNAMIC_TABLE_SORT_TYPES,
  DELETED_STATUS,
  // OFFSHORE_STATUS,
  CLIENT_GROUP_CRM_FILTER,
  CLIENT_CRM_TYPE_FILTER,
  COLLABORATOR_ROLE_FILTER,
  CLIENT_FACTURABLE_STATUS_FILTER,
} from "../../config";
import moment from "moment";

const DynamicTableFilter = ({
  token,
  filters,
  navCommunity,
  setFilters,
  lengthData,
  setPageSizeProp,
  pageSize,
  selectedYear,
}) => {
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [dynamicTableSearchWord, setDynamicTableSearchWord] = useState("");
  const [expandedYearsDiv, setExpandedYearsDiv] = useState(false);
  const yearsWrapperRef = useRef(null);

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderYearsLabel = () => {
    return selectedYear instanceof Array ? "2024" : selectedYear;
  };

  const handleYearsChange = (year) => {
    setFilters({ ...filters, year: year });
  };

  const handleSetDeletedStatus = (status) => {
    setFilters({ ...filters, deletedStatus: status });
  };

  const handleTypeCRMClientChange = (items) => {
    setFilters({ ...filters, clientCRMTypes: items });
  };

  const handleGroupCRMClientChange = (items) => {
    setFilters({
      ...filters,
      clientCRMGroup: items,
    });
  };

  const handleSortByClientChange = (items) => {
    setFilters({ ...filters, sortBy: items });
  };

  const handleCollaboratorsChange = (items) => {
    setFilters({ ...filters, collaborators: items });
  };

  const handleCollaboratorRoleChange = (item) => {
    setFilters({ ...filters, collaboratorRole: item });
  };
  const handleSetOffshoreStatus = (item) => {
    setFilters({ ...filters, offshoreStatus: item });
  };
  const handleFacturableStatutChange = (item) => {
    setFilters({ ...filters, facturableStatus: item });
  };

  const handleBudgetStatusChange = (selectedValues) => {
    if (selectedValues.length === 0) {
      setFilters({ ...filters, budgetStatus: ["ALL"] });
      return;
    }
  };

  const renderYearsSelect = (years) => (
    <div
      ref={yearsWrapperRef}
      className={`customed_select ${
        expandedYearsDiv ? "customed_select_opened" : ""
      }`}
    >
      <div className="customed_select_header">
        <span>{renderYearsLabel()}</span>
        <div className="year_actions">
          <span
            className="year_action"
            style={{ padding: "3px" }}
            onClick={() => setExpandedYearsDiv(!expandedYearsDiv)}
          >
            <TiArrowSortedDown />
          </span>
        </div>
      </div>
      {expandedYearsDiv && (
        <div className="customed_select_body">
          <div className="customed_select_body_options">
            {years.map((year, index) => (
              <div
                key={index}
                className="customed_select_body_option"
                onClick={() => handleYearsChange(year)}
              >
                {year}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  // const renderYearsSelect = (years) => {
  //   // const { expandedYearsDiv } = this.state;
  //   return (
  //     <div
  //       ref={yearsWrapperRef}
  //       className={
  //         "customed_select" +
  //         " " +
  //         (expandedYearsDiv === true ? "customed_select_opened" : "")
  //       }
  //     >
  //       <div className="customed_select_header">
  //         <span>{renderYearsLabel()}</span>
  //         <div className="year_actions">
  //           <div>
  //             <span
  //               className="year_action"
  //               onClick={handleDisplayYearSelectItems}
  //             >
  //               <i
  //                 className={
  //                   expandedYearsDiv === true
  //                     ? "icon icon-arrow-up"
  //                     : "icon icon-arrow-down"
  //                 }
  //               />
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //       {expandedYearsDiv === true && (
  //         <div className="customed_select_body">
  //           <div className="customed_select_body_options">
  //             {years.map((year, index) => (
  //               <div
  //                 key={index}
  //                 className="customed_select_body_option"
  //                 onClick={() => this.handleYearsChange(year)}
  //               >
  //                 {year}
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       )}
  //     </div>
  //   );
  // };

  const year = moment().year();
  const years = [
    `${year - 1}/${year - 2}`,
    `${year - 1}/${year - 1}`,
    `${year}/${year - 1}`,
    `${year}/${year}`,
  ];
  console.log("DYNAMIC_TABLE_SORT_TYPES====", DYNAMIC_TABLE_SORT_TYPES);

  const dynamicFilters = [
    // {
    //   grid: 3,
    //   type: "SELECT",
    //   options: {
    //     isSimple: true,
    //     placeholder: _("sortBy"),
    //     options: DYNAMIC_TABLE_SORT_TYPES,
    //     action: handleSortByClientChange,
    //     values: filters.sortBy,
    //   },
    // },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("Type Client"),
        options: CLIENT_CRM_TYPE_FILTER,
        values: filters.clientCRMTypes,
        action: handleTypeCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clients CRM Group"),
        options: CLIENT_GROUP_CRM_FILTER,
        values: filters.clientCRMGroup,
        defaultValues: [
          _("AiSBL"),
          _("ASBL"),
          _("ASBL - Consultation"),
          _("ASBL - Partenaire"),
          _("ASBL petite entreprise"),
          _("Contact"),
          _("Fondation privée"),
          _("Indépendants"),
          _("Indépendants - Consultation"),
          _("Indépendants petite entreprise"),
          _("Partenaire"),
          _("Particuliers"),
          _("Particuliers - Consultation"),
          _("Sociétés"),
          _("Sociétés - Partenaire"),
          _("Sociétés petite entreprise"),
        ],
        action: handleGroupCRMClientChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("clientState"),
        options: DELETED_STATUS,
        values: filters.deletedStatus,
        action: handleSetDeletedStatus,
      },
    },
    // {
    //   grid: 3,
    //   type: "SELECT",
    //   options: {
    //     isSimple: true,
    //     isClearable: true,
    //     placeholder: _("Offshore status"),
    //     options: OFFSHORE_STATUS,
    //     values: filters.offshoreStatus,
    //     action: handleSetOffshoreStatus,
    //   },
    // },
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("Statut Facturable"),
        options: CLIENT_FACTURABLE_STATUS_FILTER,
        values: filters.facturableStatus,
        action: handleFacturableStatutChange,
      },
    },
    // {
    //   grid: 3,
    //   type: "ASYNC_SELECT",
    //   options: {
    //     value: filters.collaborators,
    //     placeholder: _("collaborator"),
    //     // handleChange: this.handleCollaboratorChange,
    //     // loadSuggestions: this.loadCollaboratorSuggestions,
    //     // values: financialSheetFilter.collaborator,
    //   },
    // },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filters.collaborators,
        action: handleCollaboratorsChange,
      },
    },
    // financialSheetFilter.collaborator.selected.length > 0 &&
    {
      grid: 3,
      type: "SELECT",
      options: {
        isSimple: true,
        placeholder: _("Dossiers En tant que"),
        options: COLLABORATOR_ROLE_FILTER,
        values: filters.collaboratorRole,
        action: handleCollaboratorRoleChange,
      },
    },
  ];

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          label={_("nbrFolders")}
          nbResult={lengthData}
          marginLeft={"0.5em"}
          button
          filters={dynamicFilters}
          customAddOn={renderYearsSelect(years)}
          searchPlaceholder="Search by name or erp id"
          searchInputValue={dynamicTableSearchWord}
          handleSearchInputChange={(word) => setDynamicTableSearchWord(word)}
          // filterFormIsVisible={(isVisible) => setFilterClientGroup(isVisible)}
          pageSize={pageSize}
          handleListPageSizeChange={(size) => setPageSizeProp(size)}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default DynamicTableFilter;
