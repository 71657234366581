import React from "react";

export const CalendarIcon = ({
  size = 16,
  fill = "#18A0FB",
  className = "",
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33301 3.33333C2.96482 3.33333 2.66634 3.63181 2.66634 4V13.3333C2.66634 13.7015 2.96482 14 3.33301 14H12.6663C13.0345 14 13.333 13.7015 13.333 13.3333V4C13.333 3.63181 13.0345 3.33333 12.6663 3.33333H3.33301ZM1.33301 4C1.33301 2.89543 2.22844 2 3.33301 2H12.6663C13.7709 2 14.6663 2.89543 14.6663 4V13.3333C14.6663 14.4379 13.7709 15.3333 12.6663 15.3333H3.33301C2.22844 15.3333 1.33301 14.4379 1.33301 13.3333V4Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6667 0.667969C11.0349 0.667969 11.3333 0.966446 11.3333 1.33464V4.0013C11.3333 4.36949 11.0349 4.66797 10.6667 4.66797C10.2985 4.66797 10 4.36949 10 4.0013V1.33464C10 0.966446 10.2985 0.667969 10.6667 0.667969Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33366 0.667969C5.70185 0.667969 6.00033 0.966446 6.00033 1.33464V4.0013C6.00033 4.36949 5.70185 4.66797 5.33366 4.66797C4.96547 4.66797 4.66699 4.36949 4.66699 4.0013V1.33464C4.66699 0.966446 4.96547 0.667969 5.33366 0.667969Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.33301 6.66667C1.33301 6.29848 1.63148 6 1.99967 6H13.9997C14.3679 6 14.6663 6.29848 14.6663 6.66667C14.6663 7.03486 14.3679 7.33333 13.9997 7.33333H1.99967C1.63148 7.33333 1.33301 7.03486 1.33301 6.66667Z"
      fill={fill}
    />
  </svg>
);
