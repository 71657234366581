import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";

import { BriefCaseIcon } from "../common/Icons";
import PageHeader from "../common/PageHeader/PageHeader";
import TTPTab2 from "../common/Tab/TTPTab2";
import CapacityPlaningMatrix from "./Matrix";
import NotAuthorized from "../common/NotAuthorized";

const CapacityPlaningPage = () => {
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const { language } = useSelector((state) => state.params);
  const preferences =
    user && navCommunity && user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  let tabs = [
    {
      id: "CAPACITY_PLANING",
      label: "capacityPlaning",
      hasAccess: preferences.allowAccessCapacityPlanningTab === "1",
    },
    {
      id: "SYNTHESE",
      label: "synthese",
      hasAccess: preferences.allowAccessSynthesis === "1",
    },
  ];
  const accessibleTabs = tabs.filter(tab => tab.hasAccess);
  const [filter, setFilter] = useState({
    tab: accessibleTabs[0] && accessibleTabs[0].id,
    year: new Date().getFullYear().toString(),
  });

  if (preferences.allowAccessCapacityPlanning === "0") {
    return <NotAuthorized />;
  }

  const renderCapacityPlaningTabContent = () => {
    switch (filter.tab) {
      case "CAPACITY_PLANING":
        return (
          <CapacityPlaningMatrix
            token={token}
            navCommunity={navCommunity}
            language={language}
          />
        );
      case "SYNTHESE":
        return (
          <div className="no_data">
            <div className="title">{_("wip")}</div>
            <div className="subTitle">{_("wipWorkshop")}</div>
          </div>
        );
      default:
        return;
    }
  };
  return (
    <div className="page-content">
      <PageHeader
        header={_("capacityPlaning")}
        subHeader={_("subTitleCapacityPlaning")}
        icon={<BriefCaseIcon fill="#29394D" size="36" />}
      />
      <div className="tab-container">
        <TTPTab2
          tabs={accessibleTabs}
          width="fit-content"
          activeTab={filter.tab}
          selectTab={(item) => {
            setFilter({ ...filter, tab: item.id });
          }}
        />
      </div>

      <div className="content-body large-content">
        {renderCapacityPlaningTabContent()}
      </div>
    </div>
  );
};

export default CapacityPlaningPage;
