import React from "react";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";

import {
  URL_HASH_KEY
} from "../../../config";
import styles from "./DrawerIframe.module.scss";
import NotAuthorized from "../NotAuthorized";


const encryptAES = string => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var iv = CryptoJS.lib.WordArray.random(16);
  var salt = CryptoJS.lib.WordArray.random(256);
  var iterations = 999;
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var encrypted = CryptoJS.AES.encrypt(string, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

  var output = {
    ciphertext: encryptedString,
    iv: CryptoJS.enc.Hex.stringify(iv),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iterations: iterations,
  };

  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(output))
  );
};

const DrawerIframe = props => {
  const auth = useSelector(state => state.auth);
  const { src, params, hasAccess = "1" } = props;

  if (hasAccess === "0") {
    return <NotAuthorized />;
  }

  if (src) {
    let param = [...params, "token=" + auth.token];
    if (auth.user) {
      param = [...params, "token=" + auth.token, "userId=" + auth.user.id];
    }

    let srcUrl =
      src.indexOf("?") === -1
        ? src + "?params=" + encodeURIComponent(encryptAES(param.join()))
        : src + "&params=" + encodeURIComponent(encryptAES(param.join()));

    return (
      <>
        {/* <div id="iframe-loader" class={styles.loader}></div> */}
        <iframe
          id="drawer_iframe"
          allow="camera;microphone;autoplay;fullscreen"
          className={styles.iframe}
          allowFullScreen
          src={srcUrl}
          title="TAMTAM portal"
          // onLoad={() => {
          //   if (document.getElementById("iframe-loader")) {
          //     document.getElementById("iframe-loader").style.display = "none";
          //     document.getElementById("load-button").style.display = "flex";
          //   } else {
          //     document.getElementById("close-button").style.display = "flex";
          //   }
          // }}
        />
      </>
    );
  } else {
    return <div style={{ minHeight: "70vh" }} />;
  }
};

export default DrawerIframe;
