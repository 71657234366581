import React, { useState } from "react";

import { LoopIcon, IconClose } from "../Icons";
import _ from "../../../i18n";

const SearchBox = ({ filter, placeholder, nbResult, fetching, onChange }) => {
  const [visibleCount, setVisibleCount] = useState(false);
  const [inputValue, setInputValue] = useState(filter.keyWord);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onChange(event.target.value);
      setTimeout(() => {
        setVisibleCount(true);
      }, "400");
    }
  };

  const debounceSearchHandler = (event) => {
    setInputValue(event.target.value);
  };

  const clearAll = () => {
    setVisibleCount(false);
    setInputValue("");
    onChange("");
  };
  const getSearchValueStyle = () => {
    return {
      width: 44,
      height: 19,
      padding: 5,
      fontSize: 13,
      marginRight: 5,
      borderRadius: 13,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "rgb(255, 255, 255)",
      background: "rgb(109, 127, 146)",
    };
  };

  return (
    <div className="search-box">
      <span className="search-box__icon">
        <LoopIcon />
      </span>
      <input
        value={inputValue}
        placeholder={`${_(placeholder || "search")} ...`}
        onChange={(event) => debounceSearchHandler(event)}
        onKeyPress={(event) => handleKeyPress(event)}
      />
      {nbResult && visibleCount && !fetching ? (
        <div style={getSearchValueStyle()}>{nbResult}</div>
      ) : (
        ""
      )}
      {filter.keyWord && filter.keyWord.length > 0 && (
        <div onClick={() => clearAll()}>
          <IconClose size={9} />
        </div>
      )}
    </div>
  );
};

export default SearchBox;
