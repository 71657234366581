import React from "react";

export const LoopIcon = ({ size = 14 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8758 13.286L10.4736 9.88381C11.3863 8.83459 11.9388 7.46563 11.9388 5.9694C11.9388 2.67583 9.25987 0 5.9694 0C2.67583 0 0 2.67894 0 5.9694C0 9.25987 2.67894 11.9388 5.9694 11.9388C7.46563 11.9388 8.83459 11.3863 9.88381 10.4736L13.286 13.8758C13.3667 13.9565 13.4754 14 13.5809 14C13.6865 14 13.7951 13.9596 13.8758 13.8758C14.0372 13.7144 14.0372 13.4475 13.8758 13.286ZM0.835033 5.9694C0.835033 3.13836 3.13836 0.838138 5.9663 0.838138C8.79734 0.838138 11.0976 3.14146 11.0976 5.9694C11.0976 8.79734 8.79734 11.1038 5.9663 11.1038C3.13836 11.1038 0.835033 8.80044 0.835033 5.9694Z"
      fill="#6D7F92"
    />
  </svg>
);
