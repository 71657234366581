import axios from "axios";

import { TTP_API_URL } from "../config";

export const getEmailCollaborator = (collaboratorId, organizationId, token) => {

  const requestUrl = `${TTP_API_URL}/team/collaborator/email`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      collaboratorId: collaboratorId,
      organization: organizationId
    },
  });
}

export const sendGenericEmail = (
    token,
    recipients,
    type,
    lng,
    dynamicFields,
    organization,
    files
  ) => {
    const requestUrl = `${TTP_API_URL}/mailing/generic-email/send`;
    let formData = new FormData();
    formData.append("access_token", token);
    formData.append("recipients", JSON.stringify(recipients));
    formData.append("app", "EMAILING");
    formData.append("type", type);
    formData.append("language", lng);
    formData.append("dynamicFields", JSON.stringify(dynamicFields));
    formData.append("organization", organization);
    if (type === "APPOINTMENT") {
      for (let i = 0; i < files.length; i++) {
        formData.append(`file[${i}]`, files[i], files[i].name);
      }
    }
  
    return axios.post(requestUrl, formData);
  };