import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import DrawerIframe from "../common/DrawerIframe";

import { TTP_TEAM_URL } from "../../config";
import { getCollaborator } from "../../api";

const CollaboratorTimeSheet = () => {
  const { token, navCommunity, user } = useSelector((state) => state.auth);
  const [collaborator, setCollaborator] = useState(null);

  let collboratorQuery = useQuery(
    ["getCollaborator", token, navCommunity, user],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaborator(
            null,
            navCommunity.id,
            token,
            user.uid
          );
          setCollaborator(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const params = [];
  let powerTeamUrl = TTP_TEAM_URL + "/collaborator-time-sheet";

  if (navCommunity && collaborator) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + navCommunity.id);
    return <DrawerIframe src={powerTeamUrl+"/"+collaborator.id+"/"+collaborator.name} params={params} />;
  }
  return <></>;
};

export default CollaboratorTimeSheet;
