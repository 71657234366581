import React from "react";

export const ChevronDown = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.41205 6.91009C4.73748 6.58466 5.26512 6.58466 5.59056 6.91009L10.0013 11.3208L14.412 6.91009C14.7375 6.58466 15.2651 6.58466 15.5906 6.91009C15.916 7.23553 15.916 7.76317 15.5906 8.0886L10.5906 13.0886C10.2651 13.414 9.73748 13.414 9.41205 13.0886L4.41205 8.0886C4.08661 7.76317 4.08661 7.23553 4.41205 6.91009Z"
      fill="#29394D"
    />
  </svg>
);

