import { Image, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import {
  FaAngleDown,
  FaAngleUp,
  FaArrowCircleDown,
  FaArrowCircleUp,
} from "react-icons/fa";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getCollaborator,
  getCPProgression,
  getProfitProgression,
  getScheduleProgression,
} from "../../../api";
import {
  formatNumber,
  renderAvatar,
  renderDurationDisplay,
  renderTeamYears,
} from "../../../utils";
import WavingHand from "../assets/wavingHand.svg";
import styles from "./Dashboard.module.scss";
import Stats from "./Stats";
import _ from "../../../i18n";
import CapacityProduction from "./CapacityProduction";
import RedirectCard from "../components/RedirectCard/RedirectCard";
import { Carousel } from "primereact/carousel";

const regionTabs = [
  { label: _("morocco"), value: 2 },
  { label: _("belgium"), value: 1 },
];

const folderTabs = [
  { label: _("schedule"), value: "SCHEDULE" },
  { label: _("volumetry"), value: "VOLUMETRY" },
];

const financialTabs = [
  { label: _("turnover"), value: "CA" },
  { label: _("profitability"), value: "PROFIT" },
];

export default function DashboardPartner() {
  // const [searchParams] = useSearchParams();
  // const partnerId = searchParams.get("partner");

  const ref = useRef();

  const { token, user, navCommunity } = useSelector((state) => state.auth);
  const { language: lang } = useSelector((state) => state.params);

  const [partner, setPartner] = useState(null);
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [progression, setProgression] = useState({
    schedule: [],
    volumetry: [],
    ca: [],
    profit: [],
    cp: {
      be: [],
      ma: [],
    },
  });
  const [filter, setFilter] = useState({
    year: new Date().getFullYear(),
    regionTab: regionTabs[0].value,
    folderTab: folderTabs[0].value,
    financialTab: financialTabs[0].value,
    cpVisibleItems: 3,
    expanded: null,
  });

  const handleLoadMore = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      cpVisibleItems: prevFilter.cpVisibleItems + 2,
    }));
  };

  const handleLoadLess = () => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      cpVisibleItems: prevFilter.cpVisibleItems - 2,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setYearDivIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setYearDivIsVisible]);

  useQuery(["collaboratorQuery", token, navCommunity], async () => {
    if (token && user) {
      try {
        const response = await getCollaborator(
          null,
          navCommunity.id,
          token,
          user.uid,
          user.adminisId.split(":")[1]
        );
        setPartner(response.data.data);
      } catch (e) {
        setPartner(null);
        return e;
      }
    }
  });

  const scheduleProgression = useQuery(
    ["getScheduleProgressionQuery", token, navCommunity, user],
    async () => {
      if (token && navCommunity && filter.year) {
        try {
          const response = await getScheduleProgression(
            token,
            navCommunity,
            user,
            filter.year,
            filter.folderTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            [filter.folderTab.toLowerCase()]:
              data[filter.folderTab.toLowerCase()],
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!user,
    }
  );

  useEffect(() => {
    scheduleProgression.refetch();
  }, [filter.folderTab, filter.year]);

  const profitProgression = useQuery(
    ["getProfitProgressionQuery", token, navCommunity, user],
    async () => {
      if (token && navCommunity && filter.year) {
        try {
          const response = await getProfitProgression(
            token,
            navCommunity,
            user,
            filter.year,
            filter.financialTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            [filter.financialTab.toLowerCase()]:
              data[filter.financialTab.toLowerCase()],
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!user,
    }
  );

  useEffect(() => {
    profitProgression.refetch();
  }, [filter.financialTab, filter.year]);

  const productionProgression = useQuery(
    ["getProductionProgressionQuery", token, navCommunity, user],
    async () => {
      if (token && navCommunity && filter.year) {
        try {
          const response = await getCPProgression(
            token,
            navCommunity,
            user,
            filter.year,
            filter.regionTab
          );
          const data = response.data.data;
          setProgression((prevProgression) => ({
            ...prevProgression,
            cp: {
              ...prevProgression.cp,
              [filter.regionTab == 1 ? "be" : "ma"]: data,
            },
          }));
          return data;
        } catch (e) {
          return e.message;
        }
      }
    },
    {
      enabled: !!token && !!navCommunity && !!user,
    }
  );

  useEffect(() => {
    productionProgression.refetch();
  }, [filter.regionTab, filter.year]);

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => setFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const {
    regionTab,
    folderTab,
    financialTab,
    year,
    cpVisibleItems: visibleCount,
    expanded,
  } = filter;

  const alias = regionTab == 1 ? "be" : "ma";
  const visibleData = progression.cp[alias].slice(0, visibleCount);

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <div className={styles.container_header_salutation}>
          <Image
            src={WavingHand}
            alt="waving-hand"
            width={20}
            height={20}
            className={styles.waving_hand}
          />
          <span>
            Bonjour, <strong>{partner?.firstName}</strong>
          </span>
        </div>
        {renderYearSelect()}
      </div>
      {/* Dashboard content */}
      <div className={styles.main}>
        {/* Left */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 15,
            width: "75%",
          }}
        >
          <Stats
            token={token}
            organization={navCommunity}
            collaborator={partner}
            partner={user}
            year={year}
          />
          <CapacityProduction
            token={token}
            organization={navCommunity}
            partner={user}
            year={year}
          />
        </div>
        {/* Right */}
        <div className={styles.right_column}>
          {/* ----------- Capacity Production --------------  */}
          <RedirectCard
            title="Rapport annuel"
            to={`/${lang}/reporting?tab=TS_PROGRESSION`}
            width="100%"
            height="auto"
            borderColor="#F1F2F4"
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={regionTabs}
              numVisible={2}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      regionTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ width: "10px", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, regionTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {category.label}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_annual_report}>
              {productionProgression.isFetching
                ? renderCPSkeleton()
                : productionProgression.isFetched &&
                  visibleData.map((co) => (
                    <div className={styles.redirect_card_annual_report_card}>
                      <div
                        className={
                          styles.redirect_card_annual_report_card_profile
                        }
                      >
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_info
                          }
                        >
                          <div
                            className={
                              styles.redirect_card_annual_report_card_profile_info_avatar
                            }
                          >
                            <div
                              className={
                                styles.redirect_card_annual_report_card_profile_info_avatar_avatar
                              }
                            >
                              {renderAvatar(
                                co.avatar,
                                `${co.fname} ${co.lname}`
                              )}
                              <div
                                className={styles.progress}
                                style={{
                                  "--progress":
                                    (co.real.total * 100) / co.theoric,
                                }}
                              />
                            </div>
                            <div>
                              <strong>{`${co.fname} ${co.lname[0]}.`}</strong>
                              <em>{co.role}</em>
                            </div>
                          </div>
                          <div
                            className={
                              styles.redirect_card_annual_report_card_profile_info_diff
                            }
                          >
                            <div>
                              <span>Budg vs facturable</span>
                              <span>{`${
                                parseInt(co.budg_fact) > 0
                                  ? parseInt(co.budg_fact)
                                  : parseInt(co.budg_fact) * -1
                              } %`}</span>
                              {parseInt(co.budg_fact) > 0 ? (
                                <FaArrowCircleDown color="#FB1E5B" />
                              ) : (
                                <FaArrowCircleUp color="#06D9B1" />
                              )}
                            </div>
                            <div>
                              <span>Réalisé vs attendu</span>
                              <span>{`${
                                parseInt(co.real_theoric) > 0
                                  ? parseInt(co.real_theoric)
                                  : parseInt(co.real_theoric) * -1
                              } %`}</span>
                              {parseInt(co.real_theoric) > 0 ? (
                                <FaArrowCircleDown color="#FB1E5B" />
                              ) : (
                                <FaArrowCircleUp color="#06D9B1" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            styles.redirect_card_annual_report_card_profile_progress
                          }
                        >
                          <div
                            className={
                              styles.redirect_card_annual_report_card_profile_progress_details
                            }
                          >
                            <div>
                              <span>C.P:</span>
                              <span>{`${parseInt(
                                (co.real.total * 100) / co.theoric
                              )} %`}</span>
                            </div>
                            <div>
                              <span>V.A:</span>
                              <span>100</span>
                              <div className={styles.va_bar_container}>
                                <Tooltip
                                  title={`${parseInt(co.real.wVA.percent)}%`}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      width: `${co.real.wVA.percent}%`,
                                      background: "black",
                                    }}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title={`${parseInt(co.real.fVA.percent)}%`}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      width: `${co.real.fVA.percent}%`,
                                      background: "#FB1E5B",
                                    }}
                                  />
                                </Tooltip>

                                <Tooltip
                                  title={`${parseInt(co.real.mVA.percent)}%`}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      width: `${co.real.mVA.percent}%`,
                                      background: "#FFAC3A",
                                    }}
                                  />
                                </Tooltip>

                                <Tooltip
                                  title={`${parseInt(co.real.hVA.percent)}%`}
                                  placement="top"
                                >
                                  <div
                                    style={{
                                      width: `${co.real.hVA.percent}%`,
                                      background: "#06D9B1",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              styles.redirect_card_annual_report_card_profile_progress_arrow
                            }
                            onClick={() =>
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                expanded: co.id,
                              }))
                            }
                            style={{
                              transform:
                                expanded == co.id
                                  ? "rotate(180deg)"
                                  : "rotate(0)",
                            }}
                          >
                            <FaAngleDown
                              width={16}
                              height={16}
                              color="#29394D"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className={styles.redirect_card_annual_report_details}
                        style={{
                          display: expanded == co.id ? "flex" : "none",
                          padding: expanded == co.id ? "20px 0" : 0,
                        }}
                      >
                        <div
                          className={
                            styles.redirect_card_annual_report_details_row
                          }
                        >
                          <div>
                            <span>{_("budgeted")}</span>
                            <span>{`${formatNumber(co.budgeted / 60)} h`}</span>
                          </div>
                          <div>
                            <span>{_("real")}</span>
                            <span>{`${formatNumber(
                              co.real.total / 60
                            )} h`}</span>
                          </div>
                          <div>
                            <span>{_("expected")}</span>
                            <span>{`${formatNumber(co.theoric / 60)} h`}</span>
                          </div>
                        </div>
                        <div className={styles.polygon} />
                        <div
                          className={
                            styles.redirect_card_annual_report_details_billable
                          }
                        >
                          <div>
                            <span>{`${formatNumber(
                              co.real.billable / 60
                            )} h`}</span>
                            <span>{_("billable")}</span>
                          </div>
                          <div>
                            <span>{`${formatNumber(
                              co.real.non_billable / 60
                            )} h`}</span>
                            <span>{_("nonBillable")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            {visibleData.length < progression.cp[alias].length ? (
              <button
                className={styles.redirect_card_load_btn}
                onClick={handleLoadMore}
              >
                <span>{_("seeMore")}</span>
                <FaAngleDown width={20} height={20} />
              </button>
            ) : (
              <button
                className={styles.redirect_card_load_btn}
                onClick={handleLoadLess}
              >
                <span>{_("seeLess")}</span>
                <FaAngleUp width={20} height={20} />
              </button>
            )}
          </RedirectCard>

          {/* ---------- Hour Budget & Volumetry -----------  */}
          <RedirectCard
            title="Gestion des dossiers"
            to=""
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              borderColor: "#F1F2F4",
            }}
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={folderTabs}
              numVisible={2}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      folderTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ width: "10px", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, folderTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {category.label}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_progression}>
              {scheduleProgression.isFetching
                ? renderSkeleton()
                : scheduleProgression.isFetched &&
                  progression[folderTab.toLowerCase()] && (
                    <div className={styles.redirect_card_progression_type}>
                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {folderTab == "SCHEDULE"
                          ? progression.schedule?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div>{client.name}</div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span style={{ fontSize: 10 }}>{`${parseInt(
                                      client.hour_real
                                    )}h/${parseInt(client.hour_budget)}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )
                          : progression.volumetry?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span
                                      className={styles.volum_ecart}
                                      style={{
                                        backgroundColor: "#eb57571a",
                                        color: "#FB1E5B",
                                      }}
                                    >{`${parseInt(
                                      client.volY - client.volY_1
                                    )}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[folderTab.toLowerCase()]?.exceed
                              ?.rest_count
                          } en dépassement ${_(folderTab.toLowerCase())}`}
                        </div>
                      </div>

                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {folderTab == "SCHEDULE"
                          ? progression.schedule?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span style={{ fontSize: 10 }}>{`${parseInt(
                                    client.hour_real
                                  )}h/${parseInt(client.hour_budget)}`}</span>
                                  <span
                                    style={{ width: "50px" }}
                                  >{`${formatNumber(
                                    parseInt(client.diff)
                                  )} %`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))
                          : progression.volumetry?.top?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span
                                      className={styles.volum_ecart}
                                      style={{
                                        backgroundColor: "#02af8e1a",
                                        color: "#06D9B1",
                                      }}
                                    >{`${parseInt(
                                      client.volY - client.volY_1
                                    )}`}</span>
                                    <span
                                      style={{ width: "50px" }}
                                    >{`${formatNumber(
                                      parseInt(client.diff)
                                    )} %`}</span>
                                    <FaArrowCircleUp color="#06D9B1" />
                                  </div>
                                </div>
                              )
                            )}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[folderTab.toLowerCase()]?.top
                              ?.rest_count
                          } en top ${_(folderTab.toLowerCase())}`}
                        </div>
                      </div>
                    </div>
                  )}
            </div>
          </RedirectCard>

          {/* --------------- CA & Profit -----------------  */}
          <RedirectCard
            title="Financial"
            to=""
            style={{
              marginTop: "20px",
              width: "100%",
              height: "auto",
              borderColor: "#F1F2F4",
            }}
          >
            <Carousel
              style={{
                width: "100%",
                padding: 0,
                marginBottom: 10,
                height: "auto",
              }}
              value={financialTabs}
              numVisible={2}
              numScroll={0}
              showNavigators={false}
              itemTemplate={(category, i) => {
                return (
                  <div
                    key={i}
                    className={`tab_badge ${
                      financialTab === category.value ? "tab_badge_active" : ""
                    }`}
                    style={{ width: "10px", padding: "5px 0" }}
                    onClick={() =>
                      setFilter({ ...filter, financialTab: category.value })
                    }
                  >
                    <div style={{ textTransform: "capitalize" }}>
                      {category.label}
                    </div>
                  </div>
                );
              }}
            />

            <div className={styles.redirect_card_progression}>
              {profitProgression.isFetching
                ? renderSkeleton()
                : profitProgression.isFetched &&
                  progression[financialTab.toLowerCase()] && (
                    <div className={styles.redirect_card_progression_type}>
                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {financialTab == "CA"
                          ? progression.ca?.exceed?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div>{client.name}</div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff) * -1
                                  )} €`}</span>
                                  <FaArrowCircleDown color="#FB1E5B" />
                                </div>
                              </div>
                            ))
                          : progression.profit?.exceed?.details.map(
                              (client) => (
                                <div
                                  className={
                                    styles.redirect_card_progression_type_child_card
                                  }
                                >
                                  <div style={{ width: "50%" }}>
                                    {client.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 10,
                                    }}
                                  >
                                    <span>{`${formatNumber(
                                      parseInt(client.diff) * -1
                                    )} €`}</span>
                                    <FaArrowCircleDown color="#FB1E5B" />
                                  </div>
                                </div>
                              )
                            )}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[financialTab.toLowerCase()]?.exceed
                              ?.rest_count
                          } en échec ${_(financialTab.toLowerCase())}`}
                        </div>
                      </div>

                      <div
                        className={styles.redirect_card_progression_type_child}
                      >
                        {financialTab == "CA"
                          ? progression.ca?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff)
                                  )} €`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))
                          : progression.profit?.top?.details.map((client) => (
                              <div
                                className={
                                  styles.redirect_card_progression_type_child_card
                                }
                              >
                                <div style={{ width: "50%" }}>
                                  {client.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 10,
                                  }}
                                >
                                  <span>{`${formatNumber(
                                    parseInt(client.diff)
                                  )} €`}</span>
                                  <FaArrowCircleUp color="#06D9B1" />
                                </div>
                              </div>
                            ))}
                        <div
                          className={
                            styles.redirect_card_progression_type_child_info
                          }
                        >
                          {`+ ${
                            progression[financialTab.toLowerCase()]?.top
                              ?.rest_count
                          } en top ${_(financialTab.toLowerCase())}`}
                        </div>
                      </div>
                    </div>
                  )}
            </div>
          </RedirectCard>
        </div>
      </div>
    </div>
  );
}

// Skeleton Loaders
const renderSkeleton = () => {
  return (
    <div
      className={`${styles.redirect_card_progression_type} ${styles.skeleton}`}
    >
      <div className={styles.skeleton_child}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={`skeleton-exceed-${index}`}
            className={styles.skeleton_child_card}
          >
            <div className={styles.skeleton_text}></div>
            <div className={styles.skeleton_detail}>
              <div className={styles.skeleton_bar}></div>
              <div className={styles.skeleton_percentage}></div>
              <div className={styles.skeleton_icon}></div>
            </div>
          </div>
        ))}
        <div className={styles.skeleton_child_info}></div>
      </div>

      <div className={styles.skeleton_child}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div
            key={`skeleton-top-${index}`}
            className={styles.skeleton_child_card}
          >
            <div className={styles.skeleton_text}></div>
            <div className={styles.skeleton_detail}>
              <div className={styles.skeleton_bar}></div>
              <div className={styles.skeleton_percentage}></div>
              <div className={styles.skeleton_icon}></div>
            </div>
          </div>
        ))}
        <div className={styles.skeleton_child_info}></div>
      </div>
    </div>
  );
};

const renderCPSkeleton = () => {
  return (
    <div
      className={`${styles.redirect_card_annual_report_card} ${styles.skeleton}`}
    >
      <div className={styles.skeleton_profile}>
        <div className={styles.skeleton_avatar}></div>
        <div className={styles.skeleton_info}>
          <div className={styles.skeleton_name}></div>
          <div className={styles.skeleton_role}></div>
        </div>
      </div>
      <div className={styles.skeleton_progress}>
        <div className={styles.skeleton_progress_bar}></div>
        <div className={styles.skeleton_progress_arrow}></div>
      </div>
      <div
        className={`${styles.redirect_card_annual_report_details} ${styles.skeleton_details}`}
      >
        <div className={styles.skeleton_detail_row}>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
        </div>
        <div className={styles.skeleton_polygon}></div>
        <div className={styles.skeleton_billable}>
          <div className={styles.skeleton_billable_item}></div>
          <div className={styles.skeleton_billable_item}></div>
        </div>
      </div>
      <br />
      <div className={styles.skeleton_profile}>
        <div className={styles.skeleton_avatar}></div>
        <div className={styles.skeleton_info}>
          <div className={styles.skeleton_name}></div>
          <div className={styles.skeleton_role}></div>
        </div>
      </div>
      <div className={styles.skeleton_progress}>
        <div className={styles.skeleton_progress_bar}></div>
        <div className={styles.skeleton_progress_arrow}></div>
      </div>
      <div
        className={`${styles.redirect_card_annual_report_details} ${styles.skeleton_details}`}
      >
        <div className={styles.skeleton_detail_row}>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
          <div className={styles.skeleton_detail_item}></div>
        </div>
        <div className={styles.skeleton_polygon}></div>
        <div className={styles.skeleton_billable}>
          <div className={styles.skeleton_billable_item}></div>
          <div className={styles.skeleton_billable_item}></div>
        </div>
      </div>
    </div>
  );
};
