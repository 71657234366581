import { React, useState, useEffect } from "react";
import styles from "./Budget.module.scss";
import classNames from "classnames";
import _ from "../../i18n";
import Switch from "../common/Switch/Switch";
import moment from "moment";

// import DatePicker from "react-datepicker"
import { DatePicker } from "@gsebdev/react-simple-datepicker";
import { CalendarIcon } from "../common/Icons";
import { Calendar } from "primereact/calendar";
const Settings = ({
  preferences,
  dataSettings,
  startCategories,
  setStartCategories,
  departCategories,
  setDepartCategories,
  selectedStartDates,
  setSelectedStartDates,
  selectedDepartDates,
  setSelectedDepartDates,

  setStartDatesActivated,
  departActivated,
  startDatesActivated,
  departDatesActivated,
  setDepartDatesActivated,
  startDatesFacturation,
  setStartDatesFacturation,
  startDatesTask,
  setStartDatesTask,
  departDatesFacturation,
  setDepartDatesFacturation,
  departDatesTask,
  setDepartDatesTask,
  preferentialTariff,
  setPreferentialTariff,
  setUpdated,
  setNormalVoyant,
  setMediumVoyantFrom,
  setMediumVoyantTo,
  setHighVoyant,
  normalVoyant,
  mediumVoyantFrom,
  mediumVoyantTo,
  highVoyant,
  communicatedDeparture,
  setCommunicatedDeparture
}) => {
  const [selectedDate, setSelectedDate] = useState(
    dataSettings.start_dates.start_invoice
  );

  const [active, setActive] = useState("");

  const switchhh = (checked, toSet) => {
    return (
      <div>
        <input
          checked={checked}
          onChange={() => {
            // if (toSet === "newClient") {
            //   setStartDatesActivated(!startDatesActivated);
            // } else
            if (toSet === "depart") {
              setDepartDatesActivated(!departDatesActivated);
            } else setPreferentialTariff(!preferentialTariff);
          }}
          className={styles["ttp-switch"]}
          type="checkbox"
        />
        <div>
          <span>
            <span className="icon icon-toolbar grid-view" />
          </span>
          <span>
            <span className="icon icon-toolbar ticket-view" />
          </span>
          <div />
        </div>
      </div>
    );
  };

  const handleChangeValues = (rowIndex, column, list) => {
    if (list === "start") {
      setStartCategories((prevStartCategories) => {
        return prevStartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    } else {
      setDepartCategories((prevDepartCategories) => {
        return prevDepartCategories.map((item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              [column]: !item.checked,
            };
          } else {
            return item;
          }
        });
      });
    }
    setUpdated(true);
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      <div className={styles.title}>Paramètre client</div>
      <div className="grid-x">
        <div className="cell small-4 medium-4 large-3">
          <div
            className={classNames(
              styles.group_info,
              active === "NewClient" && styles.selected_info
            )}
            onClick={() => setActive("NewClient")}
          >
            {(preferences.allowUpdatingNewClients==="1") &&
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Nouveau client :</span>
                  <div>
                    <Switch
                      isChecked={startDatesActivated}
                      onChange={() =>
                        setStartDatesActivated(!startDatesActivated)
                      }
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          {(preferences.allowUpdatingDepartClients==="1") &&
            <div
              className={classNames(
                styles.group_info,
                active === "Depart" && styles.selected_info
              )}
              onClick={() => setActive("Depart")}
            >
              <div className={styles.info}>
                <div className={styles.info_content}>
                  <span>Départ client :</span>
                  <div>{switchhh(departDatesActivated, "depart")}</div>
                </div>
              </div>
            </div>
          }
           {(preferences.allowUpdatingTarifClients==="1") &&
          <div className={styles.info}>
            <div className={styles.info_content}>
              <span>Tarif préférentiel/ friendzone :</span>
              <div>{switchhh(preferentialTariff, "preferentialTariff")}</div>
            </div>
          </div>}
          <div className={styles.info}>
            <div className={styles.info_content}>
              <span>Personne lié :</span>
              {dataSettings.attached.length > 0 &&
                dataSettings.attached.map((item, i) => {
                  return <div>{item.name}</div>;
                })}
            </div>
          </div>
          {(preferences.allowUpdatingBillingView==="1") &&
            <div className={styles.info} style={{ height: "auto" }}>
              <div className={styles.info_content}>
                <span>Voyant de facturation :</span>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#06D9B1" }}
                  ></span>
                  <div className={styles.voyant}>
                    {"< "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={normalVoyant}
                      onChange={(event) => setNormalVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EA8124" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {"de "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantFrom}
                      onChange={(event) => setMediumVoyantFrom(event.target.value)}
                    />
                    {" %"}
                  </div>
                  <div className={styles.voyant}>
                    {"a "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={mediumVoyantTo}
                      onChange={(event) => setMediumVoyantTo(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
                <div className={styles.voyant_goup}>
                  <span
                    className={styles.span}
                    style={{ background: "#EB5757" }}
                  ></span>{" "}
                  <div className={styles.voyant}>
                    {" > "}
                    <input
                      type="number"
                      style={{ width: "46px" }}
                      className={classNames(styles.input)}
                      value={highVoyant}
                      onChange={(event) => setHighVoyant(event.target.value)}
                    />
                    {" %"}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {active !== "" && (
          <div
            className={classNames("cell small-4 medium-4 large-9")}
            style={{}}
          >
            <div
              className={styles.line}
              style={{
                height: "auto",
                display: "flex",
              }}
            >
              <div className={styles.settings}>
                <div className={styles.title_settings}>
                  {" "}
                  {active === "NewClient"
                    ? " Nouveau client :"
                    : "Depart client :"}
                </div>
                <div>
                  <div className={styles.calendar_content}>
                    <div className={styles.date_title}>
                      {active === "NewClient"
                        ? "Début de facturation"
                        : "Fin de facturation"}
                    </div>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={moment(
                        active === "NewClient"
                          ? startDatesFacturation
                          : departDatesFacturation,
                          "DD-MM-YYYY"
                      ).toDate()}
                      onChange={(e) => {
                        if (active === "NewClient") {
                          setStartDatesFacturation(
                            moment(e.value).format("DD-MM-YYYY")
                          );
                        } else {
                          setDepartDatesFacturation(
                            moment(e.value).format("DD-MM-YYYY")
                          );
                        }
                      }}
                    />
                    <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
                  </div>
                  <div className={styles.calendar_content}>
                    <div className={styles.date_title}>
                      {active === "NewClient"
                        ? "Début de prestation"
                        : "Fin de prestation"}
                    </div>
                    <Calendar
                      dateFormat="dd/mm/yy"
                      value={moment(
                        active === "NewClient"
                          ? startDatesTask
                          : departDatesTask,
                          "DD-MM-YYYY"
                      ).toDate()}
                      onChange={(e) => {
                        if (active === "NewClient") {
                          setStartDatesTask(moment(e.value).format("DD-MM-YYYY"));
                        } else {
                          setDepartDatesTask(moment(e.value).format("DD-MM-YYYY"));
                        }
                      }}
                    />
                    <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />{" "}
                  </div>
                  <div className={styles.calendar_content}>
                  { active === "Depart" && 
                    <>
                      <div className={styles.date_title}>
                        {" Date de depart "}
                      </div>
                      <Calendar
                        dateFormat="dd/mm/yy"
                        value={moment( communicatedDeparture, "DD-MM-YYYY" ).toDate()}
                        onChange={(e) => {
                          if (active === "Depart") {
                            setCommunicatedDeparture(moment(e.value).format("DD-MM-YYYY"));
                          }
                        }}
                      />
                      <CalendarIcon className="w-6 h-6 text-gray-400 mr-2" />
                    </>
                  }
                  </div>
                </div>
              </div>
              <div className={styles.categories_list}>
                <div className={styles.categories}>
                  categories
                  <div>
                    {active === "NewClient"
                      ? startCategories.map((item, i) => {
                          return (
                            <div className={styles.category_item}>
                              <span>{item.label}</span>
                              <div className={styles.switch}>
                                <Switch
                                  isChecked={item.checked}
                                  onChange={() =>
                                    handleChangeValues(i, "checked", "start")
                                  }
                                />
                              </div>
                            </div>
                          );
                        })
                      : departCategories.map((item, i) => {
                          return (
                            <div className={styles.category_item}>
                              <span>{item.label}</span>
                              <div className={styles.switch}>
                                <Switch
                                  isChecked={item.checked}
                                  onChange={() =>
                                    handleChangeValues(i, "checked", "depart")
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
