import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import _ from "../../../i18n";
import { useOnClickOutside, renderTeamYears, getMonths } from "../../../utils";
import {
  getCollaboratorsSuggestions,
  getCollaboratorSettingSuggestions,
} from "../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";

const ReportingsFilter = ({ filter, nbResult, updateFilter, lng }) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity } = useSelector((state) => state.auth);

  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);

  let collaboratorsdQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let collaboratorSettingQuery = useQuery(
    ["zonesQuery", navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorSettingSuggestions(
            token,
            navCommunity.id
          );
          let dataZones = response.data.data.zones
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label,
              };
            });
          setZoneOptions(dataZones);
          let dataBUs = response.data.data.bus
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id,
                label: t.label,
              };
            });
          setBuOptions(dataBUs);
        } catch (e) {
          setBuOptions([]);
          setZoneOptions([]);
          return null;
        }
      }
    }
  );

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          zIndex: 0,
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("zone"),
        options: zoneOptions,
        values: filter.zone ? filter.zone.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.zone;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              zone: zoneOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu ? filter.bu.value : null,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.bu;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({
              ...filter,
              bu: buOptions.filter((option) => option.value == value)[0],
            });
          }
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborators,
        action: (items) => {
          updateFilter({ ...filter, collaborators: items });
        },
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("months"),
        options: getMonths(lng),
        values: filter.months,
        action: (value) => {
          if (value == null) {
            let cleanFilter = { ...filter };
            delete cleanFilter.months;
            updateFilter({ ...cleanFilter });
          } else {
            updateFilter({ ...filter, months: value });
          }
        },
      },
    },
  ];

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          bg="#FFF"
          subBG="F8F9FA"
          button
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default ReportingsFilter;
