import React from "react";

import Modal from "react-modal";
import _ from "../../../i18n";
import "./Modal.local.scss";

const ModalComponent = ({ isOpen, close, width, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      width={width}
      style={{ margin: "0 auto", zIndex: 100 }}
    >
      <div className="close" onClick={close}>
        x
      </div>
      {children}
    </Modal>
  );
};

export default ModalComponent;
