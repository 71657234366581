import React from "react";

export const AlertTriangleIcon = ({ size = "12", fill = "#EB5757" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.26546 1.14214C5.4901 1.01566 5.74355 0.949219 6.00135 0.949219C6.25915 0.949219 6.51261 1.01566 6.73725 1.14214C6.96189 1.26862 7.15015 1.45086 7.28385 1.67128L7.28529 1.67365L11.5203 8.74366L11.5243 8.75056C11.6553 8.97738 11.7246 9.23454 11.7254 9.49645C11.7261 9.75837 11.6582 10.0159 11.5285 10.2435C11.3988 10.471 11.2118 10.6606 10.9861 10.7934C10.7603 10.9263 10.5038 10.9977 10.2418 11.0006L10.2364 11.0006L1.76086 11.0006C1.49896 10.9977 1.24237 10.9263 1.01664 10.7934C0.790902 10.6606 0.603879 10.471 0.474178 10.2435C0.344476 10.0159 0.276616 9.75837 0.27735 9.49645C0.278083 9.23454 0.347384 8.97738 0.478358 8.75056L0.48242 8.74366L4.71885 1.67127C4.85255 1.45085 5.04081 1.26862 5.26546 1.14214ZM6.00135 1.94922C5.91542 1.94922 5.83094 1.97137 5.75605 2.01353C5.68151 2.05549 5.61899 2.11588 5.57446 2.18891L1.3427 9.2535C1.30011 9.3284 1.27759 9.41305 1.27735 9.49925C1.2771 9.58656 1.29972 9.6724 1.34296 9.74825C1.38619 9.8241 1.44853 9.88731 1.52378 9.93158C1.59836 9.97547 1.68305 9.99924 1.76955 10.0006H10.2332C10.3197 9.99924 10.4043 9.97547 10.4789 9.93158C10.5542 9.88731 10.6165 9.8241 10.6598 9.74825C10.703 9.6724 10.7256 9.58656 10.7254 9.49925C10.7251 9.41306 10.7026 9.32842 10.66 9.25353L6.42885 2.1899C6.42865 2.18957 6.42845 2.18924 6.42825 2.18891C6.38372 2.11588 6.3212 2.05549 6.24665 2.01353C6.17177 1.97137 6.08729 1.94922 6.00135 1.94922Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 4C6.27614 4 6.5 4.22386 6.5 4.5V6.5C6.5 6.77614 6.27614 7 6 7C5.72386 7 5.5 6.77614 5.5 6.5V4.5C5.5 4.22386 5.72386 4 6 4Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.5 8.5C5.5 8.22386 5.72386 8 6 8H6.005C6.28114 8 6.505 8.22386 6.505 8.5C6.505 8.77614 6.28114 9 6.005 9H6C5.72386 9 5.5 8.77614 5.5 8.5Z"
      fill={fill}
    />
  </svg>
);
