import React from "react";

export const WeightLiftingIcon = ({ size = 13, fill = "#29394D" }) => (
  <svg fill={fill} height={size} width={size} viewBox="0 0 64 64">
    <g id="Clocks_1_">
      <path
        id="Clocks"
        d="M63.022,26.237c-0.665-0.798-1.601-1.255-2.568-1.255H59v-1.174c0-2.109-1.832-3.826-4.083-3.826H53v-2.167
		c0-1.02-0.435-2.015-1.192-2.731c-1.506-1.423-4.109-1.423-5.615,0C45.435,15.801,45,16.796,45,17.816v10.167H19V17.816
		c0-1.02-0.434-2.015-1.192-2.731c-1.506-1.423-4.109-1.423-5.615,0C11.435,15.801,11,16.796,11,17.816v2.167H8.85
		c-1.011,0-1.967,0.365-2.693,1.028C5.422,21.686,5,22.628,5,23.594v1.388H3.56c-0.972,0-1.912,0.459-2.579,1.26
		C0.349,27.002,0,28.001,0,29.054v5.856c0,1.053,0.349,2.052,0.982,2.812c0.667,0.801,1.607,1.26,2.578,1.26H5v1.388
		c0,0.966,0.422,1.908,1.159,2.585c0.724,0.662,1.68,1.027,2.691,1.027H11v2.167c0,1.02,0.435,2.015,1.192,2.73
		c0.753,0.711,1.75,1.103,2.808,1.103s2.055-0.392,2.808-1.103C18.566,48.163,19,47.168,19,46.149V35.982h26v10.167
		c0,1.02,0.435,2.015,1.192,2.73c0.753,0.711,1.75,1.103,2.808,1.103c1.058,0,2.055-0.392,2.808-1.103
		C52.565,48.163,53,47.168,53,46.149v-2.167h1.917c2.251,0,4.083-1.716,4.083-3.826v-1.174h1.454c0.967,0,1.903-0.458,2.569-1.255
		c0.63-0.757,0.978-1.751,0.978-2.8v-5.889C64,27.989,63.653,26.994,63.022,26.237z M3.56,36.982c-0.381,0-0.751-0.192-1.041-0.54
		C2.189,36.047,2,35.488,2,34.91v-5.856c0-0.578,0.189-1.136,0.519-1.532c0.29-0.348,0.66-0.54,1.041-0.54H5v10H3.56z M8.85,41.982
		c-0.503,0-0.992-0.183-1.339-0.501C7.181,41.178,7,40.784,7,40.37v-2.388v-12v-2.388c0-0.414,0.181-0.809,0.509-1.11
		c0.35-0.319,0.838-0.502,1.341-0.502H11v20H8.85z M17,46.149c0,0.479-0.201,0.932-0.565,1.276c-0.75,0.708-2.119,0.708-2.869,0
		C13.201,47.081,13,46.627,13,46.149v-3.167v-22v-3.167c0-0.479,0.201-0.932,0.565-1.276c0.375-0.354,0.898-0.557,1.435-0.557
		c0.537,0,1.06,0.203,1.435,0.557C16.799,16.883,17,17.337,17,17.816v11.167v6V46.149z M19,33.982v-4h26v4H19z M51,46.149
		c0,0.479-0.201,0.932-0.565,1.276c-0.75,0.708-2.119,0.708-2.869,0C47.201,47.081,47,46.627,47,46.149V34.982v-6V17.816
		c0-0.479,0.201-0.932,0.565-1.276c0.375-0.354,0.898-0.557,1.435-0.557c0.537,0,1.06,0.203,1.435,0.557
		C50.799,16.883,51,17.337,51,17.816v3.167v22V46.149z M57,40.156c0,1.007-0.934,1.826-2.083,1.826H53v-20h1.917
		c1.148,0,2.083,0.819,2.083,1.826v2.174v12V40.156z M62,34.926c0,0.574-0.188,1.127-0.514,1.52
		c-0.288,0.346-0.655,0.536-1.032,0.536H59v-10h1.454c0.377,0,0.744,0.19,1.032,0.536C61.813,27.91,62,28.464,62,29.038V34.926z"
      />
    </g>
  </svg>
);
