import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getClientBudget } from "../api";

export const fetchClientBudget = createAsyncThunk(
  "clientBudget/fetchClientBudget",

  async ({ clientID, year }, { dispatch, getState }) => {
    const { token, navCommunity } = getState().auth;

    let organizationId = navCommunity.id;
    const response = await getClientBudget({
      token,
      organizationId,
      clientID,
      year,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

export const clientBudgetSlice = createSlice({
  name: "clientBudget",
  initialState,
  extraReducers: {
    [fetchClientBudget.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchClientBudget.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.data = payload.data.data;
    },
    [fetchClientBudget.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.data = [];
    },
  },
});

export default clientBudgetSlice.reducer;
