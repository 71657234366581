import React, { Fragment, useState } from "react";
import Table from "../../../Reporting/Echeances/Table";

import "./EncodingProgressionContent.local.scss";

const BalanceProgressionContent = ({
  id,
  year,
  token,
  organization,
  zoneId
}) => {
  const balance_types = ["IPM", "ISOC"];
  const [balanceRefresh, setBalanceRefresh] = useState(false);
  
  return (
    <div className="tva_bilan_content">
      <Table
        token={token}
        organization={organization}
        year={year}
        collaboratorId={id}
        type={"ISOC"}
        balance_types={balance_types}
        zoneId={zoneId}
        balanceRefresh={balanceRefresh}
        setBalanceRefresh={setBalanceRefresh}
      />
    </div>
  );
};

export default BalanceProgressionContent;
