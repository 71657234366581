import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCollaborators } from "../api";

export const fetchCollaborators = createAsyncThunk(
  "collaborators/fetchCollaborators",

  async (year, { dispatch, getState }) => {
    const { token, navCommunity } = getState().auth;

    let organizationId = navCommunity.id;
    const response = await getCollaborators({
      token,
      organizationId,
      year,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

export const collaboratorSlice = createSlice({
  name: "collaborators",
  initialState,
  extraReducers: {
    [fetchCollaborators.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchCollaborators.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.data = payload.data.data;
    },
    [fetchCollaborators.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.data = [];
    },
  },
});

export default collaboratorSlice.reducer;
