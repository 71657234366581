import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  categoryDefaultFilter: { id: 4, label: "04. Encodage" },
  selectedStartDateFilter: moment().subtract(15, "days")._d,
  selectedEndDateFilter: moment()._d,
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setCategoryDefaultFilter: (state, action) => {
      state.categoryDefaultFilter = action.payload;
    },
    setSelectedStartDateFilter: (state, action) => {
      state.selectedStartDateFilter = action.payload;
    },
    setSelectedEndDateFilter: (state, action) => {
      state.selectedEndDateFilter = action.payload;
    },
  },
});

export const {
  setClientsDefaultFilter,
  setCollaboratorsDefaultFilter,
  setRoleDefaultFilter,
  setCategoryDefaultFilter,
  setSelectedStartDateFilter,
  setSelectedEndDateFilter
} = filtersSlice.actions;

export default filtersSlice.reducer;
