import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, Tooltip } from "antd";

import {
  getCalendarRecap,
  saveCollaboratorAbsence,
  getCollaboratorsAvatars,
} from "../../../api";
import {
  CheckIcon,
  IconClose,
  SettingsIcon,
  CalendarIcon,
  ArrowLeftIcon,
  SwitchUserIcon,
  AlertTriangleIcon,
  ExclamationMarkIcon,
} from "../../common/Icons";
import _ from "../../../i18n";

import "./Calendar.local.scss";
import CalendarFilter from "./CalendarFilter";

const palette = {
  WEEKEND_DAY: {
    label: _("weekend"),
    background: "#FFF",
  },
  HOLIDAY_DAY: {
    label: _("holiday"),
    color: "#168BA4",
    background: "#B2BCC6",
  },
  VACANCE_DAY: {
    label: _("annualLeave"),
    background: "#93c6ff",
  },
  SICK_DAY: {
    label: _("sickLeave"),
    background: "#786bd9",
    color: "#DA830E",
  },
  ABSENCE_DAY: {
    label: _("absence"),
    background: "#f6953d",
  },
  BUSINISS_TRIP_DAY: {
    label: _("businessTrip"),
    background: "#CFF4AB",
  },
  REMOTE_DAY: {
    label: _("remoteDay"),
    background: "#AAC1E1",
  },
  WORKING_DAY: {
    label: _("present"),
    background: "#E7EDF6",
  },
  OUT_DAY: {
    label: _("outDay"),
    background: "#E5BAF4",
  },
  NONE_DAY: {
    label: _("none"),
    background: "#29394D",
  },
};

const COLLABORATOR_ROLE_ATTRIBUT = {
  supervisor: "SUPERVISOR",
  manager: "GD",
  encodingManager: "GE",
  scanOperator: "SCAN-OP",
  encodingSupervisor: "SE",
};

const CalendarRecap = ({ token, navCommunity, lng, user, hasAccess }) => {
  const [data, setData] = useState([]);
  const [avatars, setAvatars] = useState([]);
  const [columns, setColumns] = useState([]);
  const [filter, setFilter] = useState({
    year: new Date().getFullYear().toString(),
    month: new Date().getMonth(),
    collaborators: [],
    status: null,
    zone: null,
    role: null,
    bu: null,
  });

  let calendarQuery = useQuery(
    [("getCalendarRecap", token, navCommunity, filter)],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCalendarRecap(
            navCommunity.id,
            token,
            filter
          );
          setData(response.data.data);
          setColumns(Object.keys(response.data.data[0].data));
        } catch (e) {
          console.error(e);
          return null;
        }
      }
    }
  );

  let avatarsQuery = useQuery(
    ["getCollaboratorAvatars", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsAvatars(
            navCommunity.id,
            token
          );
          setAvatars(response.data.data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderCustumedTooltipContent = (day, id, type, data) => {
    let key = type.replace("_PENDING", "").replace("_BACKUP", "");
    key = key.replace("_BACKUP", "");
    switch (type) {
      case "WORKING_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }} />
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>{_("affecteAbsenceMessage")}:</span>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div className="action">
                <span>{_("affecteAbsence")}</span>
                <CalendarIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      case "WORKING_DAY_BACKUP":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <ExclamationMarkIcon fill={palette[key].color} size={15} />
              <span>
                {_("alertAbsenceBackupMessage")} <b>{data.absent}</b>
              </span>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div
                className="action"
                style={{ color: "#EB5757", fontSize: 11 }}>
                <span>{_("affecteAbsenceEven")}</span>
                <CalendarIcon fill="#EB5757" />
              </div>
            </Link>
          </div>
        );
      case "HOLIDAY_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }} />
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>
                {_("holiday")}: <b>{data.label}</b>
              </span>
            </div>
          </div>
        );
      case "ABSENCE_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>
                {_("absence")}: <b>{data.label}</b> {_("from")}{" "}
                <b>{data.from}</b> {_("to")} <b>{data.to}</b>
              </span>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div className="action">
                <span>{_("manageAbsence")}</span>
                <CalendarIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      case "ABSENCE_DAY_PENDING":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>
                {_("holiday")}: <b>{data.label}</b> {_("from")}{" "}
                <b>{data.from}</b> {_("to")} <b>{data.to}</b>
              </span>
            </div>
            <div className="message">
              <ExclamationMarkIcon fill={palette[key].color} size={15} />
              <span>{_("confirmationAbsenceMessage")}</span>
            </div>
            <div className="actions">
              <div
                className="action danger"
                onClick={() =>
                  handleRespondToAbsenceRequest(
                    id,
                    { ...data, type: "absence_2" },
                    "REJECTED"
                  )
                }>
                <span>{_("refuse")}</span>
                <IconClose size="9" />
              </div>
              <div
                className="action success"
                onClick={() =>
                  handleRespondToAbsenceRequest(
                    id,
                    { ...data, type: "absence_2" },
                    "APPROVED"
                  )
                }>
                <span>{_("accept")}</span>
                <CheckIcon width="13" height="9" />
              </div>
            </div>
          </div>
        );
      case "VACANCE_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message" style={{ flexDirection: "column" }}>
              <span>
                {_("paidLeave")}: {_("from")} <b>{data.from}</b> {_("to")}{" "}
                <b>{data.to}</b>
              </span>
              <span>
                {_("backup")}: <b>{data.backup}</b>
              </span>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div className="action">
                <span>{_("manageAbsence")}</span>
                <CalendarIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      case "VACANCE_DAY_PENDING":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>
                {_("holiday")}: <b>{data.label}</b> {_("from")}{" "}
                <b>{data.from}</b> {_("to")} <b>{data.to}</b>
              </span>
            </div>
            <div className="message">
              <ExclamationMarkIcon fill={palette[key].color} size={15} />
              <span>{_("confirmationAbsenceMessage")}</span>
            </div>
            <div className="actions">
              <div
                className="action danger"
                onClick={() =>
                  handleRespondToAbsenceRequest(
                    id,
                    { ...data, type: "absence_1" },
                    "REJECTED"
                  )
                }>
                <span>{_("refuse")}</span>
                <IconClose size="9" />
              </div>
              <div
                className="action success"
                onClick={() =>
                  handleRespondToAbsenceRequest(
                    id,
                    { ...data, type: "absence_1" },
                    "APPROVED"
                  )
                }>
                <span>{_("accept")}</span>
                <CheckIcon width="13" height="9" />
              </div>
            </div>
          </div>
        );
      case "SICK_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>Congé de maladie</span> {_("from")} <b>{data.from}</b>{" "}
              {_("to")} <b>{data.to}</b>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div className="action">
                <span>{_("manageAbsence")}</span>
                <CalendarIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      case "SICK_DAY_PENDING":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="minim_message">
              <span>Congé de maladie</span> {_("from")} <b>{data.from}</b>{" "}
              {_("to")} <b>{data.to}</b>
            </div>
            <div className="message">
              <ExclamationMarkIcon fill={palette[key].color} size={15} />
              <span>{_("needCertificats")}</span>
            </div>
            <Link to={`/${lng}/fiche-collaborator/${id}?tab=CALENDAR`}>
              <div className="action">
                <span>{_("addCertificats")}</span>
                <CalendarIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      case "REMOTE_DAY":
      case "OUT_DAY":
        return (
          <div className="tooltip-frame">
            <div className="color">
              <div style={{ background: palette[key].background }}></div>
              <span>{palette[key].label}</span>
            </div>
            <div className="title">
              {moment(
                filter.year + "-" + (filter.month + 1) + "-" + day,
                "YYYY-M-D"
              ).format("DD MMM YYYY")}
            </div>
            <div className="message">
              <span>{_("regime")} :</span> <b>{data.label}</b>
            </div>
            <Link to={`/${lng}/collaborators-settings`}>
              <div className="action">
                <span>{_("manageRegime")}</span>
                <SettingsIcon fill="#6D7F92" />
              </div>
            </Link>
          </div>
        );
      default:
        return "";
    }
  };

  const handleRespondToAbsenceRequest = async (id, data, state) => {
    try {
      const response = await saveCollaboratorAbsence(
        token,
        navCommunity.id,
        [
          {
            id: data.id,
            from: data.from,
            to: data.to,
            reason: data.label,
            rejectReason: "",
            type: data.type,
            month: filter.month,
            numberDays: data.numberDays,
            listOfDays: data.days,
            vacationStatus: state,
          },
        ],
        id,
        filter.year,
        filter.month - 1,
        user,
        true
      );
      if (response.status === 200) {
        const responseData = await getCalendarRecap(
          navCommunity.id,
          token,
          filter
        );
        setData(responseData.data.data);
        setColumns(Object.keys(responseData.data.data[0].data));
      }
    } catch (e) {
      return null;
    }
  };

  const renderCaledarMatrix = () => {
    let columnsTest = [
      {
        title: "",
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div className="user-info">
            <div
              className="avatar"
              style={{
                backgroundImage: `url(${avatars[record.uid]?.avatarUrl})`,
              }}
            />
            <div className="infos">
              <div>{record.name}</div>
              <div>
                <span>{COLLABORATOR_ROLE_ATTRIBUT[record.role]}</span>
                {record.role && record.bu && <div className="dot" />}
                <span>{record.bu}</span>
              </div>
            </div>
          </div>
        ),
        width: 30,
        fixed: "left",
      },
      {
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div
                style={{
                  height: 43,
                  margin: "0px 2px",
                  borderBottom: "1px solid #1E1E1E",
                }}>
                <span class="ant-table-column-title">1</span>
              </div>
            </span>
          );
        },
        dataIndex: 1,
        key: 1,
        onCell: (record, index) => {
          if (
            Object.values(record.data)
              .map((elem) => elem.type)
              .includes("NONE_DAY")
          ) {
            return {
              colSpan: columns.length,
            };
          }
          return {};
        },
        render: (text, record) => {
          if (
            Object.values(record.data)
              .map((elem) => elem.type)
              .includes("NONE_DAY")
          ) {
            return (
              <span class="warning_tag">
                <AlertTriangleIcon />
                {_("regimeNotConfigurated")}
              </span>
            );
          }
          let key = record.data[1].type
            .replace("_PENDING", "")
            .replace("_BACKUP", "");
          return (
            <Tooltip
              key={`entity-${key}`}
              placement="top"
              color="#FFFFFF"
              showArrow={false}
              title={() =>
                renderCustumedTooltipContent(
                  1,
                  record.id,
                  record.data[1].type,
                  record.data[1].data
                )
              }>
              <span
                className={`balance-sheet-td-component minim-componenent`}
                style={{
                  cursor: ["WEEKEND_DAY", "HOLIDAY_DAY"].includes(
                    record.data[1].type
                  )
                    ? "default"
                    : "pointer",
                  color: palette[key].color,
                  background: palette[key].background,
                }}>
                <span style={{ display: "flex" }}>
                  {record.data[1].type.includes("PENDING") ? (
                    <ExclamationMarkIcon fill={palette[key].color} />
                  ) : record.data[1].type.includes("BACKUP") ? (
                    <SwitchUserIcon fill={palette[key].color} />
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </Tooltip>
          );
        },
        width: 5,
      },
    ];

    for (let i = 2; i <= columns.length; i++) {
      columnsTest.push({
        title: () => {
          return (
            <span class="ant-table-header-column">
              <div
                style={{
                  height: 43,
                  margin: "0px 2px",
                  borderBottom: "1px solid #1E1E1E",
                }}>
                <span class="ant-table-column-title">{i}</span>
              </div>
            </span>
          );
        },
        dataIndex: i,
        key: i,
        onCell: (record, index) => {
          if (
            Object.values(record.data)
              .map((elem) => elem.type)
              .includes("NONE_DAY")
          ) {
            return {
              colSpan: 0,
            };
          }
          return {};
        },
        render: (text, record) => {
          if (
            Object.values(record.data)
              .map((elem) => elem.type)
              .includes("NONE_DAY")
          ) {
            return (
              <span>
                <AlertTriangleIcon />
                {_("regimeNotConfigurated")}
              </span>
            );
          }
          let key = record.data[i].type
            .replace("_PENDING", "")
            .replace("_BACKUP", "");
          return (
            <Tooltip
              key={`entity-${key}`}
              placement="top"
              color="#FFFFFF"
              showArrow={false}
              title={() =>
                renderCustumedTooltipContent(
                  i,
                  record.id,
                  record.data[i].type,
                  record.data[i].data
                )
              }>
              <span
                className={`balance-sheet-td-component minim-componenent`}
                style={{
                  cursor: ["WEEKEND_DAY", "HOLIDAY_DAY"].includes(
                    record.data[i].type
                  )
                    ? "default"
                    : "pointer",
                  color: palette[key].color,
                  background: palette[key].background,
                }}>
                <span style={{ display: "flex" }}>
                  {record.data[i].type.includes("PENDING") ? (
                    <ExclamationMarkIcon fill={palette[key].color} />
                  ) : record.data[i].type.includes("BACKUP") ? (
                    <SwitchUserIcon fill={palette[key].color} size="18" />
                  ) : (
                    ""
                  )}
                </span>
              </span>
            </Tooltip>
          );
        },
        width: 5,
      });
    }

    return (
      <div className={"calendar_table"}>
        <Table
          columns={columnsTest}
          scroll={{
            y: 600,
          }}
          dataSource={data}
          pagination={false}
          className="reporting-calendar-table"
        />
      </div>
    );
  };

  return (
    <Fragment>
      { hasAccess === "1" ? (
        <div className="calendar_container portfolio_container">
        <div className="container">
          <CalendarFilter
            filterFormIsVisible={false}
            filter={filter}
            nbResult={data.length}
            updateFilter={setFilter}
            fetching={calendarQuery.isFetching}
          />
          <div className="medium-11">
            {calendarQuery.isFetching ? (
              <div className="ripple_loading">
                <img src={"/img/Ripple-1s-150px.svg"} alt="" />
              </div>
            ) : Object.keys(data).length ? (
              <Fragment>
                <div className="palette">
                  {Object.keys(palette).map((item) => {
                    if (["WEEKEND_DAY", "NONE_DAY"].includes(item)) {
                      return;
                    }
                    return (
                      <div className="color">
                        <div
                          style={{ background: palette[item].background }}></div>
                        <span>{palette[item].label}</span>
                      </div>
                    );
                  })}
                </div>
                <div className="caroussel">
                  <div className="label">
                    {_("nbrResult")} : {data.length}
                  </div>
                  <div className="menu">
                    <div
                      className="prev"
                      style={{
                        opacity: filter.month === 0 ? 0.4 : 1,
                        cursor: filter.month === 0 ? "not-allowed" : "pointer",
                      }}
                      onClick={() => {
                        if (filter.month === 0) {
                          return;
                        }
                        setFilter({ ...filter, month: filter.month - 1 });
                      }}>
                      {filter.month !== 0 && <ArrowLeftIcon width={11} />}
                      <span>
                        {filter.month !== 0 &&
                          moment(filter.month, "M").format("MMMM")}
                      </span>
                    </div>
                    <div className="now">
                      {moment(filter.month + 1, "M").format("MMMM")} {filter.year}
                    </div>
                    <div
                      className="next"
                      style={{
                        opacity: filter.month === 12 ? 0.4 : 1,
                        cursor: filter.month === 12 ? "not-allowed" : "pointer",
                      }}
                      onClick={() => {
                        if (filter.month === 11) {
                          return;
                        }
                        setFilter({ ...filter, month: filter.month + 1 });
                      }}>
                      <span>
                        {filter.month !== 11 &&
                          moment(filter.month + 2, "M").format("MMMM")}
                      </span>
                      {filter.month !== 11 && <ArrowLeftIcon width={11} />}
                    </div>
                  </div>
                </div>
                {renderCaledarMatrix()}
              </Fragment>
            ) : (
              <div className="no_data">
                <div className="title">{_("noDataFound")}</div>
                <div className="subTitle">{_("noClientsFound")}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      ) : ""}
    </Fragment>
  );
};

export default CalendarRecap;
