import React from "react";

export const SliderIcon = ({ size = 16, fill = "#18A0FB" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_59317_72114)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.66667 8.66797C3.03486 8.66797 3.33333 8.96645 3.33333 9.33464V14.0013C3.33333 14.3695 3.03486 14.668 2.66667 14.668C2.29848 14.668 2 14.3695 2 14.0013V9.33464C2 8.96645 2.29848 8.66797 2.66667 8.66797Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.66667 1.33203C3.03486 1.33203 3.33333 1.63051 3.33333 1.9987V6.66536C3.33333 7.03355 3.03486 7.33203 2.66667 7.33203C2.29848 7.33203 2 7.03355 2 6.66536V1.9987C2 1.63051 2.29848 1.33203 2.66667 1.33203Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99967 7.33203C8.36786 7.33203 8.66634 7.63051 8.66634 7.9987V13.9987C8.66634 14.3669 8.36786 14.6654 7.99967 14.6654C7.63148 14.6654 7.33301 14.3669 7.33301 13.9987V7.9987C7.33301 7.63051 7.63148 7.33203 7.99967 7.33203Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.99967 1.33203C8.36786 1.33203 8.66634 1.63051 8.66634 1.9987V5.33203C8.66634 5.70022 8.36786 5.9987 7.99967 5.9987C7.63148 5.9987 7.33301 5.70022 7.33301 5.33203V1.9987C7.33301 1.63051 7.63148 1.33203 7.99967 1.33203Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3337 10C13.7018 10 14.0003 10.2985 14.0003 10.6667V14C14.0003 14.3682 13.7018 14.6667 13.3337 14.6667C12.9655 14.6667 12.667 14.3682 12.667 14V10.6667C12.667 10.2985 12.9655 10 13.3337 10Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.3337 1.33203C13.7018 1.33203 14.0003 1.63051 14.0003 1.9987V7.9987C14.0003 8.36689 13.7018 8.66536 13.3337 8.66536C12.9655 8.66536 12.667 8.36689 12.667 7.9987V1.9987C12.667 1.63051 12.9655 1.33203 13.3337 1.33203Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 9.33464C0 8.96645 0.298477 8.66797 0.666667 8.66797H4.66667C5.03486 8.66797 5.33333 8.96645 5.33333 9.33464C5.33333 9.70283 5.03486 10.0013 4.66667 10.0013H0.666667C0.298477 10.0013 0 9.70283 0 9.33464Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.33301 5.33464C5.33301 4.96645 5.63148 4.66797 5.99967 4.66797H9.99967C10.3679 4.66797 10.6663 4.96645 10.6663 5.33464C10.6663 5.70283 10.3679 6.0013 9.99967 6.0013H5.99967C5.63148 6.0013 5.33301 5.70283 5.33301 5.33464Z"
          fill={fill}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.667 10.6667C10.667 10.2985 10.9655 10 11.3337 10H15.3337C15.7018 10 16.0003 10.2985 16.0003 10.6667C16.0003 11.0349 15.7018 11.3333 15.3337 11.3333H11.3337C10.9655 11.3333 10.667 11.0349 10.667 10.6667Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_59317_72114">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
