import axios from "axios";
import { TTP_API_URL } from "../config";

export const getClusters = ({ token, organizationId, searchWord }) => {
  const requestUrl = `${TTP_API_URL}/team/clusters`;
  let filter = [];

  if (searchWord && searchWord.length) {
    filter = [
      {
        property: "label",
        value: searchWord,
        operator: "like",
      },
    ];
  }
  let params = {
    organization: organizationId,
    access_token: token,
    filter: JSON.stringify(filter),
  };
  return axios.get(requestUrl, {
    params,
  });
};
