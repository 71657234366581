import React from "react";
import { useSelector } from "react-redux";
import _ from "../../../i18n";
import styles from "./PageHeader.module.scss";
import { ArrowLeftIcon } from "../../common/Icons";
// import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const PageHeader = ({ header, subHeader, cssClass, icon, children }) => {
  const { language } = useSelector((state) => state.params);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div className={styles.headertesttttt}>
      <div className={styles.page_header__icon}>
        {/*<img src={"/img/icons/" + SVG_ICONS[icon] || "EMAIL.svg"} alt={""} />*/}
        {/* <SvgIcon icon={icon} className={""} /> */}
        {/* <IconTrash /> */}
        {/* <Link to={`/${language}/clients`}> */}
          <button
            key="cancel"
            style={{
              height: 36,
              width: 116,
              marginLeft: 8,
              alignItems: "center",
              color: "#6D7F92",
              background: "#F8F9FA",
              display: "flex",
              textAlign: "center",
              justifyContent: "space-around",
              border: "1px solid #B2BCC6",
              boxSizing: "border-box",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() =>handleGoBack()}
            // onClick={this.props.onBack}
          >
            {/* <IconArrowLeft className={styles.setting_page_header_icon} /> */}
            <div className={styles.setting_page_header_button_label}>
              <div className="action">
                <ArrowLeftIcon />
              </div>

              {_("goBack")}
            </div>
          </button>
        {/* </Link> */}
      </div>
      <div className={styles.page_header__info}>
        <div>
          <h3>{_(header)}</h3>
          <p>{_(subHeader)}</p>
        </div>
      </div>
      <div className={styles.page_header__children}>{children}</div>
      {/* {this.props.customAddOn} */}
    </div>
  );
};
export default PageHeader;
