import axios from "axios";
import { TTP_API_URL } from "../config";

export const deleteNotification = ({ token, notificationId, organizationId }) => {
  const requestUrl = `${TTP_API_URL}/team/delete_notification`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", notificationId);
  formData.append("organizationId", organizationId);

  return axios.post(requestUrl, formData);
};
