import React from "react";

export const CheckCircleIcon = ({ size = 13, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_63019_47298)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.8315 2.63567C6.94512 2.24072 5.95482 2.14288 5.00829 2.35674C4.06175 2.57059 3.20971 3.08469 2.57923 3.82235C1.94874 4.56002 1.5736 5.48172 1.50976 6.45001C1.44591 7.4183 1.69677 8.38129 2.22493 9.19535C2.7531 10.0094 3.53026 10.6309 4.44051 10.9672C5.35077 11.3035 6.34534 11.3366 7.27591 11.0614C8.20648 10.7863 9.02318 10.2177 9.6042 9.44049C10.1852 8.66327 10.4994 7.71906 10.5 6.74867V6.28896C10.5 6.01281 10.7239 5.78896 11 5.78896C11.2761 5.78896 11.5 6.01281 11.5 6.28896V6.74896C11.4993 7.93499 11.1153 9.08931 10.4051 10.0392C9.69499 10.9892 8.69681 11.6841 7.55945 12.0204C6.42209 12.3567 5.20649 12.3163 4.09396 11.9053C2.98142 11.4942 2.03156 10.7346 1.38603 9.73963C0.740496 8.74466 0.433885 7.56768 0.511922 6.38421C0.589959 5.20075 1.04846 4.07422 1.81905 3.17263C2.58964 2.27104 3.63103 1.6427 4.7879 1.38132C5.94478 1.11994 7.15515 1.23953 8.2385 1.72224C8.49074 1.83463 8.60411 2.13022 8.49172 2.38246C8.37933 2.63469 8.08374 2.74806 7.8315 2.63567Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3534 2.39627C11.5487 2.59143 11.5489 2.90802 11.3537 3.10338L6.35373 8.10838C6.25998 8.20222 6.13278 8.25497 6.00012 8.255C5.86747 8.25503 5.74025 8.20235 5.64645 8.10855L4.14645 6.60855C3.95118 6.41329 3.95118 6.09671 4.14645 5.90145C4.34171 5.70618 4.65829 5.70618 4.85355 5.90145L5.99982 7.04772L10.6463 2.39662C10.8414 2.20126 11.158 2.20111 11.3534 2.39627Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_63019_47298">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
