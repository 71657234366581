import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getBU } from "../api";

export const fetchBU = createAsyncThunk(
  "bu/fetchBU",

  async (searchWord, { dispatch, getState }) => {
    const { token, navCommunity } = getState().auth;

    let organizationId = navCommunity.id;
    const response = await getBU({
      token,
      organizationId,
      searchWord,
    });
    return response;
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

export const buSlice = createSlice({
  name: "bu",
  initialState,
  extraReducers: {
    [fetchBU.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchBU.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.data = payload.data.data;
    },
    [fetchBU.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.data = [];
    },
  },
});

export default buSlice.reducer;
