export default function AnimatedLoadingIcon({size = 24, color = '#18A0FB'}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      // style="shape-rendering: auto; display: block; background: rgba(255, 255, 255, 0);"
      style={{shapeRendering: 'auto', display: 'block', background: 'rgba(255, 255, 255, 0)'}}
      width={size}
      height={size}>
      <g>
        <g>
          <path stroke-width="12" stroke={color} fill="none" d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843"></path>
          <path fill={color} d="M49 3L49 27L61 15L49 3"></path>
          <animateTransform keyTimes="0;1" values="0 50 50;360 50 50" dur="1s" repeatCount="indefinite" type="rotate" attributeName="transform"></animateTransform>
        </g>
        <g></g>
      </g>
    </svg>
  )
}
