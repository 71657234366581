const ADMIN_SUB_TAB = "admin";
const TODO_SUB_TAB = "todo";

export const SUB_TABS = [
    {
        key: ADMIN_SUB_TAB,
        label: "Admin"
    },
    {
        key: TODO_SUB_TAB,
        label: "To Do"
    }
]

export const PAGINATION_CURRENT_PAGE = 0;
export const PAGINATION_START = 0;
export const PAGINATION_LIMIT = 5;

// those columns exist in all tabs
const COMMUN_COLUMNS = [
  {
    field: "month",
    header: "Mois",
    style: {
      width: "40px"
    },
  },
  {
    field: "clientName",
    header: "Nom",
    resizeable: true,
    align: 'left',
    style: {
      width: "200px"
    },
  }
]

export const SUB_TABS_COLUMNS = {
  [ADMIN_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "rdv",
      header: "RDV",
      alignHeader: 'center',
      style: {
        width: "110px"
      },
    },
    {
      field: "waitingForClient",
      header: "En attente client",
      alignHeader: 'center',
    },
    {
      field: "todoDeg",
      header: "À faire Deg",
      alignHeader: 'center',
    },
    {
      field: "orderSent",
      header: "OP envoyée",
      alignHeader: 'center',
    },
    {
      field: "orderSigned",
      header: "OP Signée",
      alignHeader: 'center',
    },
    {
      field: "lmReception",
      header: "Gagné/Perdu",
      alignHeader: 'center',
    },
    {
      field: "lmProposition",
      header: "LM/LAB envoyée",
      alignHeader: 'center',
    },
    {
      field: "oneShot",
      header: "Forfait start",
      alignHeader: 'center',
    },
    {
      field: "selectedPlan",
      header: "Formule choisie",
      alignHeader: 'center',
      align: 'center',
      style: {
        textAlign: 'center'
      }
    },
    {
      field: "planning",
      header: "A/D",
      alignHeader: 'center',
      align: 'right',
    },
    {
      field: "governance",
      header: "Régie",
      alignHeader: 'center',
    },
    {
      field: "feeYearN",
      header: "Annuel N",
      alignHeader: 'center',
      align: 'right',
    },
    {
      field: "feeYearN1",
      header: "Annuel N+1",
      alignHeader: 'center',
      align: 'right',
    }
  ],
  [TODO_SUB_TAB]: [
    ...COMMUN_COLUMNS,
    {
      field: "appDemo",
      header: "APP démo",
      alignHeader: 'center',
    },
    {
      field: "manager",
      header: "GD",
      alignHeader: 'center',
      style: {
        width: '200px'
      }
    },
    {
      field: "threeMonths",
      header: "3 mois",
      alignHeader: 'center',
    },
    {
      field: "sixMonths",
      header: "6 mois",
      alignHeader: 'center',
    },
    {
      field: "nineMonths",
      header: "9 mois",
      alignHeader: 'center',
    }
  ]
}
