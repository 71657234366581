import { useState } from "react";
import { useSelector } from "react-redux";
import _ from "../../i18n";
import PageHeader from "../common/PageHeader/PageHeader";
import CollaboratorSpace from "../CollaboratorSpace/CollaboratorSpace";
import { FolderIcon } from "../common/Icons";
import { useQuery } from "react-query";
import { getCollaborator } from "../../api";

const UserSpace = () => {
  const auth = useSelector((state) => state.auth);
  const [collaborator, setCollaborator] = useState(null);
  const { token, user } = useSelector((state) => state.auth);

  let collaboratorQuery = useQuery(["collaboratorQuery", token], async () => {
    if (token && user.uid) {
      try {
        const response = await getCollaborator(
          null,
          auth.navCommunity.id,
          token,
          user.uid
        );
        setCollaborator(response.data.data);
      } catch (e) {
        setCollaborator(null);
        return null;
      }
    }
  });

  return (
    <div>
      <PageHeader
        header={_("My space")}
        subHeader={_("VISUALISER ET GÉRER VOS DOSSIER")}
        icon={<FolderIcon size="36" fill="#29394D" />}
      />
      {collaborator ? (
        <CollaboratorSpace
          // collaboratorId={collaborator.id}
          userSpace={collaborator}
          collaboratorSpace={true}
        />
      ) : (
        <div className="no_data">
          <div className="title">{_("noDataFound")}</div>
          <div className="subTitle">{_("noCollaboratorsFound")}</div>
        </div>
      )}
    </div>
  );
};

export default UserSpace;
