import React, { useState } from "react";
import { Button, DatePicker, Input, Modal } from "antd";
import _ from "../../../i18n";
import { printPrestationInvoice } from "../../../api";
import { BeatLoader } from "react-spinners";

const InvoiceDownloader = ({
  token,
  navCommunity,
  open,
  loading,
  close,
  data,
}) => {
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [date, setDate] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const handlePrintPrestationInvoice = async () => {
    setDownloading(true);
    if (token && navCommunity) {
      const selectedDate = new Date(date);
      const endOfMonthDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        0
      );
      const endDate = `${endOfMonthDate.getFullYear()}-${String(
        endOfMonthDate.getMonth() + 1
      ).padStart(2, "0")}-${String(endOfMonthDate.getDate()).padStart(2, "0")}`;
      console.log("rrr", endDate, invoiceNumber);
      try {
        await printPrestationInvoice(
          token,
          navCommunity.id,
          endDate,
          invoiceNumber,
          data
        );
        setDownloading(false);
      } catch (e) {
        setDownloading(false)
        throw new Error(e.message);;
      }
    }
  };
  return (
    <Modal
      title={<p>{_("downloadInvoice")}</p>}
      footer={
        <Button
          disabled={!(invoiceNumber && date)}
          className="download-button"
          onClick={handlePrintPrestationInvoice}
        >
          {downloading ? <BeatLoader color="#3498db" size={10} /> : _("download")}
        </Button>
      }
      width={700}
      loading={loading}
      open={open}
      onCancel={close}
    >
      <div className="form-control">
        <div className="field-control">
          <label htmlFor="invoice">{_("invoiceNumber")}</label>
          <Input
            type="text"
            id="invoice"
            name="incoive"
            placeholder={_("invoiceNumber")}
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            className="field"
          />
        </div>
        <div className="field-control">
          <label htmlFor="invoice">{_("invoiceDate")}</label>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            className="field"
            placeholder={_("invoiceDate")}
          />
        </div>
      </div>
    </Modal>
  );
};
export default InvoiceDownloader;
