import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import './ClientsOutFilters.scss';

import _ from "../../../../i18n";
import { CLIENT_GROUP_CRM_FILTER, COLLABORATOR_ROLE_FILTER, IS_ENTITY_STATES } from "../../../../config";
import { useOnClickOutside, renderTeamYears } from "../../../../utils";
import { getBU, getClientsSuggestions, getCollaboratorsSuggestions } from "../../../../api";

import { TiArrowSortedDown } from "react-icons/ti";
import TTPFilterHorizontalBar from "../../../common/Filter/TTPFilterHorizontalBar";
import { getPartners } from "../../../../api/partners";

const ClientsOutFilters = ({
  filter,
  nbResult,
  updateFilter,
  resetFilter
}) => {
  const ref = useRef();
  useOnClickOutside(ref, () => setYearDivIsVisible(false));
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [buOptions, setBuOptions] = useState([]);

  let collaboratorsQuery = useQuery(
    ["getCollaborators", token, navCommunity],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsSuggestions(
            token,
            navCommunity.id
          );
          let data = response.data.data
            .filter((item) => item !== null)
            .map((t) => {
              return {
                value: t.id.toString(),
                label: t.name,
              };
            });
          setCollaboratorOptions(data);
        } catch (e) {
          return null;
        }
      }
    }
  );

  let buQuery = useQuery(["businessUnitsQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getBU({token, organizationId: navCommunity.id});
        let data = response.data.data
          .filter((item) => item !== null)
          .map((t) => {
            return {
              value: t.id,
              label: t.label,
            };
          });
        setBuOptions(data);
      } catch (e) {
        setBuOptions([]);
        return null;
      }
    }
  });

  let partnersQuery = useQuery(["partnersQuery", navCommunity], async () => {
    if (token) {
      try {
        const response = await getPartners({token, organizationId: navCommunity.id});
        let data = response.data.data.map(partner => (
          {
            value: partner.id,
            label: partner.name
          }
        ))
        setPartnerOptions(data);
      } catch (e) {
        setPartnerOptions([]);
        return null;
      }
    }
  });

  const renderYearSelect = () => {
    return (
      <div
        ref={ref}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div
          className={"customed_select_header"}
          onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
        >
          <span>{filter.year}</span>
          <div className={"actions"}>
            <div>
              <span className={"action"}>
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => updateFilter({ ...filter, year: item })}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleClientGroupChange = (items) => {
    updateFilter({ ...filter, clientGroup: items });
  }

  const handleBuChange = (items) => {
    updateFilter({ ...filter, bu: items });
  };

  const handlePartnerChange = (item) => {
    updateFilter({ ...filter, partner: item });
  };

  const handleCollaboratorChange = (item) => {
    updateFilter({ ...filter, collaborator: item });
  };

  const handleCollaboratorRoleChange = (item) => {
    updateFilter({ ...filter, role: item });
  };

  let filters = [
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        placeholder: _("clientsGroup"),
        options: CLIENT_GROUP_CRM_FILTER,
        values: filter.clientGroup,
        action: handleClientGroupChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: false,
        isMulti: true,
        isClearable: true,
        isSearch: true,
        placeholder: _("bu"),
        options: buOptions,
        values: filter.bu,
        action: handleBuChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isSearch: true,
        placeholder: _("partner"),
        options: partnerOptions,
        values: filter.partner,
        action: handlePartnerChange,
      },
    },
    {
      type: "SELECT",
      options: {
        isSimple: true,
        isMulti: false,
        isClearable: true,
        isSearch: true,
        placeholder: _("collaborator"),
        options: collaboratorOptions,
        values: filter.collaborator,
        action: handleCollaboratorChange,
      },
    },
    filter.collaborator !== null && {
      type: "SELECT",
      options: {
        isSimple: true,
        isClearable: true,
        placeholder: _("folderAs"),
        options: COLLABORATOR_ROLE_FILTER,
        values: filter.role,
        action: handleCollaboratorRoleChange,
      },
    }
  ];
  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="clients-out-filters">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          button
          bg="#FFF"
          subBG="F8F9FA"
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          pageSize={filter.pageSize}
          customAddOn={renderYearSelect()}
          updateFilterContent={updateFilter}
          resetFilterContent={resetFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default ClientsOutFilters;
