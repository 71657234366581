import React from "react";

export const CheckIcon = ({ width = "10", height = "7", fill = "#6D7F92", className = '', onClick = () => {} }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.10453 0.146447C9.29979 0.341709 9.29979 0.658291 9.10453 0.853553L3.60453 6.35355C3.40927 6.54882 3.09269 6.54882 2.89742 6.35355L0.397423 3.85355C0.202161 3.65829 0.202161 3.34171 0.397423 3.14645C0.592685 2.95118 0.909268 2.95118 1.10453 3.14645L3.25098 5.29289L8.39742 0.146447C8.59269 -0.0488155 8.90927 -0.0488155 9.10453 0.146447Z"
      fill={fill}
    />
  </svg>
);
