import React from "react";

export const RepeatIcon = ({ size = 14, className = "" }) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_63019_47282)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.14645 0.896447C8.34171 0.701184 8.65829 0.701184 8.85355 0.896447L10.8536 2.89645C11.0488 3.09171 11.0488 3.40829 10.8536 3.60355L8.85355 5.60355C8.65829 5.79882 8.34171 5.79882 8.14645 5.60355C7.95118 5.40829 7.95118 5.09171 8.14645 4.89645L9.79289 3.25L8.14645 1.60355C7.95118 1.40829 7.95118 1.09171 8.14645 0.896447Z"
        fill="#6D7F92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 3.75C3.10218 3.75 2.72064 3.90804 2.43934 4.18934C2.15804 4.47064 2 4.85218 2 5.25V6.25C2 6.52614 1.77614 6.75 1.5 6.75C1.22386 6.75 1 6.52614 1 6.25V5.25C1 4.58696 1.26339 3.95107 1.73223 3.48223C2.20107 3.01339 2.83696 2.75 3.5 2.75H10.5C10.7761 2.75 11 2.97386 11 3.25C11 3.52614 10.7761 3.75 10.5 3.75H3.5Z"
        fill="#6D7F92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.85355 7.89645C4.04882 8.09171 4.04882 8.40829 3.85355 8.60355L2.20711 10.25L3.85355 11.8964C4.04882 12.0917 4.04882 12.4083 3.85355 12.6036C3.65829 12.7988 3.34171 12.7988 3.14645 12.6036L1.14645 10.6036C0.951184 10.4083 0.951184 10.0917 1.14645 9.89645L3.14645 7.89645C3.34171 7.70118 3.65829 7.70118 3.85355 7.89645Z"
        fill="#6D7F92"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 6.75C10.7761 6.75 11 6.97386 11 7.25V8.25C11 8.91304 10.7366 9.54893 10.2678 10.0178C9.79893 10.4866 9.16304 10.75 8.5 10.75H1.5C1.22386 10.75 1 10.5261 1 10.25C1 9.97386 1.22386 9.75 1.5 9.75H8.5C8.89782 9.75 9.27936 9.59196 9.56066 9.31066C9.84196 9.02936 10 8.64782 10 8.25V7.25C10 6.97386 10.2239 6.75 10.5 6.75Z"
        fill="#6D7F92"
      />
    </g>
    <defs>
      <clipPath id="clip0_63019_47282">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0 0.75)"
        />
      </clipPath>
    </defs>
  </svg>
);
