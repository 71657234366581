import React from "react";

export const PhoneCallIcon = ({ size = 16, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.41511 3.39938C7.31648 3.13086 7.11884 2.91026 6.86273 2.78282C6.60662 2.65539 6.31145 2.63078 6.03778 2.71405C4.74044 3.11405 3.83378 4.22738 4.02844 5.51538C4.24615 6.9449 4.73056 8.32078 5.45644 9.57138C6.17456 10.8191 7.11599 11.9241 8.23378 12.8314C9.23911 13.6447 10.6498 13.4207 11.6444 12.4874C11.8537 12.291 11.9806 12.0226 11.9997 11.7363C12.0187 11.45 11.9285 11.1671 11.7471 10.9447L11.0351 10.074C10.8925 9.89897 10.7006 9.77078 10.4842 9.70611C10.2679 9.64145 10.0371 9.64328 9.82178 9.71138L8.17911 10.2314L7.89244 9.93538C7.53125 9.56077 7.21531 9.14504 6.95111 8.69672C6.69469 8.24334 6.49497 7.76016 6.35644 7.25805L6.24578 6.86472L7.51244 5.69805C7.67959 5.54285 7.79684 5.34148 7.8493 5.11951C7.90177 4.89754 7.88708 4.66499 7.80711 4.45138L7.41644 3.40072L7.41511 3.39938ZM5.64578 1.43938C6.24465 1.25615 6.89092 1.30889 7.45216 1.58678C8.0134 1.86468 8.44712 2.34669 8.66444 2.93405L9.05511 3.98472C9.22745 4.44631 9.25862 4.94873 9.14465 5.42808C9.03069 5.90744 8.77675 6.34207 8.41511 6.67672L7.76178 7.27805C7.84578 7.51005 7.95911 7.77538 8.10578 8.03272C8.25244 8.28605 8.42311 8.51672 8.57911 8.70472L9.41911 8.43805C9.88873 8.28978 10.392 8.28588 10.8639 8.42684C11.3358 8.56781 11.7544 8.84715 12.0658 9.22872L12.7778 10.0994C13.1735 10.5833 13.3707 11.1993 13.3296 11.823C13.2885 12.4467 13.0122 13.0316 12.5564 13.4594C11.2311 14.7034 9.06711 15.2194 7.39644 13.8674C6.15156 12.8575 5.10302 11.6272 4.30311 10.238C3.49331 8.84317 2.95282 7.30854 2.70978 5.71405C2.38978 3.59005 3.91378 1.97272 5.64578 1.44072V1.43938Z"
      fill={fill}
    />
  </svg>
);
