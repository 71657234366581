import { MdBlock } from "react-icons/md";
import _ from "../../../i18n";
import styles from "./notAuthorized.module.scss";

export default function NotAuthorized() {
  return (
    <div className={styles.not_authorized__container}>
      <div className={styles.not_authorized__content}>
        <MdBlock size={100} className={styles.not_authorized__block_icon} />
        <h1>{_("accessDenied")}</h1>
        <span>{_("accessDeniedMessage")}</span>

        <button onClick={() => window.history.back()}>Retour</button>
      </div>
    </div>
  );
}
