import axios from "axios";
import { TTP_API_URL } from "../config";

export const getPartners = ({token, organizationId}) => {
    const requestUrl = `${TTP_API_URL}/team/partners`;

    return axios.get(requestUrl, {
      params: {
        access_token: token,
        organizationId
      },
    });
  };
