import React, { useState, useRef } from "react";

import { LIST_PAGE_SIZES } from "../../../config";
import { TiArrowSortedDown } from "react-icons/ti";
import { useOnClickOutside } from "../../../utils";

const PageSize = ({ pageSize, onPageSizeChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsVisible(false));

  const handlePageSizeChange = (value) => {
    onPageSizeChange(value);
    setIsVisible(false);
  };

  return (
    <div
      ref={ref}
      className={
        "customed_select m-l-s" +
        " " +
        (isVisible ? "customed_select_opened" : "")
      }
      style={{
        background: "#F1F2F4",
        color: "#6D7F92",
        width: 75,
      }}
    >
      <div
        className={"customed_select_header"}
        onClick={() => setIsVisible(!isVisible)}
      >
        <span>{pageSize}</span>
        <div className={"actions"}>
          <div>
            <span className={"action"}>
              <TiArrowSortedDown />
            </span>
          </div>
        </div>
      </div>
      {isVisible && (
        <div className={"customed_select_body"}>
          <div className={"customed_select_body_options"}>
            {LIST_PAGE_SIZES.map((item, index) => (
              <div
                key={index}
                className={"customed_select_body_option"}
                onClick={() => handlePageSizeChange(item)}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSize;
