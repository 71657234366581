import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// import {  } from "../api";

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  authors: [],
  chains: [],
  mixedItems: [],
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {

   
  },
});

export default usersSlice.reducer;
