import { React } from "react";
import styles from "./Budget.module.scss";
import classNames from "classnames";
// import classnames from "classnames";
import { SELECT_STYLES } from "../../config";
import Select from "react-select";
// import _ from "../../i18n";
import { TableBorderedColumns } from "tamtam-components";

const ClientInformations = ({
  updatedDataVolumetrie,
  updatedClientInformationData,
  setUpdatedClientInformationData,
  setUpdatedDataVolumetrie,
  setUpdated,
  squads,
  clusters,
  preferences,
  bu,
}) => {
  const bordrerdcolumns = [
    { label: "real", haveHeader: true, isBordered: true, isEditable: false },
    {
      label: "budget",
      haveHeader: true,
      isBordered: true,
      isEditable: true,
    },
  ];

  const handleInputChange = (value, label, column, index1) => {
    setUpdatedDataVolumetrie(
      updatedDataVolumetrie.map((element, i) => {
        if (i === index1) {
          return {
            ...element,
            [column]: value,
          };
        }
        return element;
      })
    );
    setUpdated(true);
  };

  const handleColumns = (column, item, index1) => {
    return (
      <div className={styles.center}>
        <input
          type="text"
          className={classNames(
            styles.input_box,
            styles.label,
            (column.label === "real" ||(column.label ==="budget" && preferences.allowUpdateBudgetedBenchmark==="0")) && styles.disabled
          )}
          disabled={column.label === "real"}
          style={{ textAlign: "center" }}
          value={item[column.label]}
          required={true}
          onChange={(event) =>
            handleInputChange(
              event.target.value,
              item.label,
              column.label,
              index1
            )
          }
        ></input>
      </div>
    );
  };

  const handleSelectChange = (event, property) => {
    setUpdatedClientInformationData(
      updatedClientInformationData.map((element, i) => {
        if (element.property === property) {
          return {
            ...element,
            value: event.id,
          };
        }
        return element;
      })
    );
    setUpdated(true);
  };

  return (
    <div>
      <div className={styles.title}>INFORMATIONS CLIENT</div>
      <div className={classNames("grid-x")}>
        <div
          className={classNames(
            styles.cardCont,
            "cell small-6 medium-6 large-6",
            "grid-x"
          )}
        >
          {updatedClientInformationData &&
            updatedClientInformationData.map((item) => {
              return (
                <div className={classNames("cell small-6 medium-6 large-6")}>
                  <div className={styles.info}>
                  <div className={styles.info_content}>
                      {(item.property !== "Squad" || preferences.allowAttributionSquad === "1") &&
                        (item.property !== "BU" || preferences.allowAttributionBU === "1") &&
                        (item.property !== "Cluster" || preferences.allowAttributionCluster === "1") &&
                        <span>{item.property}</span>}
                      {!item.isList ? (
                        <div className={styles.value}>{item.value}</div>
                      ) : (
                        (item.property === "Squad" && preferences.allowAttributionSquad === "1") ||
                        (item.property === "BU" && preferences.allowAttributionBU === "1") ||
                        (item.property === "Cluster" && preferences.allowAttributionCluster === "1") ?
                        <Select
                          isMulti={false}
                          styles={SELECT_STYLES}
                          value={
                            item.property === "Squad"
                              ? squads.find((option) => option.id === item.value)
                              : item.property === "Cluster"
                              ? clusters.find((option) => option.id === item.value)
                              : bu.find((option) => option.id === item.value)
                          }
                          options={
                            item.property === "Squad"
                              ? squads
                              : item.property === "Cluster"
                              ? clusters
                              : bu
                          }
                          onChange={(event) =>
                            handleSelectChange(event, item.property)
                          }
                          isSearchable={true}
                          name="name"
                        /> : null
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div
          className={classNames(
            styles.cardCont,
            "cell small-6 medium-6 large-6"
          )}
        >
          <div className={styles.volumetrie}>
            <div className={styles.title}>
              <span>volumetrie</span>
            </div>
            <div>
              {updatedDataVolumetrie && (
                <TableBorderedColumns
                  headerHeight={"58px"}
                  height={"40px"}
                  bordrerdcolumns={bordrerdcolumns}
                  handleColumns={handleColumns}
                  tableItems={updatedDataVolumetrie}
                  isGestionTask={false}
                  widthColumns={"50%"}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientInformations;
