import React from "react";
import _ from "../../../i18n";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable } from "@dnd-kit/core";

import "../CollaboratorSpace.local.scss";
import PlanCard from "./PlanCard";

const PlansBoardColumn = ({ id, lng, avatars, plans }) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  return (
    <SortableContext
      id={id}
      items={plans}
      strategy={verticalListSortingStrategy}
    >
      <div className={`column-board ${id}_column`} key={id}>
        <div className="header">
          <div>{_(id)}</div>
          <div>{plans.length}</div>
        </div>
        <div ref={setNodeRef}>
          {plans.map((plan) => {
            return (
              <PlanCard lng={lng} plan={plan} plans={plans} avatars={avatars} />
            );
          })}
        </div>
      </div>
    </SortableContext>
  );
};

export default PlansBoardColumn;
