const teamNl = {
  save: "Opslaan",
  cancel: "Annuleren",
  english: "English",
  french: "French",
  dutch: "Dutch",
  yes: "Ja",
  no: "Nee"
};
export default teamNl;
