import React, { Fragment } from "react";
import moment from "moment";
import _ from "../../../i18n";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { renderAvatar } from "../../../utils";
import "../CollaboratorSpace.local.scss";

const PlanCard = ({ lng, plan, avatars }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: plan.id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0 : 1,
      }}
      {...attributes}
      {...listeners}
    >
      <div
        className="card-board"
        style={{ backgroundColor: plan.collaborator ? "#f4f7f9" : "" }}
      >
        {plan.collaborator && (
          <div className="avatar-frame">
            <div className="avatar" key={"item.uid"}>
              {renderAvatar(null, plan.collaborator.name)}
            </div>
            <div className="details">
              <span>{plan.collaborator.name}</span>
              <span>GD</span>
            </div>
          </div>
        )}
        <div className="card-board_title">
          {plan.collaborator ? plan.plan.title : plan.reason}
        </div>
        {!plan.collaborator && (
          <Fragment>
            <div className="card-board_sub_title">{plan.plan.title}</div>
            <div className="card-board_infos">
              {_("folder")}: <span>{plan.client.title.label}</span>
            </div>
            <div className="card-board_infos">
              {_("category")}: <span>{plan.category.label}</span>
            </div>
          </Fragment>
        )}
        <div className="card-board_description">
          {plan.plan.description.substring(0, 90)}
          {plan.plan.description.length > 90 ? " ... " : ""}
        </div>
        <div className="card-board_lower">
          <div className="avatars">
            {plan.plan.responsables &&
              plan.plan.responsables.map((item) => (
                <div className="avatar" key={item.uid}>
                  {renderAvatar(
                    avatars[item.uid] ? avatars[item.uid].avatarUrl : null,
                    item.name
                  )}
                </div>
              ))}
          </div>
          <div className="tag">{plan.plan.priorityLevel}</div>
        </div>
        <div className="card-board_footer">
          <div className="ago">
            {moment(plan.plan.createdAt, "YYYY-MM-DD hh:mm:ss")
              .locale(lng)
              .fromNow()}
          </div>
          <div className="flags"></div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
