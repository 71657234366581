import React from "react";

export const UserIcon = ({ size = 15, fill = "#29394D" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.60622 9.29177C3.15321 8.74479 3.89507 8.4375 4.66862 8.4375H9.33529C10.1088 8.4375 10.8507 8.74479 11.3977 9.29177C11.9447 9.83875 12.252 10.5806 12.252 11.3542V12.5208C12.252 12.843 11.9908 13.1042 11.6686 13.1042C11.3465 13.1042 11.0853 12.843 11.0853 12.5208V11.3542C11.0853 10.89 10.9009 10.4449 10.5727 10.1167C10.2445 9.78854 9.79941 9.60417 9.33529 9.60417H4.66862C4.20449 9.60417 3.75937 9.78854 3.43118 10.1167C3.10299 10.4449 2.91862 10.89 2.91862 11.3542V12.5208C2.91862 12.843 2.65745 13.1042 2.33529 13.1042C2.01312 13.1042 1.75195 12.843 1.75195 12.5208V11.3542C1.75195 10.5806 2.05924 9.83875 2.60622 9.29177Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.0026 2.60417C6.03611 2.60417 5.2526 3.38767 5.2526 4.35417C5.2526 5.32067 6.03611 6.10417 7.0026 6.10417C7.9691 6.10417 8.7526 5.32067 8.7526 4.35417C8.7526 3.38767 7.9691 2.60417 7.0026 2.60417ZM4.08594 4.35417C4.08594 2.74334 5.39177 1.4375 7.0026 1.4375C8.61344 1.4375 9.91927 2.74334 9.91927 4.35417C9.91927 5.965 8.61344 7.27083 7.0026 7.27083C5.39177 7.27083 4.08594 5.965 4.08594 4.35417Z"
      fill={fill}
    />
  </svg>
);
