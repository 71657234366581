import _ from "../../i18n";
import { getDynamicTableData } from "../../api";
import PageHeader from "../common/PageHeader/PageHeader";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import BudgetStateFilter from "./BudgetStateFilter/BudgetStateFilter";
import DynamicTableFilter from "./DynamicTableFilter";
import ReactTable from "./ReactTable/ReactTable";
import { FolderIcon } from "../common/Icons";
import { BUDGET_STATE_CONFIG, PAGES } from "../../config";
import moment from "moment";

const DynamicTable = () => {
  const dispatch = useDispatch();
  const { token, navCommunity, user, loggedAs } = useSelector(
    (state) => state.auth
  );
  const [error, setError] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [pageSize, setPageSize] = useState(5);
  const [budgetState, setBudgetState] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [currentNavPage, setCurrentNavPage] = useState(1);
  const [budgetStateConfig, setBudgetStateConfig] = useState({});
  const [dynamicTableFilterYear, setDynamicTableFilterYear] = useState(null);

  const [dataTotal, setDataTotal] = useState([]);
  const [lengthData, setLengthData] = useState(0);
  const [pageIndexPagination, setPageIndexPagination] = useState(0);
  const [selectedLimit, setSelectedLimit] = useState(10);

  const handleRedirectBudgetSetting = () => {
    // Logique pour rediriger vers les paramètres du budget
  };
  const [filters, setFilters] = useState({
    year: selectedYear + "/" + selectedYear,
    budgetState: [],
    clients: [],
    collaborators: [],
    clientTypes: [],
    pageSize: 5,
    startPage: 0,
    searchWord: "",
    deletedStatus: "NOT_DELETED",
    clientTypes: [],
    clientGroups: [],
    businessUnits: [],
    clientCRMTypes: ["CLIENT"],
    clientCRMGroup: [
      "Sociétés",
      "Indépendants",
      "Partenaire",
      "Particuliers",
      "Gérants",
      "Sociétés - Partenaire",
      "ASBL",
      "Indépendants petite entreprise",
      "AiSBL",
      "ASBL - Consultation",
      "Particuliers - Consultation",
      "Contact",
      "Gérant petite entreprise",
      "Indépendants - Consultation",
      "ASBL - Partenaire",
      "ASBL petite entreprise",
      "Fondation privée",
      "Sociétés petite entreprise",
    ],
    isEntity: "1",
    sortBy: "REVENUE_DESC",
    collaboratorAttribution: "ALL_FOLDERS",
    collaboratorRole: "",
    budgetStatus: "all",
    offshoreStatus: "",
    facturableStatus: "NON_FACTURABLE",
    role: "",
  });
  const [collaborators, setCollaboratorsState] = useState({
    fetching: false,
    fetched: false,
    error: null,
    items: [],
  });

  const { dynamicTableData, collaboratorsFetched, collaboratorsFetching } =
    useSelector((state) => ({
      dynamicTableData: state.dynamicTableData,
      collaboratorsFetched: state.collaborators.fetched,
      collaboratorsFetching: state.collaborators.fetching,
    }));

  let dataTable = useQuery(
    ["getDynamicTableData", token, navCommunity.id, filters],
    async () => {
      if (token && navCommunity.id) {
        try {
          const response = await getDynamicTableData(
            token,
            navCommunity.id,
            filters.clients,
            filters.clientGroups,
            filters.collaborators,
            filters.collaboratorRole,
            filters.year,
            filters.squads,
            filters.clusters,
            filters.businessUnits,
            filters.sortBy,
            filters.clientTypes,
            filters.clientCRMTypes,
            filters.searchWord,
            filters.budgetStatus,
            filters.clientCRMGroup,
            filters.deletedStatus,
            filters.offshoreStatus,
            filters.facturableStatus,
            filters.pageSize,
            filters.startPage
          );
          setFetching(false);
          setFetched(true);
          setData(response.data.data.result);
          setDataTotal(response.data.data.total);
          setLengthData(response.data.data.count);
        } catch (e) {
          console.log("defdefrefr===");
          return null;
        }
      }
    }
  );
  useEffect(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      startPage: pageIndexPagination,
    }));
    setFetching(true);
  }, [pageIndexPagination]);

  useEffect(() => {
    let year = moment().year();
    setCurrentNavPage(PAGES.FINANCIAL_SHEET_PAGE);
    setBudgetStateConfig(BUDGET_STATE_CONFIG);
    setBudgetState([BUDGET_STATE_CONFIG[0].id]);
  }, [dispatch]);

  const handleChangeStartPage = (start) => {
    setFilters({ ...filters, startPage: start });
  };

  const renderRipple = () => {
    return (
      <div className="ripple_loading">
        <img src={"/img/Ripple-1s-150px.svg"} alt="" />
      </div>
    );
  };

  const renderFilter = () => (
    <DynamicTableFilter
      // filterFormIsVisible={handleFilterFormIsVisible}
      token={token}
      navCommunity={navCommunity}
      lengthData={lengthData}
      pageSize={pageSize}
      selectedYear={filters.year}
      handleDynamicTablePageSize={setPageSize}
      filters={filters}
      setFilters={setFilters}
    />
  );

  // const handleExportFinancialSheet = () => {
  //   // dispatch(exportPeriodFinancialSheet(filters.year));
  // };

  // const renderExportDynamicTable = () => {
  //   if (dynamicTableData.fetched) {
  //     return (
  //       <div
  //         className="row medium-6"
  //         style={{ marginTop: "2%", marginRight: "-21%" }}
  //       >
  //         <div className="medium-3" style={{ marginLeft: "24%" }}>
  //           <a
  //             target="_blank"
  //             className="btn secondary text-uppercase"
  //             onClick={handleExportFinancialSheet}
  //           >
  //             {dynamicTableData.exportFetching ? (
  //               <img
  //                 width={"16%"}
  //                 src={"/img/icons/tail-spin-blue.svg"}
  //                 alt=""
  //               />
  //             ) : (
  //               <img width={"11%"} src={"/img/icons/exportation.svg"} alt="" />
  //             )}
  //             {_("Export")}
  //           </a>
  //         </div>
  //       </div>
  //     );
  //   }
  //   return "";
  // };

  return (
    <div>
      <PageHeader
        header={_("dynamic-table")}
        subHeader={_("subTitleClientList")}
        icon={<FolderIcon size="36" fill="#29394D" />}
      />
      <div className="resizable">
        <div>
          <div className="ttp-tab-container">
            <div className="rmodal__center">
              {renderFilter()}
              {/* {budgetStateConfig && budgetStateConfig.length > 0 ? (
                <BudgetStateFilter
                  majorKey={budgetStateConfig[0].id}
                  configState={budgetStateConfig}
                  selectedState={budgetState}
                  filters={filters}
                  setFilters={setFilters}
                  collaborators={collaborators}
                  collaboratorsFetching={collaboratorsFetching}
                  collaboratorsFetched={collaboratorsFetched}
                  // handleFetchCollaborators={handleFetchCollaborators}
                  // handleSelectedCollaborator={handleSelectedCollaborator}
                />
              ) : (
                <>test</>
                // <Skeleton
                //   style={{ width: 200 }}
                //   active={true}
                //   avatar={false}
                //   paragraph={false}
                // />
              )}{" "} */}
              {error && <h1>An error occured</h1>}
              {fetching ? (
                renderRipple()
              ) : fetched && !error ? (
                <ReactTable
                  selectedYear={filters.year}
                  fetching={fetching}
                  data={data && data.filter((item) => item.level !== "level-1")}
                  dataSource={
                    data && data.filter((item) => item.level !== "level-1")
                  }
                  handleChangeStartPage={handleChangeStartPage}
                  dataTotal={dataTotal}
                  lengthData={lengthData}
                  pageIndexPagination={pageIndexPagination}
                  setPageIndexPagination={setPageIndexPagination}
                  skipPageReset={skipPageReset}
                  pageSizeProp={pageSize}
                  setPageSizeProp={setPageSize}
                  setPageSize={setPageSize}
                  selectedLimit={selectedLimit}
                  setSelectedLimit={setSelectedLimit}
                  handleRedirectBudgetSetting={handleRedirectBudgetSetting}
                />
              ) : (
                <div>no data found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
