import React from "react";

export const ChevronUp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.588 13.0899C15.2625 13.4153 14.7349 13.4153 14.4094 13.0899L9.9987 8.67916L5.58795 13.0899C5.26252 13.4153 4.73488 13.4153 4.40944 13.0899C4.084 12.7645 4.084 12.2368 4.40944 11.9114L9.40944 6.9114C9.73488 6.58596 10.2625 6.58596 10.588 6.9114L15.588 11.9114C15.9134 12.2368 15.9134 12.7645 15.588 13.0899Z"
      fill="#29394D"
    />
  </svg>
);
