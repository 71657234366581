import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";
import { getCollaboratorsGlobalReporting } from "../../../api";
import _ from "../../../i18n";
import TTPPaginator from "../../common/TTPPaginator";
import Item from "./Item";

import ReportingsFilter from "./ReportingFilter";
import "./Reporting.local.scss";

const Reporting = ({ token, navCommunity, language, hasAccess }) => {
  const [reportings, setReportings] = useState([]);
  const [filter, setFilter] = useState({
    keyWord: "",
    year: new Date().getFullYear().toString(),
    pageSize: 10,
    paginationPage: 1,
    collaborators: [],
    deletedStatus: null,
    clientCRMGroup: [],
    clientCRMTypes: [],
  });
  const [nbrReportings, setNbrReportings] = useState(0);

  let reportingsQuery = useQuery(
    ["getCollaboratorsGlobalReporting", token, navCommunity, filter],
    async () => {
      if (token && navCommunity) {
        try {
          const response = await getCollaboratorsGlobalReporting(
            navCommunity.id,
            token,
            filter
          );
          setReportings(response.data.data);
          setNbrReportings(response.data.data.length - 1);
        } catch (e) {
          return null;
        }
      }
    }
  );

  const renderFetchingLines = () => {
    let cardFetching = [];
    cardFetching.push(
      <div key={0} className="line_fetching_reporting header_fetching">
        <div className="infos"></div>

        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
        <div className="tags">
          <div className="tag gradiant" />
        </div>
      </div>
    );
    for (let i = 1; i <= 10; i++) {
      cardFetching.push(
        <div key={i} className="line_fetching">
          <div className="infos">
            <div className="infos_header gradiant" />
            <div className="infos_sub_header gradiant" />
          </div>

          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
          <div className="tags">
            <div className="tag gradiant" />
          </div>
        </div>
      );
    }
    return cardFetching;
  };

  const renderPaginate = () => {
    return (
      <TTPPaginator
        onPageChange={({ selected }) =>
          setFilter({ ...filter, paginationPage: selected + 1 })
        }
        pageSize={filter.pageSize}
        nbResult={nbrReportings}
        paginationPage={filter.paginationPage}
      />
    );
  };

  return (
    <>
      { hasAccess === "1" ? (
        <div>
        <ReportingsFilter
          filterFormIsVisible={false}
          filter={filter}
          nbResult={nbrReportings}
          updateFilter={setFilter}
          fetching={reportingsQuery.isFetching}
        />
        <div className="result_label">
          {_("collaboratorCount")} : {nbrReportings}
        </div>
        <div className="medium-11">
          {reportingsQuery.isFetching ? (
            renderFetchingLines()
          ) : reportings.length ? (
            <Fragment>
              {reportings.map((item, i) => {
                return (
                  <Item
                    lng={language}
                    index={i}
                    item={item}
                    key={`reporting-${item.id}`}
                    year={filter.year}
                  />
                );
              })}
              {renderPaginate()}
            </Fragment>
          ) : (
            <div className="no_data">
              <div className="title">{_("noDataFound")}</div>
              <div className="subTitle">{_("noReportingsFound")}</div>
            </div>
          )}
        </div>
      </div>
      ) : ""
    }
    </>
  );
};

export default Reporting;
