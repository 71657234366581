import React from "react";

export const SynchroRuningIcon = ({ size = 20, fill = "#C7E8FE" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_68081_93017)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1673 2.5C19.6276 2.5 20.0007 2.8731 20.0007 3.33333V8.33333C20.0007 8.79357 19.6276 9.16667 19.1673 9.16667H14.1673C13.7071 9.16667 13.334 8.79357 13.334 8.33333C13.334 7.8731 13.7071 7.5 14.1673 7.5H18.334V3.33333C18.334 2.8731 18.7071 2.5 19.1673 2.5Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.193359 11.1966C-0.193359 10.7364 0.179737 10.3633 0.639974 10.3633H5.63997C6.10021 10.3633 6.47331 10.7364 6.47331 11.1966C6.47331 11.6569 6.10021 12.0299 5.63997 12.0299H1.47331V16.1966C1.47331 16.6569 1.10021 17.0299 0.639974 17.0299C0.179737 17.0299 -0.193359 16.6569 -0.193359 16.1966V11.1966Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8478 3.59716C10.766 3.28425 9.62253 3.25048 8.52415 3.499C7.42576 3.74752 6.40823 4.27024 5.5665 5.01838C4.72477 5.76651 4.08628 6.71569 3.7106 7.77733C3.55707 8.2112 3.08088 8.43846 2.64701 8.28493C2.21313 8.13139 1.98587 7.65521 2.13941 7.22133C2.60901 5.89428 3.40712 4.70782 4.45928 3.77265C5.51144 2.83748 6.78336 2.18408 8.15634 1.87342C9.52932 1.56277 10.9586 1.60498 12.3109 1.99612C13.6588 2.38602 14.8864 3.10997 15.8799 4.10074L19.7373 7.72537C20.0727 8.04053 20.0891 8.56791 19.774 8.90331C19.4588 9.23871 18.9314 9.25512 18.596 8.93996L14.7294 5.30663C14.723 5.30066 14.7168 5.2946 14.7106 5.28844C13.9145 4.49193 12.9296 3.91007 11.8478 3.59716ZM0.226042 11.0954C0.541201 10.76 1.06858 10.7435 1.40398 11.0587L5.27065 14.692C5.277 14.698 5.28325 14.7041 5.28941 14.7102C6.08552 15.5067 7.07042 16.0886 8.15223 16.4015C9.23403 16.7144 10.3775 16.7482 11.4759 16.4997C12.5742 16.2511 13.5918 15.7284 14.4335 14.9803C15.2752 14.2321 15.9137 13.283 16.2894 12.2213C16.4429 11.7875 16.9191 11.5602 17.353 11.7137C17.7869 11.8673 18.0141 12.3435 17.8606 12.7773C17.391 14.1044 16.5929 15.2908 15.5407 16.226C14.4886 17.1612 13.2167 17.8146 11.8437 18.1252C10.4707 18.4359 9.04138 18.3937 7.68913 18.0025C6.34117 17.6126 5.11356 16.8887 4.12009 15.8979L0.262689 12.2733C-0.0727107 11.9581 -0.089118 11.4307 0.226042 11.0954Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_68081_93017">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
