import React, { Component } from "react";
import { IconArrowRight } from "../../common/Icons";
import _ from "../../../i18n";

class TTPTab extends Component {
  selectTab = (ev, tabId) => {
    ev.preventDefault();
    this.props.selectTab(tabId);
  };

  render() {
    const { tabs, activeTab } = this.props;

    return (
      <div className="ttp-tab-selector">
        <nav>
          <button
            onClick={() =>
              this.props.arrowLeft ? this.props.handleActivateArrowLeft() : ""
            }
            style={{
              width: "52px",
              minWidth: "inherit",
              cursor: this.props.arrowLeft ? "" : "not-allowed",
              background: this.props.arrowLeft ? "" : "rgb(241, 242, 244)",
              opacity: this.props.arrowLeft ? 1 : "0.8",
            }}
          >
            <IconArrowRight />
          </button>
          {tabs.map((tab) => {
            return (
              <button
                key={tab.id}
                href="#"
                className={`${activeTab === tab.id ? "active" : ""}`}
                onClick={(ev) => this.selectTab(ev, tab.id)}
                style={{
                  width: tab.label && tab.label.length > 18 ? "250px" : "",
                }}
              >
                {_(tab.label)}
              </button>
            );
          })}
          <button
            onClick={() =>
              this.props.arrowRight ? this.props.handleActivateArrowRight() : ""
            }
            style={{
              width: "52px",
              minWidth: "inherit",
              transform: "rotate(180deg)",
              cursor: this.props.arrowRight ? "" : "not-allowed",
              background: this.props.arrowRight ? "" : "rgb(241, 242, 244)",
            }}
          >
            <IconArrowRight />
          </button>
        </nav>
      </div>
    );
  }
}

export default TTPTab;
