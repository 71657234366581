import React from "react";

export const UsersIcon = ({ size = 15, fill = "#29394D" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
  >
    <g clip-path="url(#clip0_59317_66706)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.1967 23.1967C3.60322 21.7902 5.51088 21 7.5 21H19.5C21.4891 21 23.3968 21.7902 24.8033 23.1967C26.2098 24.6032 27 26.5109 27 28.5V31.5C27 32.3284 26.3284 33 25.5 33C24.6716 33 24 32.3284 24 31.5V28.5C24 27.3065 23.5259 26.1619 22.682 25.318C21.8381 24.4741 20.6935 24 19.5 24H7.5C6.30653 24 5.16193 24.4741 4.31802 25.318C3.47411 26.1619 3 27.3065 3 28.5V31.5C3 32.3284 2.32843 33 1.5 33C0.671573 33 0 32.3284 0 31.5V28.5C0 26.5109 0.790177 24.6032 2.1967 23.1967Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5 6C11.0147 6 9 8.01472 9 10.5C9 12.9853 11.0147 15 13.5 15C15.9853 15 18 12.9853 18 10.5C18 8.01472 15.9853 6 13.5 6ZM6 10.5C6 6.35786 9.35786 3 13.5 3C17.6421 3 21 6.35786 21 10.5C21 14.6421 17.6421 18 13.5 18C9.35786 18 6 14.6421 6 10.5Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.548 22.3207C28.7551 21.5186 29.5732 21.0362 30.3754 21.2433C31.9844 21.6587 33.4098 22.5968 34.4279 23.9101C35.446 25.2235 35.9991 26.8378 36.0004 28.4996L36.0004 31.5007C36.0004 32.3291 35.3288 33.0007 34.5004 33.0007C33.6719 33.0007 33.0004 32.3291 33.0004 31.5007L33.0004 28.5018C33.0004 28.5016 33.0004 28.5019 33.0004 28.5018C32.9995 27.5049 32.6677 26.536 32.0569 25.7481C31.446 24.9601 30.5908 24.3973 29.6254 24.148C28.8232 23.9409 28.3409 23.1228 28.548 22.3207Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.5472 4.32361C22.7527 3.52108 23.5699 3.03707 24.3724 3.24255C25.9857 3.65561 27.4156 4.59386 28.4367 5.90938C29.4579 7.2249 30.0121 8.84285 30.0121 10.5082C30.0121 12.1735 29.4579 13.7914 28.4367 15.107C27.4156 16.4225 25.9857 17.3607 24.3724 17.7738C23.5699 17.9793 22.7527 17.4953 22.5472 16.6927C22.3418 15.8902 22.8258 15.073 23.6283 14.8675C24.5963 14.6197 25.4542 14.0568 26.0669 13.2674C26.6796 12.4781 27.0121 11.5074 27.0121 10.5082C27.0121 9.50898 26.6796 8.53821 26.0669 7.7489C25.4542 6.95959 24.5963 6.39664 23.6283 6.1488C22.8258 5.94332 22.3418 5.12615 22.5472 4.32361Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_59317_66706">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
