import React from "react";

export const ClipBoardIcon = ({ size = 16, fill = "#18A0FB" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 3.33333C3.82319 3.33333 3.65362 3.40357 3.5286 3.5286C3.40357 3.65362 3.33333 3.82319 3.33333 4V13.3333C3.33333 13.5101 3.40357 13.6797 3.5286 13.8047C3.65362 13.9298 3.82319 14 4 14H12C12.1768 14 12.3464 13.9298 12.4714 13.8047C12.5964 13.6797 12.6667 13.5101 12.6667 13.3333V4C12.6667 3.82319 12.5964 3.65362 12.4714 3.5286C12.3464 3.40357 12.1768 3.33333 12 3.33333H10.6667C10.2985 3.33333 10 3.03486 10 2.66667C10 2.29848 10.2985 2 10.6667 2H12C12.5304 2 13.0391 2.21071 13.4142 2.58579C13.7893 2.96086 14 3.46957 14 4V13.3333C14 13.8638 13.7893 14.3725 13.4142 14.7475C13.0391 15.1226 12.5304 15.3333 12 15.3333H4C3.46957 15.3333 2.96086 15.1226 2.58579 14.7475C2.21071 14.3725 2 13.8638 2 13.3333V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H5.33333C5.70152 2 6 2.29848 6 2.66667C6 3.03486 5.70152 3.33333 5.33333 3.33333H4Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.6665 2.0013C4.6665 1.26492 5.26346 0.667969 5.99984 0.667969H9.99984C10.7362 0.667969 11.3332 1.26492 11.3332 2.0013V3.33464C11.3332 4.07102 10.7362 4.66797 9.99984 4.66797H5.99984C5.26346 4.66797 4.6665 4.07102 4.6665 3.33464V2.0013ZM9.99984 2.0013H5.99984V3.33464H9.99984V2.0013Z"
      fill={fill}
    />
  </svg>
);
