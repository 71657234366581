import React from "react";

export const BookMarkIcon = ({ size = "14", fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 2C3.86739 2 3.74021 2.05268 3.64645 2.14645C3.55268 2.24021 3.5 2.36739 3.5 2.5V9.52841L6.20938 7.59313C6.38323 7.46896 6.61677 7.46896 6.79062 7.59313L9.5 9.52841V2.5C9.5 2.36739 9.44732 2.24021 9.35355 2.14645C9.25979 2.05268 9.13261 2 9 2H4ZM2.93934 1.43934C3.22064 1.15804 3.60218 1 4 1H9C9.39782 1 9.77936 1.15804 10.0607 1.43934C10.342 1.72064 10.5 2.10217 10.5 2.5V10.5C10.5 10.6873 10.3953 10.8589 10.2288 10.9446C10.0623 11.0303 9.86179 11.0157 9.70938 10.9069L6.5 8.61445L3.29062 10.9069C3.13821 11.0157 2.93774 11.0303 2.77121 10.9446C2.60467 10.8589 2.5 10.6873 2.5 10.5V2.5C2.5 2.10218 2.65804 1.72064 2.93934 1.43934Z"
      fill={fill}
    />
  </svg>
);
