import React from "react";

import _ from "../../../i18n";
import TTPFilterHorizontalBar from "../../common/Filter/TTPFilterHorizontalBar";

const EcheancesFilter = ({ filter, nbResult, updateFilter }) => {
  const handleChangeZone = (id) => {
    updateFilter({
      ...filter,
      zone: id,
    });
  };

  const renderCustomAddOn = () => {
    return (
      <div className="filter_custom">
        <div className="mini-tabs-frame">
          {[
            {
              id: null,
              label: "all",
              title: "tous les collaborateurs",
            },
            {
              id: "1",
              label: "belgique",
              title: "les collaborateurs de la belgique",
            },
            {
              id: "2",
              label: "maroc",
              title: "les collaborateurs du maroc",
            },
          ].map((item) => (
            <div
              className={filter.zone === item.id && "mini-tabs-frame_active"}
              onClick={() => handleChangeZone(item.id)}
            >
              {_(item.label)}
            </div>
          ))}
        </div>
      </div>
    );
  };

  let filters = [];

  let countFilter = 0;
  filters.forEach((filter) => {
    if (filter && filter.options.values && filter.options.values.length > 0) {
      countFilter++;
    }
  });

  return (
    <div className="row align-center medium-12">
      <div className="medium-12">
        <TTPFilterHorizontalBar
          subBG="F8F9FA"
          filter={filter}
          filters={filters}
          nbResult={nbResult}
          countFilter={countFilter}
          customAddOn={renderCustomAddOn()}
          updateFilterContent={updateFilter}
          cssClass="medium-12"
        />
      </div>
    </div>
  );
};

export default EcheancesFilter;
