import React, { useState, useEffect, useRef } from "react";
import "./Planner.local.scss";
import _ from "../../../i18n";
import { Droppable, Draggable } from "react-beautiful-dnd";
import moment from "moment";
import classNames from "classnames";
import {
  IconPlus,
  IconTrash,
  IconPencil,
  RefreshIcon,
} from "../../common/Icons";
import { renderAvatar } from "../../../utils";
import {
  deleteCollaboratorPlanner,
  removeTeamsMeetingEvent,
} from "../../../api";
import { toast } from "react-toastify";

const Item = ({
  events,
  setEvents,
  backupEvents,
  token,
  organizationId,
  setOpenAddEvent,
  setSelectedDateEvent,
  renderDurationDisplay,
  formatDuration,
  timeSheetByDays,
  timeSheetNFByDays,
  holidays,
  durationsByDay,
  workingHours,
  dayMapping,
  day,
  dayIndex,
  setSelectedCategory,
  selectedDateEvent,
  selectedDurationHours,
  setSelectedDurationHours,
  setSelectedDurationTravelHours,
  setSelectedDuration,
  selectedDurationMin,
  setSelectedDurationMin,
  setSelectedDurationTravelMin,
  setSelectedRecurrenceDetails,
  setSelectedEvent,
  selectedStatus,
  setSelectedStatus,
  selectedPlaceRDV,
  setSelectedPlaceRDV,
  selectedDescription,
  setSelectedDescription,
  setSelectedTypeEvent,
  setSelectedTitleEvent,
  setSelectedClient,
  setSelectedEventId,
  weekIndex,
  collapsedWeeks,
  toggleCollapseWeek,
  isMonthVue,
  selectedMeetingInfo,
  setSelectedMeetingInfo,
  onClickEvent,
  selectedEventId,
  selectedCollaboratorBackupTabs,
  backupInformations,
}) => {
  const daysOfWeek = [
    { index: 0, name: "Lundi" },
    { index: 1, name: "Mardi" },
    { index: 2, name: "Mercredi" },
    { index: 3, name: "Jeudi" },
    { index: 4, name: "Vendredi" },
  ];
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const allEvents = [];
  const tooltipRef = useRef(null);

  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, callback]);
  };
  useOutsideClick(tooltipRef, () => setTooltipVisible(false));

  const deletePlanner = async (event) => {
    if (event && token && organizationId) {
      try {
        // removing event from Microsoft Outlook Calendar
        const msApiAccessToken = localStorage.getItem("ms_api_access_token");

        if (msApiAccessToken) {
          // remove event from Outlook Planner
          await removeTeamsMeetingEvent(msApiAccessToken, event.meetingId);
        }

        // remove planner from database
        const response = await deleteCollaboratorPlanner(
          event.id,
          organizationId,
          token
        );

        if (response.data.result === "OK") {
          const updatedEvents = events.filter((e) => e.id !== event.id);
          setEvents(updatedEvents);
          toast.success(_("saveSuccess"));
        } else {
          toast.error(_("savingError"), { autoClose: true });
          // toast.error("Configurer votre compte Outlook pour supprimer le planner aussi de votre compte Outlook.");
        }
      } catch (e) {
        toast.error(_("savingError"), { autoClose: true });
        return null;
      }
    }
  };
  // const createRecurringEvent = (event) => {
  //   const recurrenceDetails = {
  //     type: selectedRecurrenceType,
  //     day_of_month: selectedDayOfMonth,
  //     nth_week: selectedNthWeek,
  //     day_of_week: selectedDayOfWeek,
  //     recurrence_end_date: recurrenceEndDate,
  //   };
  //   let updatedEvent = {
  //     ...event,
  //     recurrenceDetails: recurrenceDetails,
  //   };
  //   let updatedEvents = [];
  //   setSelectedRecurrenceDetails(recurrenceDetails);

  //   updatedEvents = events.map((e) => (e.id === event.id ? updatedEvent : e));
  //   setEvents(updatedEvents);

  //   setSelectedDateEvent(moment(event.date.date).toDate());
  //   setSelectedDurationHours(Math.floor(event.duration / 60));
  //   setSelectedDurationMin(event.duration % 60);
  //   setSelectedDurationTravelHours(Math.floor(event.durationTravel / 60));
  //   setSelectedDurationTravelMin(event.durationTravel % 60);
  //   setSelectedStatus(event.status);
  //   setSelectedPlaceRDV(event.meetingPlace);
  //   setSelectedDescription(event.description);
  //   setSelectedTypeEvent(event.type);
  //   setSelectedTitleEvent(event.title);
  //   setSelectedEventId(event.id);
  //   setSelectedDuration(event.duration);
  //   setSelectedEvent(updatedEvent);
  // };
  const convertDecimalTime = (decimalTime) => {
    let hours = Math.floor(decimalTime);
    let decimalPart = decimalTime - hours;

    let minutes = Math.round(decimalPart * 60);

    return hours + "h " + minutes;
  };

  const generateRecurringEvents = (event) => {
    const recurrenceDetails = event.recurrenceDetails;
    if (!recurrenceDetails) return [];

    const occurrences = [];
    const startDate = moment(event.date.date, "YYYY-MM-DD");
    const endDate = moment(recurrenceDetails.recurrence_end_date, "YYYY-MM-DD");
    let nextOccurrence = startDate.clone();

    if (recurrenceDetails.type === "weekly") {
      while (nextOccurrence.isBefore(endDate)) {
        recurrenceDetails.day_of_week.forEach((day) => {
          let occurrence = nextOccurrence.clone().day(day);
          if (
            occurrence.isSameOrAfter(event.date.date) &&
            occurrence.isBefore(endDate) &&
            occurrence.isAfter(event.date.date)
          ) {
            occurrences.push({
              ...event,
              date: { date: occurrence.format("YYYY-MM-DD") },
            });
          }
        });
        nextOccurrence.add(1, "week");
      }
    }

    if (recurrenceDetails.type === "monthly") {
      while (nextOccurrence.isBefore(endDate)) {
        let firstDayOfMonth = nextOccurrence.clone().startOf("month");

        let occurrence = firstDayOfMonth
          .clone()
          .day(recurrenceDetails.day_of_week[0]);

        if (occurrence.isBefore(firstDayOfMonth)) {
          occurrence.add(7, "days");
        }
        occurrence.add((recurrenceDetails.day_of_month - 1) * 7, "days");

        if (
          occurrence.isSameOrAfter(startDate) &&
          occurrence.isBefore(endDate)
        ) {
          occurrences.push({
            ...event,
            date: { date: occurrence.format("YYYY-MM-DD") },
          });
        }

        nextOccurrence.add(1, "month");
      }
    }

    return occurrences;
  };

  events.forEach((event) => {
    const recurringEvents = generateRecurringEvents(event, day);
    allEvents.push(...recurringEvents, event);
  });

  const cardEvents = (events, isBackup) => {
    const avatar = isBackup
      ? backupInformations.backupAvatar
      : backupInformations.collaboratorAvatar;
    const name = isBackup
      ? backupInformations.backupName
      : backupInformations.collaboratorName;
    return (
      <div>
        {events
          .filter(
            (event) =>
              event.date &&
              moment(moment(event.date.date, "Y-M-D").toDate()).isSame(
                day,
                "day"
              )
          )
          .map((filteredEvent, eventIndex) => (
            <div key={eventIndex} className={`event ${filteredEvent.status}`}>
              <div className="event_type">
                <div className="line_content">
                  {filteredEvent.type === "task" && (
                    <div className="circle">
                      <div
                        className={classNames(
                          "circle",
                          filteredEvent.status === "done" ||
                            filteredEvent.status === "Terminé"
                            ? "tag-success"
                            : filteredEvent.status === "inprogress" ||
                              filteredEvent.status === "En cours"
                            ? "tag-warning"
                            : "tag-danger"
                        )}
                      ></div>
                    </div>
                  )}

                  <div className={`type`}>
                    {filteredEvent.type !== "meeting"
                      ? _(filteredEvent.type)
                      : _(filteredEvent.type) +
                        " : " +
                        _(filteredEvent.meetingPlace)}
                  </div>
                  {console.log(
                    "filteredEvent====== item======",
                    !filteredEvent.parentId ||
                      !filteredEvent.parent_id ||
                      (filteredEvent.recurrenceDetails &&
                        filteredEvent.recurrenceDetails.type)
                  )}
                  {(!filteredEvent.parentId ||
                    !filteredEvent.parent_id ||
                    (filteredEvent.recurrenceDetails &&
                      filteredEvent.recurrenceDetails.type)) && <RefreshIcon />}
                </div>
                <div className="line_content">
                  <div
                    className={`type duration ${!isBackup && "item_duration"}`}
                  >
                    {formatDuration(filteredEvent.duration)}
                  </div>
                  {!isBackup && (
                    <div className="event_actions line_content">
                      <div onClick={() => onClickEvent(filteredEvent, true)}>
                        <IconPencil size={12} />
                      </div>

                      <div onClick={() => deletePlanner(filteredEvent)}>
                        <IconTrash size={12} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="client_name">
                {filteredEvent.type !== "management"
                  ? filteredEvent.client_name
                  : filteredEvent.title}
              </div>
              {filteredEvent.type !== "meeting" && (
                <div className="line_space_content" style={{ width: "100%" }}>
                  <div className="category_name">
                    {filteredEvent.category_name}
                  </div>
                  <div>{renderAvatar(avatar, name)}</div>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };
  const cardOneEvent = (filteredEvent, isBackup, eventIndex) => {
    console.log("filteredEvent=========card", filteredEvent.avatars);
    const avatar = isBackup
      ? backupInformations.backupAvatar
      : backupInformations.collaboratorAvatar;
    const name = isBackup
      ? backupInformations.backupName
      : backupInformations.collaboratorName;
    return (
      <div>
        <div key={eventIndex} className={`event ${filteredEvent.status}`}>
          <div className="event_type">
            <div className="line_content">
              {filteredEvent.type === "task" && (
                <div className="circle">
                  <div
                    className={classNames(
                      "circle",
                      filteredEvent.status === "done" ||
                        filteredEvent.status === "Terminé"
                        ? "tag-success"
                        : filteredEvent.status === "inprogress" ||
                          filteredEvent.status === "En cours"
                        ? "tag-warning"
                        : "tag-danger"
                    )}
                  ></div>
                </div>
              )}

              <div className={`type`}>
                {filteredEvent.type !== "meeting"
                  ? _(filteredEvent.type)
                  : _(filteredEvent.type) +
                    " : " +
                    _(filteredEvent.meetingPlace)}
              </div>
              {!filteredEvent.parentId &&
              !filteredEvent.parent_id &&
              !(
                filteredEvent.recurrenceDetails &&
                filteredEvent.recurrenceDetails.type
              ) ? null : (
                <RefreshIcon />
              )}
            </div>
            <div className="line_content">
              <div className={`type duration ${!isBackup && "item_duration"}`}>
                {formatDuration(filteredEvent.duration)}
              </div>
              {!isBackup && (
                <div className="event_actions line_content">
                  <div onClick={() => onClickEvent(filteredEvent, true)}>
                    <IconPencil size={12} />
                  </div>

                  <div onClick={() => deletePlanner(filteredEvent)}>
                    <IconTrash size={12} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="client_name">
            <div>
              {filteredEvent.type !== "management"
                ? filteredEvent.client_name
                : filteredEvent.title}
            </div>
            {console.log(
              "filteredEvent.avatars=========",
              filteredEvent.avatars
            )}
            {filteredEvent.type === "meeting" && filteredEvent.avatars && (
              <div style={{ width: "100%", display: "flex" }}>
                {console.log(
                  "filteredEvent avatarrrr fff===========",
                  filteredEvent.avatars
                )}
                {filteredEvent.avatars &&
                  filteredEvent.avatars.map((item) => (
                    <div>{renderAvatar(item.avatar, item.name)}</div>
                  ))}
              </div>
            )}
          </div>
          {filteredEvent.type !== "meeting" && (
            <div className="line_space_content" style={{ width: "100%" }}>
              <div className="category_name">{filteredEvent.category_name}</div>
              <div>{renderAvatar(avatar, name)}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="calendar_columns" key={dayIndex}>
      <div className={classNames("day_label")}>{day.format("dddd")}</div>
      <div className={classNames("day_label")}>{day.format("DD")}</div>
      <div
        className={classNames(
          "day_content",
          day.isSame(moment(), "day") ? "selected_day" : ""
        )}
      >
        <div className="day_header line_space_content">
          <div
            onClick={() => {
              setOpenAddEvent(true);
              const selectedDateWithTime = moment(day)
                .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
                .toDate();
              setSelectedDateEvent(selectedDateWithTime);
              setSelectedEventId(null);
            }}
          >
            <IconPlus size={13} />
          </div>

          <div className="realised">
            Realisé:
            {timeSheetByDays.filter(
              (task) =>
                task.date &&
                moment(task.date.replace(" ", "T") + "Z").isSame(day, "day")
            ).length > 0 ? (
              timeSheetByDays
                .filter((filteredEvent) =>
                  moment(filteredEvent.date.replace(" ", "T") + "Z").isSame(
                    day,
                    "day"
                  )
                )
                .map((filteredEvent, eventIndex) => (
                  <>
                    {renderDurationDisplay(
                      filteredEvent.total_task_duration * 60
                    )}
                  </>
                ))
            ) : (
              <>{renderDurationDisplay(0)}</>
            )}
          </div>

          <div
            className="disponibility"
            style={{
              background:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(255, 226, 221)",
              color:
                holidays.filter((event) =>
                  moment(event.date).isSame(day, "day")
                ).length > 0 && "rgb(93, 23, 21)",
            }}
          >
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length > 0
              ? holidays
                  .filter((event) => moment(event.date).isSame(day, "day"))
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{
                        background: "rgb(255, 226, 221)",
                        backgroundColor: holiday.key,
                        color: "rgb(93, 23, 21)",
                      }}
                    >
                      {holiday.label.slice(0, 14)}
                    </div>
                  ))
              : durationsByDay &&
                durationsByDay.filter((duration) =>
                  moment(duration.date).isSame(day, "day")
                ).length > 0
              ? durationsByDay
                  .filter((duration) =>
                    moment(duration.date).isSame(day, "day")
                  )
                  .map((holiday, holidayIndex) => (
                    <div
                      className="filteredEvent"
                      key={holidayIndex}
                      style={{ background: holiday.key }}
                    >
                      {formatDuration(holiday.duration)}
                    </div>
                  ))
              : formatDuration(0)}
            {holidays.filter((event) => moment(event.date).isSame(day, "day"))
              .length === 0 && (
              <>
                {" "}
                sur{" "}
                {convertDecimalTime(
                  workingHours[dayMapping[moment(day).format("ddd")]]
                )}
              </>
            )}
          </div>
        </div>
        {console.log("events==============", events)}
        <div className="events">
          <Droppable droppableId={day.format("YYYY-MM-DD")}>
            {(provided) => (
              <div
                className="events"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {events
                  .filter((event) => moment(event.date.date).isSame(day, "day"))
                  .map((event, index) => {
                    return (
                      <Draggable
                        key={`${day.format("YYYY-MM-DD")}-${
                          event.id
                            ? event.id.toString()
                            : event.parentId
                            ? event.parentId.toString()
                            : event.date.date
                        }`}
                        draggableId={
                          event.id
                            ? event.id.toString()
                            : event.parentId
                            ? event.parentId.toString()
                            : event.date.date
                        }
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              className={classNames(
                                collapsedWeeks[weekIndex] ? "collapsed" : ""
                              )}
                            >
                              {selectedCollaboratorBackupTabs !== 2 &&
                                cardOneEvent(
                                  {
                                    ...event,
                                    avatars: events[index]["avatars"],
                                  },
                                  false,
                                  index
                                )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                {selectedCollaboratorBackupTabs !== 1 &&
                  backupEvents
                    .filter((event) =>
                      moment(event.date.date).isSame(day, "day")
                    )
                    .map((event, index) => (
                      <div
                        key={`backup-${event.id}`}
                        className={classNames(
                          "events",
                          collapsedWeeks[weekIndex] ? "collapsed" : ""
                        )}
                      >
                        {cardOneEvent(event, true, index)}
                      </div>
                    ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </div>
  );
};

export default Item;
