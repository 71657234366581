import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getClusters } from "../api";

export const fetchClusters = createAsyncThunk(
    "cluster/fetchClusters",
  
    async (searchWord, { dispatch, getState }) => {
      const { token, navCommunity } = getState().auth;
  
      let organizationId = navCommunity.id;
      const response = await getClusters({
        token,
        organizationId,
        searchWord,
      });
      return response;
    }
  );
  
  const initialState = {
    fetching: false,
    fetched: false,
    data: [],
    error: null,
  };
  
  export const clustersSlice = createSlice({
    name: "clusters",
    initialState,
    extraReducers: {
      [fetchClusters.pending]: (state, action) => {
        state.fetching = true;
      },
      [fetchClusters.fulfilled]: (state, { payload }) => {
        state.fetching = false;
        state.data = payload.data.data;
      },
      [fetchClusters.rejected]: (state, { payload }) => {
        state.error = payload;
        state.fetching = false;
        state.data = [];
      },
    },
  });
  
  export default clustersSlice.reducer;
  