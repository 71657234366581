import { useEffect, useRef, useState } from "react";
import { renderDurationDisplay } from "../../../../utils";
import styles from "./AnimatedBar.module.scss";

export default function AnimatedBar({ data }) {
  const [loading, setLoading] = useState(true);
  const barContentRef = useRef();

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (barContentRef.current && !loading) {
      const contentWidth = barContentRef.current.scrollWidth;
      const barWidth = barContentRef.current.clientWidth;
      const duration = Math.max((contentWidth / barWidth) * 20, 10);
      barContentRef.current.style.animationDuration = `${duration}s`;
    }
  }, [data, loading]);

  return (
    <div className={styles.bar}>
      {loading ? (
        <div className={styles.placeholder_item} />
      ) : (
        <div className={styles.bar_content} ref={barContentRef}>
          <div>
            {data &&
              data.length &&
              data.map((item, index) => (
                <div
                  key={`${index} ${item.col_id}`}
                  className={styles.bar_content_item}
                >
                  <div className={styles.bar_content_item_label}>
                    <span className={styles.bar_content_item_label_name}>
                      {item.col_name}
                    </span>
                    <span
                      className={styles.bar_content_item_label_since}
                    >{`il y a ${renderDurationDisplay(item.since)}`}</span>
                  </div>
                  <div className={styles.bar_content_item_separator} />
                  <div className={styles.bar_content_item_details}>
                    <span>
                      {`Temps réalisé: `}
                      <span className={styles.duration_value}>
                        {renderDurationDisplay(item.duration)}
                      </span>
                    </span>
                    <div className={styles.dot} />
                    <span style={{ fontWeight: 500, color: "#29394d" }}>
                      {item.task_type}
                    </span>
                    <div className={styles.dot} />
                    <span>{item.category}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
