import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DrawerIframe from "../../common/DrawerIframe";
import { TTP_TEAM_URL } from "../../../config";

const CollaboratorDashboardIframe = ({ children, id, name, year }) => {
  const auth = useSelector((state) => state.auth);
  const [fiduciaryId, setFiduciaryId] = useState(null);

  useEffect(() => {
    if (auth.navCommunity) {
      setFiduciaryId(auth.navCommunity.id);
    }
  }, [auth.navCommunity]);

  const params = [];
  let powerTeamUrl =
    TTP_TEAM_URL +
    `/capacity-planing/collaborator/${name}/${id}/COLLABORATOR_SPACE`;

  if (fiduciaryId) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + fiduciaryId);
    params.push("year=" + year);
    return <DrawerIframe src={powerTeamUrl} params={params} />;
  }
  return <></>;
};

export default CollaboratorDashboardIframe;
