import React from "react";

export const RefreshIcon = ({ size = 15, fill = "#29394D" }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1586_48598)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5004 1.40002C11.8318 1.40002 12.1004 1.66865 12.1004 2.00002V5.00002C12.1004 5.33139 11.8318 5.60002 11.5004 5.60002H8.50039C8.16902 5.60002 7.90039 5.33139 7.90039 5.00002C7.90039 4.66865 8.16902 4.40002 8.50039 4.40002H10.9004V2.00002C10.9004 1.66865 11.169 1.40002 11.5004 1.40002Z"
        fill="#18A0FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M-0.0996094 7.00002C-0.0996094 6.66865 0.16902 6.40002 0.500391 6.40002H3.50039C3.83176 6.40002 4.10039 6.66865 4.10039 7.00002C4.10039 7.3314 3.83176 7.60002 3.50039 7.60002H1.10039V10C1.10039 10.3314 0.831761 10.6 0.500391 10.6C0.16902 10.6 -0.0996094 10.3314 -0.0996094 10V7.00002Z"
        fill="#18A0FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.08127 2.25476C6.44842 2.07171 5.7795 2.05195 5.13695 2.19734C4.49439 2.34272 3.89914 2.64851 3.40672 3.08617C2.91431 3.52383 2.54079 4.0791 2.32102 4.70016C2.21048 5.01255 1.86762 5.17617 1.55523 5.06563C1.24285 4.95509 1.07922 4.61223 1.18976 4.29984C1.47716 3.48769 1.9656 2.76157 2.60953 2.18925C3.25345 1.61692 4.03186 1.21704 4.87213 1.02692C5.71239 0.836802 6.58712 0.862637 7.4147 1.10202C8.23918 1.3405 8.99008 1.78318 9.5979 2.38899L11.9113 4.56275C12.1527 4.78966 12.1646 5.16938 11.9376 5.41087C11.7107 5.65235 11.331 5.66417 11.0895 5.43725L8.76953 3.25725C8.76496 3.25296 8.76046 3.24859 8.75602 3.24416C8.2903 2.7782 7.71413 2.43782 7.08127 2.25476ZM0.0631406 6.58914C0.290056 6.34765 0.669771 6.33583 0.911258 6.56275L3.23126 8.74275C3.23583 8.74704 3.24033 8.75141 3.24476 8.75584C3.71049 9.2218 4.28666 9.56219 4.91951 9.74524C5.55237 9.9283 6.22128 9.94805 6.86384 9.80266C7.50639 9.65728 8.10165 9.35149 8.59406 8.91383C9.08647 8.47617 9.45999 7.9209 9.67976 7.29984C9.79031 6.98745 10.1332 6.82383 10.4456 6.93437C10.7579 7.04492 10.9216 7.38777 10.811 7.70016C10.5236 8.51231 10.0352 9.23843 9.39126 9.81075C8.74734 10.3831 7.96893 10.783 7.12866 10.9731C6.28839 11.1632 5.41366 11.1374 4.58608 10.898C3.7616 10.6595 3.0107 10.2168 2.40288 9.61101L0.0895265 7.43725C-0.151961 7.21034 -0.163774 6.83062 0.0631406 6.58914Z"
        fill="#18A0FB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1586_48598">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
