import React from "react";

export const BriefCaseIcon = ({ size = 20, fill = "#C7E8FE" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.33398 6.66667C2.87375 6.66667 2.50065 7.03976 2.50065 7.5V15.8333C2.50065 16.2936 2.87375 16.6667 3.33398 16.6667H16.6673C17.1276 16.6667 17.5007 16.2936 17.5007 15.8333V7.5C17.5007 7.03976 17.1276 6.66667 16.6673 6.66667H3.33398ZM0.833984 7.5C0.833984 6.11929 1.95327 5 3.33398 5H16.6673C18.048 5 19.1673 6.11929 19.1673 7.5V15.8333C19.1673 17.214 18.048 18.3333 16.6673 18.3333H3.33398C1.95327 18.3333 0.833984 17.214 0.833984 15.8333V7.5Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.56622 2.4002C7.03506 1.93136 7.67094 1.66797 8.33398 1.66797H11.6673C12.3304 1.66797 12.9662 1.93136 13.4351 2.4002C13.9039 2.86904 14.1673 3.50493 14.1673 4.16797V17.5013C14.1673 17.9615 13.7942 18.3346 13.334 18.3346C12.8737 18.3346 12.5007 17.9615 12.5007 17.5013V4.16797C12.5007 3.94696 12.4129 3.73499 12.2566 3.57871C12.1003 3.42243 11.8883 3.33464 11.6673 3.33464H8.33398C8.11297 3.33464 7.90101 3.42243 7.74473 3.57871C7.58845 3.73499 7.50065 3.94696 7.50065 4.16797V17.5013C7.50065 17.9615 7.12756 18.3346 6.66732 18.3346C6.20708 18.3346 5.83398 17.9615 5.83398 17.5013V4.16797C5.83398 3.50493 6.09738 2.86904 6.56622 2.4002Z"
      fill={fill}
    />
  </svg>
);
