import { useEffect, useState } from "react";
import _ from "../../i18n";
import { IconChevronDown, IconClose, IconPlus } from "../common/Icons";
import styles from "./Forfait.module.scss";
import { toast } from "react-toastify";
import { getForfaitRemarks, saveForfaitRemarks } from "../../api";
import { useQuery } from "react-query";

export default function ForfaitSidePanel({
  visible,
  onClose,
  client,
  organization,
  token,
  year
}) {
  const [analyseCauses, setAnalyseCauses] = useState("");
  const [analyseForfaits, setAnalyseForfaits] = useState("");
  const [proposals, setProposals] = useState("");
  // const [year, setYear] = useState("2024");
  const [data, setData] = useState({});
  const [viewList, setViewList] = useState(true);
  const [expanded, setExpanded] = useState("2024");

  const handleCancelSidePanel = () => {
    setAnalyseCauses("");
    setAnalyseForfaits("");
    setProposals("");
    setViewList(true);
  };

  const validated =
    analyseCauses.trim() !== "" ||
    analyseForfaits.trim() !== "" ||
    proposals.trim() !== "";
  const handleValidation = () => {
    if (!validated) {
      toast.error(_("At Least One Is Required"));
      return false;
    }
    return true;
  };

  const handleSave = () => {
    if (handleValidation()) {
      const data = {
        causes: analyseCauses,
        forfaits: analyseForfaits,
        proposals,
        client,
        organization,
        year,
        token,
      };

      saveForfaitRemarks(data)
        .then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_added"));
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
        })
        .finally(() => {
          handleCancelSidePanel();
        });
    }
  };

  const fetchForfaitRemarks = async () => {
    if (token && organization && client) {
      try {
        const response = await getForfaitRemarks(
          token,
          organization,
          client,
          year
        );
        setData(response.data.data.remarks);
        console.log("=d", response.data.data.remarks);
        return response.data.data;
      } catch (e) {
        throw new Error(e.message);
      }
    }
  };

  useQuery(
    ["getForfaitRemarks", token, organization, client],
    fetchForfaitRemarks,
    { enabled: !!token && !!organization && !!client }
  );

  useEffect(() => {
    fetchForfaitRemarks();
  }, [viewList]);

  // const { cause, forfait, proposal } = data;
  // const hasRemarks =
  //   cause.length > 0 || forfait.length > 0 || proposal.length > 0;

  return (
    <>
      {visible ? (
        <div
          className={`sidebar ${styles.sidebar}`}
          style={{ transform: "translateX(0)" }}
        >
          <div className="sidebar_header">
            <h2>{_("revisionForfait")}</h2>
            <div onClick={onClose} style={{ cursor: "pointer" }}>
              <IconClose />
            </div>
          </div>
          <div className={styles.sidebar_content}>
            {viewList ? (
              <div className={styles.sidebar_content__list}>
                <div className={styles.sidebar_content__list_head}>
                  <h3>List des remarques</h3>
                  <div
                    onClick={() => setViewList(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <IconPlus size={25} />
                  </div>
                </div>

                {data && Object.keys(data).length > 0 ? (
                  Object.keys(data).map((key) => (
                    <>
                      <li
                        className={styles.sidebar_content__sub_head}
                        onClick={() => setExpanded(_(`${key}`))}
                      >
                        <span className={styles.sidebar_content__sub_head_key}>
                          {key}
                        </span>
                        <div
                          style={{
                            transform:
                              expanded == key ? "rotate(0)" : "rotate(-90deg)",
                          }}
                        >
                          <IconChevronDown />
                        </div>
                      </li>
                      <div
                        style={{
                          maxHeight: expanded == key ? "fit-content" : 0,
                          overflow: expanded == key ? "none" : "hidden",
                        }}
                      >
                        <RemarksList
                          label={_("cause")}
                          remarks={data[key].cause}
                        />
                        <RemarksList
                          label={_("Analyse des Forfaits")}
                          remarks={data[key].forfait}
                        />
                        <RemarksList
                          label={_("Proposition")}
                          remarks={data[key].proposal}
                        />
                      </div>
                      <br />
                    </>
                  ))
                ) : (
                  <div className="no_data">
                    <div className="title">
                      {_("Aucune remarque trouvée !")}
                    </div>
                    <div
                      className="subTitle"
                      style={{ cursor: "pointer" }}
                      onClick={() => setViewList(false)}
                    >
                      {_("+ Ajouter Nouveau")}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <TextArea
                  label={_("cause")}
                  value={analyseCauses}
                  onChange={(e) => setAnalyseCauses(e.target.value)}
                />
                <TextArea
                  label="Analyse des forfaits"
                  value={analyseForfaits}
                  onChange={(e) => setAnalyseForfaits(e.target.value)}
                  rows={8}
                />
                <TextArea
                  label="Proposition"
                  value={proposals}
                  onChange={(e) => setProposals(e.target.value)}
                  rows={8}
                />

                <div className={styles.action_button}>
                  <div
                    className={`${styles.action_button__item} ${styles.button_save}`}
                    onClick={handleSave}
                    style={{
                      cursor: validated ? "pointer" : "not-allowed",
                      opacity: validated ? 1 : 0.5,
                    }}
                  >
                    {_("save")}
                  </div>
                  <div
                    className={`${styles.action_button__item} ${styles.button_cancel}`}
                    onClick={handleCancelSidePanel}
                  >
                    {_("cancel")}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export const TextArea = ({ label, value, onChange, rows = 5 }) => {
  return (
    <div className={styles.forfait__textarea}>
      <label className={styles.forfait__textarea_label}>{label}</label>
      <textarea
        className={styles.forfait__textarea_textarea}
        placeholder={_(`Description des ${label}`)}
        cols={60}
        rows={rows}
        value={value}
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export const RemarksList = ({ label, remarks }) => {
  return (
    <div
      className={styles.forfait__remarks_list}
      style={{ padding: "5px 10px" }}
    >
      <div className={styles.forfait__remarks_list__head}>
        <h4>{label}</h4>
      </div>
      <div>
        {remarks &&
          remarks.map((remark, index) => (
            <div
              key={`remark-${index}`}
              className={styles.forfait__remarks_list__item}
            >
              {remark}
            </div>
          ))}
      </div>
    </div>
  );
};
