import React from "react";

export const FilterIcon = ({ fill = "#6D7F92" }) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7379 8.01692L12.9818 8.01692C13.5441 8.01692 14 7.56096 14 6.99875C14 6.43639 13.5441 5.98047 12.9818 5.98047L11.7379 5.98047C11.8799 6.2917 11.9637 6.63482 11.9637 6.99875C11.9637 7.36253 11.8799 7.70565 11.7379 8.01692Z"
      fill={fill}
    />
    <path
      d="M7.25 5.98047L1.01928 5.98047C0.456982 5.98047 0.00114582 6.43642 0.0011458 6.99875C0.00114577 7.56097 0.456982 8.01692 1.01928 8.01692L7.24974 8.01692C7.1078 7.70566 7.02399 7.36254 7.02399 6.99875C7.02399 6.63482 7.10806 6.2917 7.25 5.98047Z"
      fill={fill}
    />
    <path
      d="M10.9453 6.99871C10.9453 6.19827 10.2937 5.54687 9.49311 5.54687C8.69245 5.54687 8.04087 6.19827 8.04087 6.99871C8.04087 7.79954 8.69245 8.45126 9.49311 8.45126C10.2938 8.45129 10.9453 7.79954 10.9453 6.99871Z"
      fill={fill}
    />
    <path
      d="M1.64844 11.0742L1.01779 11.0742C0.455487 11.0742 -0.000348111 11.5301 -0.000348136 12.0923C-0.00034816 12.6547 0.455487 13.1105 1.01779 13.1105L1.64818 13.1105C1.50623 12.7994 1.42252 12.4563 1.42252 12.0923C1.42249 11.7284 1.5065 11.3855 1.64844 11.0742Z"
      fill={fill}
    />
    <path
      d="M6.13795 13.1105L12.9818 13.1105C13.5441 13.1105 14 12.6547 14 12.0923C14 11.5301 13.5441 11.0742 12.9818 11.0742L6.13795 11.0742C6.27983 11.3855 6.36364 11.7284 6.36364 12.0923C6.36364 12.4563 6.27983 12.7994 6.13795 13.1105Z"
      fill={fill}
    />
    <path
      d="M5.34375 12.0884C5.34375 11.2879 4.69229 10.6367 3.89154 10.6367C3.09088 10.6367 2.43931 11.2879 2.43931 12.0884C2.43931 12.8894 3.09088 13.5411 3.89154 13.5411C4.69229 13.5411 5.34375 12.8894 5.34375 12.0884Z"
      fill={fill}
    />
    <path
      d="M2.66797 0.890625L1.0191 0.890625C0.4568 0.890625 0.000966052 1.3464 0.000966028 1.90879C0.000966003 2.471 0.4568 2.92695 1.0191 2.92695L2.66771 2.92695C2.52574 2.61569 2.44193 2.27274 2.44193 1.90879C2.44193 1.54483 2.526 1.20188 2.66797 0.890625Z"
      fill={fill}
    />
    <path
      d="M7.15608 2.92695L12.9818 2.92695C13.5441 2.92695 14 2.471 14 1.90879C14 1.3464 13.5441 0.890625 12.9818 0.890625L7.15608 0.890625C7.29802 1.20188 7.38183 1.54483 7.38183 1.90879C7.38183 2.27274 7.29802 2.61569 7.15608 2.92695Z"
      fill={fill}
    />
    <path
      d="M6.36353 1.90869C6.36353 1.10823 5.71195 0.457031 4.91129 0.457031C4.11065 0.457031 3.45908 1.10823 3.45908 1.90869C3.45908 2.7095 4.11065 3.36142 4.91129 3.36142C5.71195 3.36142 6.36353 2.70947 6.36353 1.90869Z"
      fill={fill}
    />
  </svg>
);
