import React from "react";
// import { Link } from "react-router-dom";

import { Button } from "antd";
import _ from "../../../i18n";
import { ArrowLeftIcon } from "../Icons";
import "./PageHeader.local.scss";
import { useNavigate } from 'react-router-dom';

const LigthPageHeader = ({ title, onBack, children, goBack }) => {

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="ligth_page_header">
      <div className="ligth_page_header_left">
        {goBack && (
          // <Link to={goBack}>
            <div className="ligth_page_header_back">
              <Button
                key="cancel"
                style={{
                  height: 36,
                  width: 116,
                  marginRight: 15,
                  alignItems: "center",
                  color: "#6D7F92",
                  background: "#F8F9FA",
                  display: "flex",
                  textAlign: "center",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  justifyContent: "space-around",
                  border: "1px solid #B2BCC6",
                  boxSizing: "border-box",
                  borderRadius: "5px",
                }}
                onClick={() =>handleGoBack()}
              >
                <ArrowLeftIcon />
                {_("goBack")}
              </Button>
            </div>
          // </Link>
        )}
        <div className="ligth_page_header_info">
          <div>{_(title.label)} :</div> <span>{title.value}</span>
        </div>
      </div>

      <div className="ligth_page_header_children">{children}</div>
    </div>
  );
};

export default LigthPageHeader;
