import classNames from "classnames";
import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, ChevronUp } from "../common/Icons";
import styles from "./Budget.module.scss";
import { TableBorderedColumns } from "tamtam-components";
import { extractNumber, extractFloatNumber } from "../../utils";
import Select from "react-select";

const TableN = ({
  title,
  descrption,
  preferences,
  data,
  filteredOptions,
  setSelectedModel,
  selectedModelOption,
  setSelectedModelOption,
  updatedCategoryRoleList,
  setUpdatedCategoryRoleList,
  updatedCollaboratorsList,
  setUpdatedCollaboratorsList,
  totalGD,
  totalGE,
  totalSE,
  totalSupervisor,
  totalScanOp,
  totalGDdelegue,
  totalGEdelegue,
  totalSEdelegue,
  totalSupervisorDelegue,
  totalScanOpDelegue,
  settotalGD,
  settotalGE,
  settotalSE,
  settotalsupervisor,
  settotalScanOp,
  handleChangeRolesValues,
  existingRoles,
  setUpdated,
}) => {
  const [isTableReduced, setIsTableReduced] = useState(false);
  const [reducedTable, setReducedTable] = useState(updatedCategoryRoleList);

  const updatedCategoryRoleListRef = useRef(updatedCategoryRoleList);

  useEffect(() => {
    if (isTableReduced) {
      const filteredTable = updatedCategoryRoleList.filter(
        (item) => extractFloatNumber(item.totalPercentage) !== 0
      );
      setReducedTable(filteredTable);
    } else {
      setReducedTable(updatedCategoryRoleList);
    }
  }, [isTableReduced, updatedCategoryRoleList]);

  const bordrerdcolumns = [
    { label: "Total", haveHeader: true, isBordered: true, isEditable: false },
    {
      label: "Superviseur",
      haveHeader: true,
      isBordered: true,
      isEditable: existingRoles && existingRoles.includes("Superviseur"),
    },
    {
      label: "GD",
      haveHeader: true,
      isBordered: true,
      isEditable: existingRoles && existingRoles.includes("GD"),
    },
    {
      label: "SE",
      haveHeader: true,
      isBordered: true,
      isEditable: existingRoles && existingRoles.includes("SE"),
    },
    {
      label: "GE",
      haveHeader: true,
      isBordered: true,
      isEditable: existingRoles && existingRoles.includes("GE"),
    },
    {
      label: "ScanOp",
      haveHeader: true,
      isBordered: true,
      isEditable: existingRoles && existingRoles.includes("ScanOp"),
    },
  ];
  const height = "83px";
  const headerHeight = "58px";

  const handleInputChange = (value, label, role, rowIndex, field, symbole) => {
    const updatedReducedTable = [...reducedTable];
    // chercher l'index du tableau principale
    const updatedItem = updatedReducedTable[rowIndex];
    const matchingItemIndex = updatedCategoryRoleList.findIndex(
      (item) => item.id_category === updatedItem.id_category
    );

    if (field !== "checked" && isNaN(parseFloat(value))) {
      value = 0;
    }
    setUpdated(true);
    const numericValue =
      field !== "checked" ? extractFloatNumber(value) : value;
    //  to update the self value
    setUpdatedCategoryRoleList((prevCategoryRoleList) => {
      return prevCategoryRoleList.map((budgetItem, index) => {
        if (index === matchingItemIndex) {
          return {
            ...budgetItem,
            [role]: {
              ...budgetItem[role],
              [field]: numericValue,
            },
          };
        } else {
          return budgetItem;
        }
      });
    });
    let totalPercentageRole = [];
    totalPercentageRole[role] = 0;

    setUpdatedCategoryRoleList((prevCategoryRoleList) => {
      totalPercentageRole[role] = 0;
      return prevCategoryRoleList.map((budgetItem, index) => {
        if (index === matchingItemIndex) {
          if (field !== "checked") {
            if (field === "value_percentage") {
              const updatedValueHeure = parseFloat(
                extractFloatNumber(numericValue) > 0
                  ? (extractFloatNumber(budgetItem["Total"]["value_heure"]) *
                      extractFloatNumber(numericValue)) /
                      100
                  : 0
              );

              totalPercentageRole[role] +=
                extractFloatNumber(updatedValueHeure);

              const gdValuePercentage = parseFloat(
                role !== "GD"
                  ? extractNumber(budgetItem["GD"]["value_percentage"])
                  : numericValue
              );

              const seValuePercentage = parseFloat(
                role !== "SE"
                  ? extractNumber(budgetItem["SE"]["value_percentage"])
                  : numericValue
              );

              const geValuePercentage = parseFloat(
                role !== "GE"
                  ? extractNumber(budgetItem["GE"]["value_percentage"])
                  : numericValue
              );

              const superviseurValuePercentage = parseFloat(
                role !== "Superviseur"
                  ? extractNumber(budgetItem["Superviseur"]["value_percentage"])
                  : numericValue
              );

              const scanOpValuePercentage = parseFloat(
                role !== "ScanOp"
                  ? extractNumber(budgetItem["ScanOp"]["value_percentage"])
                  : numericValue
              );

              return {
                ...budgetItem,
                Total: {
                  ...budgetItem.Total,
                  value_percentage: parseFloat(
                    gdValuePercentage +
                      seValuePercentage +
                      geValuePercentage +
                      superviseurValuePercentage +
                      scanOpValuePercentage
                  ),
                },
                [role]: {
                  ...budgetItem[role],
                  value_heure: updatedValueHeure,
                },
              };
            } else if (field === "value_heure") {
              const updatedValuePercentage = parseFloat(
                extractFloatNumber(budgetItem["Total"]["value_heure"]) > 0
                  ? (extractFloatNumber(budgetItem[role][field]) * 100) /
                      extractFloatNumber(budgetItem["Total"]["value_heure"])
                  : 0
              );
              totalPercentageRole[role] += extractFloatNumber(
                budgetItem[role]["value_heure"]
              );
              const gdValuePercentage = parseFloat(
                role !== "GD"
                  ? extractFloatNumber(budgetItem["GD"]["value_percentage"])
                  : updatedValuePercentage
              );

              const seValuePercentage = parseFloat(
                role !== "SE"
                  ? extractFloatNumber(budgetItem["SE"]["value_percentage"])
                  : updatedValuePercentage
              );

              const geValuePercentage = parseFloat(
                role !== "GE"
                  ? extractFloatNumber(budgetItem["GE"]["value_percentage"])
                  : updatedValuePercentage
              );

              const superviseurValuePercentage = parseFloat(
                role !== "Superviseur"
                  ? extractFloatNumber(
                      budgetItem["Superviseur"]["value_percentage"]
                    )
                  : updatedValuePercentage
              );

              const scanOpValuePercentage = parseFloat(
                role !== "ScanOp"
                  ? extractFloatNumber(budgetItem["ScanOp"]["value_percentage"])
                  : updatedValuePercentage
              );

              return {
                ...budgetItem,
                Total: {
                  ...budgetItem.Total,
                  value_percentage: isNaN(
                    parseFloat(
                      parseFloat(
                        gdValuePercentage +
                          seValuePercentage +
                          geValuePercentage +
                          superviseurValuePercentage +
                          scanOpValuePercentage
                      )
                    )
                  )
                    ? 0
                    : parseFloat(
                        gdValuePercentage +
                          seValuePercentage +
                          geValuePercentage +
                          superviseurValuePercentage +
                          scanOpValuePercentage
                      ),
                },
                [role]: {
                  ...budgetItem[role],
                  value_percentage: isNaN(parseFloat(updatedValuePercentage))
                    ? 0
                    : updatedValuePercentage,
                },
              };
            } else {
              totalPercentageRole[role] += extractFloatNumber(
                budgetItem[role]["value_heure"]
              );
              return {
                ...budgetItem,
              };
            }
          } else {
            totalPercentageRole[role] += extractFloatNumber(
              budgetItem[role]["value_heure"]
            );
            return {
              ...budgetItem,
            };
          }
        } else {
          if (index !== 0) {
            totalPercentageRole[role] += extractFloatNumber(
              budgetItem[role]["value_heure"]
                ? budgetItem[role]["value_heure"]
                : 0
            );
          }
          return budgetItem;
        }
      });
    });
    //  to update the total line
    setUpdatedCategoryRoleList((prevCategoryRoleList) => {
      return prevCategoryRoleList.map((budgetItem, index) => {
        if (index === 0) {
          const rolePercentage = parseFloat(
            extractFloatNumber(budgetItem["Total"]["value_heure"]) > 0
              ? (totalPercentageRole[role] /
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) *
                  100
              : 0
          );
          // const roles = ["GD", "GE", "SE", "Superviseur", "ScanOp"];
          // to do a foreach role
          const gdValuePercentage =
            role !== "GD"
              ? extractFloatNumber(budgetItem["GD"]["value_heure"])
              : (rolePercentage *
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) /
                100;

          const seValuePercentage =
            role !== "SE"
              ? extractFloatNumber(budgetItem["SE"]["value_heure"])
              : (rolePercentage *
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) /
                100;

          const geValuePercentage =
            role !== "GE"
              ? extractFloatNumber(budgetItem["GE"]["value_heure"])
              : (rolePercentage *
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) /
                100;

          const superviseurValuePercentage =
            role !== "Superviseur"
              ? extractFloatNumber(budgetItem["Superviseur"]["value_heure"])
              : (rolePercentage *
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) /
                100;
          const scanOpValuePercentage =
            role !== "ScanOp"
              ? extractFloatNumber(budgetItem["ScanOp"]["value_percentage"])
              : (rolePercentage *
                  extractFloatNumber(budgetItem["Total"]["value_heure"])) /
                100;

          let totalPercentage =
            100 *
            (extractFloatNumber(
              extractFloatNumber(gdValuePercentage) +
                extractFloatNumber(seValuePercentage) +
                extractFloatNumber(geValuePercentage) +
                extractFloatNumber(superviseurValuePercentage) +
                extractFloatNumber(scanOpValuePercentage)
            ) /
              budgetItem["Total"]["value_heure"]);
          let totalValuePercentage = isNaN(
            parseFloat(
              extractFloatNumber(
                (extractFloatNumber(totalPercentageRole[role]) /
                  extractFloatNumber(
                    updatedCategoryRoleList[0]["Total"]["value_heure"]
                  )) *
                  100
              )
            )
          )
            ? 0
            : extractFloatNumber(
                (extractFloatNumber(totalPercentageRole[role]) /
                  extractFloatNumber(
                    updatedCategoryRoleList[0]["Total"]["value_heure"]
                  )) *
                  100
              );

          let totalValueHeure = parseFloat(totalPercentageRole[role]).toFixed(
            2
          );
          if (role === "GD") {
            settotalGD(totalValueHeure);
          }
          if (role === "GE") {
            settotalGE(totalValueHeure);
          }
          if (role === "SE") {
            settotalSE(totalValueHeure);
          }
          if (role === "Superviseur") {
            settotalsupervisor(totalValueHeure);
          }
          if (role === "ScanOp") {
            settotalScanOp(totalValueHeure);
          }
          setUpdatedCollaboratorsList((prevCollaboratorsList) => {
            const updatedCollaborators = { ...prevCollaboratorsList };
            const principalValue =
              extractFloatNumber(totalValueHeure) -
              extractFloatNumber(
                role === "GD"
                  ? totalGDdelegue
                  : role === "GE"
                  ? totalGEdelegue
                  : role === "SE"
                  ? totalSEdelegue
                  : role === "SUPERVISOR" || role === "Superviseur"
                  ? totalSupervisorDelegue
                  : totalScanOpDelegue
              );
            updatedCollaborators[role === "Superviseur" ? "SUPERVISOR" : role] =
              {
                ...updatedCollaborators[
                  role === "Superviseur" ? "SUPERVISOR" : role
                ],
                principal: {
                  ...updatedCollaborators[
                    role === "Superviseur" ? "SUPERVISOR" : role
                  ].principal,
                  value: principalValue,
                },
              };
            return updatedCollaborators;
          });

          return {
            ...budgetItem,
            Total: {
              ...budgetItem.Total,
              value_percentage: Math.ceil(totalPercentage),
            },
            [role]: {
              ...budgetItem[role],
              value_percentage: totalValuePercentage,
              value_heure: totalValueHeure,
            },
          };
        } else {
          return budgetItem;
        }
      });
    });
  };
  const handleSelectedModelChange = (event) => {
    setSelectedModelOption(event);
    const newSelectedModel = event.model;
    setSelectedModel(newSelectedModel);

    const existingScanOp = existingRoles.includes("ScanOp");
    const existingSE = existingRoles.includes("SE");
    newSelectedModel &&
      newSelectedModel.forEach((item, index) => {
        let addedRolePercentage = 0;
        if (index !== 14 && index !== 15) {
          existingRoles.forEach((role) => {
            if (!item[role].checked) {
              if (!existingScanOp) {
                addedRolePercentage = !existingScanOp
                  ? extractFloatNumber(item["ScanOp"]["value_percentage"]) /
                    existingRoles.length
                  : 0;
              }
              handleInputChange(
                extractFloatNumber(item[role].value_percentage) +
                  addedRolePercentage +
                  (!existingSE && role === "GD"
                    ? extractFloatNumber(item["SE"]["value_percentage"])
                    : 0),
                item.label,
                role,
                index + 1,
                "value_percentage",
                "%"
              );
            } else {
              const minValueTest =
                extractNumber(item[role].value_heure) >
                extractNumber(
                  (extractNumber(
                    updatedCategoryRoleList[index + 1]["Total"]["value_heure"] // mettre test ila kan le pourcentage ki 7e9e9 liya dik la valeur
                  ) *
                    (extractFloatNumber(item[role].value_percentage) +
                      addedRolePercentage +
                      (!existingSE && role === "GD"
                        ? extractFloatNumber(item["SE"]["value_percentage"])
                        : 0))) /
                    100
                );
              handleInputChange(
                minValueTest
                  ? extractNumber(item[role].value_heure)
                  : extractFloatNumber(item[role].value_percentage) +
                      addedRolePercentage +
                      (!existingSE && role === "GD"
                        ? extractFloatNumber(item["SE"]["value_percentage"])
                        : 0),
                item[role].label,
                role,
                index + 1,
                minValueTest ? "value_heure" : "value_percentage",
                minValueTest ? "" : ""
              );
              handleInputChange(
                item[role].checked,
                item[role].label,
                role,
                index + 1,
                "checked",
                ""
              );
            }
          });
        }
      });
  };
  const handleColumns = (column, item, index1) => {
    return (
      <>
        {column.label !== "label" &&
          column.label !== "totalPercentage" &&
          item.label !== "header" &&
          item.label !== "footer" && (
            <div style={{ margin: "auto" }}>
              <div
                className={classNames(
                  styles.inputGroup,
                  styles.label,
                  (item.label === "Total" ||
                    item[column.label]["checked"] ||
                    !(existingRoles && existingRoles.includes(column.label))) &&
                    styles.disabled,
                  column.label === "Total" &&
                    extractNumber(item[column.label]["value_percentage"]) ===
                      100 &&
                    styles.sucess_total,
                  column.label === "Total" &&
                    extractNumber(item[column.label]["value_percentage"]) !==
                      100 &&
                    styles.insucess_total
                )}
                style={{
                  border:
                    extractNumber(item["Total"]["value_percentage"]) !== 100
                      ? "1px solid #de4848"
                      : "",
                }}
              >
                <input
                  type="number"
                  className={classNames(styles.input)}
                  value={item[column.label]["value_percentage"]}
                  required={true}
                  disabled={
                    preferences.allowAdjustDistributionOfTasksByRole === "0"
                  }
                  onChange={(event) =>
                    existingRoles.includes(column.label) &&
                    handleInputChange(
                      event.target.value,
                      item.label,
                      column.label,
                      index1,
                      "value_percentage",
                      "%"
                    )
                  }
                />
                %
              </div>
              <div style={{ display: "flex" }}>
                <div
                  className={classNames(
                    styles.inputGroup,
                    styles.label,
                    (!item[column.label]["checked"] ||
                      item.label === "Total" ||
                      column.label === "Total") &&
                      styles.disabled,
                    item.label === "Total" && styles.title_disabled
                  )}
                >
                  <input
                    type="number"
                    className={styles.input}
                    value={item[column.label]["value_heure"]}
                    required={true}
                    disabled={!item[column.label]["checked"]}
                    onChange={(event) =>
                      existingRoles.includes(column.label) &&
                      handleInputChange(
                        event.target.value,
                        item.label,
                        column.label,
                        index1,
                        "value_heure",
                        "h"
                      )
                    }
                  />
                  h
                </div>
                {item.label !== "Total" &&
                  column.label !== "Total" &&
                  existingRoles &&
                  existingRoles.includes(column.label) &&
                  preferences.allowAdjustDistributionOfTasksByRole === "1" && (
                    <input
                      type="checkbox"
                      checked={item[column.label]["checked"]}
                      onChange={() =>
                        existingRoles.includes(column.label) &&
                        handleInputChange(
                          !item[column.label]["checked"],
                          item.label,
                          column.label,
                          index1,
                          "checked",
                          ""
                        )
                      }
                    />
                  )}
              </div>
            </div>
          )}
      </>
    );
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={styles.title}>GESTION DES TACHES</div>
        <div style={{ marginLeft: "auto" }}>
          {preferences && preferences.allowSelectAppliedModel === "1" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Modèle appliqué:
              <div style={{ width: 170, marginLeft: 11 }}>
                <Select
                  isMulti={false}
                  // styles={SELECT_STYLES}
                  value={selectedModelOption}
                  className={styles.filter_frame_field_placeholder}
                  options={filteredOptions}
                  // isSearchable={true}
                  // name="name"
                  // value={"standard"}
                  // onBlur={handleBlur}
                  onChange={(event) => handleSelectedModelChange(event)}
                  getOptionLabel={(option) => option.labelModel}
                />
              </div>
              {/* 
            <Select
              isMulti={false}
              isSearchable={false}
              isClearable={false}
              closeMenuOnSelect
              placeholder={"collaborator"}
              // value={}
              options={filteredOptions}
              onChange={(event) => handleSelectedModelChange(event)}
              // isDisabled={!delegated}
              className={styles.filter_frame_field_placeholder}
              getOptionLabel={(option) => option.labelModel}
            /> */}
            </div>
          )}
        </div>
      </div>
      <div className={styles.volumetrie}>
        <div className={styles.title}>
          <div className={styles.group_title}>
            <span>{title}</span>

            <button
              className={styles.reduce_table_action}
              onClick={() => setIsTableReduced(!isTableReduced)}
              style={{ width: isTableReduced && "120px ! important" }}
            >
              {isTableReduced ? (
                <div className={styles.reduce_action}>
                  Afficher plus
                  <ChevronDown />
                </div>
              ) : (
                <div
                  className={styles.reduce_action}
                  style={{ width: "120px ! important" }}
                >
                  Réduire
                  <ChevronUp />
                </div>
              )}
            </button>
          </div>
          <div className={styles.description}>{descrption}</div>
        </div>
        <div>
          {updatedCategoryRoleList && (
            <TableBorderedColumns
              headerHeight={headerHeight}
              height={height}
              bordrerdcolumns={bordrerdcolumns}
              handleColumns={handleColumns}
              tableItems={reducedTable}
              isGestionTask={true}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TableN;
