import React from "react";
import DynamicTable from "./DynamicTable";


const DynamicTablePage = () => {
  return (
    <div className="page-content dynamic_page">
      <DynamicTable />
    </div>
  );
}
export default DynamicTablePage;
