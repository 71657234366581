import React from "react";

export const PullIcon = ({ fill = "#EB5757" }) => (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0001 9.33464C10.3557 9.33464 9.83341 9.85697 9.83341 10.5013C9.83341 11.1456 10.3557 11.668 11.0001 11.668C11.6444 11.668 12.1667 11.1456 12.1667 10.5013C12.1667 9.85697 11.6444 9.33464 11.0001 9.33464ZM8.66675 10.5013C8.66675 9.21264 9.71142 8.16797 11.0001 8.16797C12.2887 8.16797 13.3334 9.21264 13.3334 10.5013C13.3334 11.79 12.2887 12.8346 11.0001 12.8346C9.71142 12.8346 8.66675 11.79 8.66675 10.5013Z"
      fill="#6D7F92"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00008 2.33464C3.35575 2.33464 2.83341 2.85697 2.83341 3.5013C2.83341 4.14563 3.35575 4.66797 4.00008 4.66797C4.64441 4.66797 5.16675 4.14563 5.16675 3.5013C5.16675 2.85697 4.64441 2.33464 4.00008 2.33464ZM1.66675 3.5013C1.66675 2.21264 2.71142 1.16797 4.00008 1.16797C5.28875 1.16797 6.33341 2.21264 6.33341 3.5013C6.33341 4.78997 5.28875 5.83464 4.00008 5.83464C2.71142 5.83464 1.66675 4.78997 1.66675 3.5013Z"
      fill="#6D7F92"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.5 3.5013C7.5 3.17914 7.76117 2.91797 8.08333 2.91797H9.83333C10.2975 2.91797 10.7426 3.10234 11.0708 3.43053C11.399 3.75872 11.5833 4.20384 11.5833 4.66797V8.7513C11.5833 9.07347 11.3222 9.33464 11 9.33464C10.6778 9.33464 10.4167 9.07347 10.4167 8.7513V4.66797C10.4167 4.51326 10.3552 4.36489 10.2458 4.25549C10.1364 4.14609 9.98804 4.08464 9.83333 4.08464H8.08333C7.76117 4.08464 7.5 3.82347 7.5 3.5013Z"
      fill="#6D7F92"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.00008 4.66797C4.32225 4.66797 4.58341 4.92914 4.58341 5.2513V12.2513C4.58341 12.5735 4.32225 12.8346 4.00008 12.8346C3.67792 12.8346 3.41675 12.5735 3.41675 12.2513V5.2513C3.41675 4.92914 3.67792 4.66797 4.00008 4.66797Z"
      fill="#6D7F92"
    />
  </svg>
);
