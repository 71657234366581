import React from "react";

export const FrequanceIcon = ({ size = 14, fill = "#29394D" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.25065 1.16797C5.50174 1.16797 5.72465 1.32864 5.80405 1.56684L8.75065 10.4066L9.94725 6.81684C10.0267 6.57864 10.2496 6.41797 10.5007 6.41797H12.834C13.1562 6.41797 13.4173 6.67914 13.4173 7.0013C13.4173 7.32347 13.1562 7.58464 12.834 7.58464H10.9211L9.30405 12.4358C9.22465 12.674 9.00174 12.8346 8.75065 12.8346C8.49957 12.8346 8.27665 12.674 8.19725 12.4358L5.25065 3.59596L4.05405 7.18577C3.97465 7.42397 3.75174 7.58464 3.50065 7.58464H1.16732C0.845152 7.58464 0.583984 7.32347 0.583984 7.0013C0.583984 6.67914 0.845152 6.41797 1.16732 6.41797H3.08021L4.69725 1.56684C4.77665 1.32864 4.99957 1.16797 5.25065 1.16797Z"
      fill={fill}
    />
  </svg>
);
