import React from "react";

export const MaximizeIcon = ({ size = 13, fill = "#6D7F92" }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#B2BCC6"/>
    <path d="M14 6H18V10" stroke="#29394D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 18H6V14" stroke="#29394D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.0007 6L13.334 10.6667" stroke="#29394D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 18.0007L10.6667 13.334" stroke="#29394D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
