import React from "react";
import { Link } from "react-router-dom";
import _ from "../../../i18n";

import { getUserNameForAvatar, generateFullName } from "../../../utils";

import { CheckCircleIcon, FilterIcon } from "../../common/Icons";

import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Tooltip } from "antd";

import "./Item.local.scss";

const Item = ({
  lng,
  item,
  index,
  responsables,
  year,
  setCollapsedItem,
  collapsedItem,
}) => {
  const renderAvatar = (avatarUrl, firstName, lastName) => {
    if (!avatarUrl && !firstName && !lastName) {
      return (
        <div className="avatar_not_exist">
          <p style={{ margin: "auto" }}>-</p>
        </div>
      );
    }
    let avatarDiv;
    if (!avatarUrl) {
      avatarDiv = (
        <div className="avatar_not_exist">
          <span>{getUserNameForAvatar(firstName, lastName)}</span>
        </div>
      );
    } else {
      avatarDiv = (
        <div className="avatar_exist">
          <img src={avatarUrl} alt={generateFullName(firstName, lastName)} />
        </div>
      );
    }

    return avatarDiv;
  };

  return (
    <div
      key={index}
      className={`item ${collapsedItem === item.id ? "item_opened" : ""}`}
    >
      <div className="item_infos">
        <div className="item_infos_header">{`${_("portfolio")} -${item.name.split("-")[1]}`}</div>
        <div className="item_infos_sub_header">
          {_("folderCount")} : {item.clients.length}
        </div>
      </div>
      <div
        className={`item_progress ${
          item.hours.real > item.hours.budget ? "bg-danger" : "bg-success"
        }`}
      >
        <div className="item_progress_info">
          <div>{_("hourBudget")}</div>
          <div>{`${parseFloat(
            parseFloat(item.hours.budget).toFixed(0)
          )}h`}</div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className="item_progress_container">
            <div
              className="item_progress_bar"
              style={{
                width:
                  parseInt(item.hours.real) > parseInt(item.hours.budget)
                    ? "100%"
                    : parseInt(item.hours.budget) > 0 &&
                      parseInt(item.hours.real) > 0
                    ? `${
                        (parseInt(item.hours.real) * 100) /
                        parseInt(item.hours.budget)
                      }%`
                    : 0,

                background:
                  parseFloat(item.hours.real) > parseFloat(item.hours.budget)
                    ? "#EB5757"
                    : "#06D9B1",
              }}
            />
            <div
              className="item_progress_index"
              style={{
                width:
                  parseInt(item.hours.real) > parseInt(item.hours.budget)
                    ? "200%"
                    : parseInt(item.hours.budget) > 0 &&
                      parseInt(item.hours.real) > 0
                    ? `${
                        (parseInt(item.hours.real) * 200) /
                        parseInt(item.hours.budget)
                      }%`
                    : 0,
                color:
                  parseFloat(item.hours.real) > parseFloat(item.hours.budget)
                    ? "#DE4848"
                    : "#02AF8E",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.hours.real) > 0
                  ? `${parseFloat(parseFloat(item.hours.real).toFixed(0))}h`
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`item_progress ${
          parseFloat(item.hours.real) > 0 &&
          parseFloat(item.economic.real) / parseFloat(item.hours.real) > 80
            ? "bg-success"
            : "bg-warning"
        }`}
      >
        <div className="item_progress_info">
          <div>{_("economicBudget")}</div>
          <div>
            {parseInt(item.economic.budget) > 0
              ? `${parseFloat(parseFloat(item.economic.budget).toFixed(0))}€`
              : 0}
          </div>
        </div>
        <div
          style={{
            height: "38px",
            display: "flex",
            marginTop: 5,
            backgroundColor: "transparent",
          }}
        >
          <div className="item_progress_container">
            <div
              className="item_progress_bar"
              style={{
                width:
                  parseInt(item.economic.real) > parseInt(item.economic.budget)
                    ? "100%"
                    : parseInt(item.economic.budget) > 0 &&
                      parseInt(item.economic.real) > 0
                    ? `${
                        (parseInt(item.economic.real) * 100) /
                        parseInt(item.economic.budget)
                      }%`
                    : 0,
                background:
                  parseFloat(item.hours.real) > 0 &&
                  parseFloat(item.economic.real) / parseFloat(item.hours.real) >
                    80
                    ? "#06D9B1"
                    : "#EA8124",
              }}
            />
            <div
              className="item_progress_index"
              style={{
                width:
                  parseFloat(item.economic.budget) > 0 &&
                  parseFloat(item.economic.real) > 0
                    ? parseFloat(item.economic.real) >
                      parseFloat(item.economic.budget)
                      ? `inherit`
                      : `${
                          (parseFloat(item.economic.real) * 200) /
                          parseFloat(item.economic.budget)
                        }%`
                    : "fit-content",
                color:
                  parseFloat(item.hours.real) > 0 &&
                  parseFloat(item.economic.real) / parseFloat(item.hours.real) >
                    80
                    ? "#02AF8E"
                    : "#EA8124",
                alignItems:
                  parseFloat(item.economic.real) >
                  parseFloat(item.economic.budget)
                    ? "end"
                    : "center",
              }}
            >
              <TiArrowSortedDown />{" "}
              <div>
                {parseFloat(item.economic.real) > 0
                  ? `${parseFloat(parseFloat(item.economic.real).toFixed(0))}€`
                  : 0}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="item_tags">
        <div className="tag">
          <CheckCircleIcon fill="#6D7F92" />{" "}
          <span>
            {parseInt(item.depart.count)}
          </span>{" "}
          {_("depart")}
        </div>
        {item.depart.count ? <div className="set_tags">
          <div className="tag">
            <CheckCircleIcon fill="#6D7F92" size={10} />{" "}
            <span>
              {parseInt(item.depart.budget)}h
            </span>
          </div>
          <div className="tag">
            <CheckCircleIcon fill="#6D7F92"  size={10} />{" "}
            <span>
              {parseInt(item.depart.economic)}€
            </span>
          </div>
        </div> : ""}
      </div>
      <div className="item_tags">
        <div className="tag">
          <CheckCircleIcon fill="#6D7F92" />{" "}
          <span>
            {item.illigibleHourusCount > 0 ? parseInt((item.hourusCount * 100) / item.illigibleHourusCount) : 0}%
          </span>{" "}
          {_("horusFolder")}
        </div>
      </div>
      <div className="item_tags">
        <div className="tag">
          <CheckCircleIcon fill="#6D7F92" />{" "}
          <span>
            {parseInt((item.offShoringCount * 100) / item.clients.length)}%
          </span>{" "}
          {_("offshoring")}
        </div>
      </div>

      <div className="item_avatars">
        {responsables.map((item, index) => (
          <Tooltip
            key={`role-${index}-${item.roles.join("-")}`}
            placement="top"
            title={_(item.roles.join("-"))}
          >
            <div className="avatar" key={index}>
              {renderAvatar(item.avatar, item.name)}
            </div>
          </Tooltip>
        ))}
      </div>

      <div className="item_actions">
        <Tooltip
          key="tool-1"
          placement="top"
          title={_("Visualiser le detail")}
          mouseLeaveDelay={0}
        >
          <div
            className="action"
            onClick={() =>
              setCollapsedItem(collapsedItem !== item.id ? item.id : 0)
            }
          >
            {collapsedItem !== item.id ? (
              <TiArrowSortedDown />
            ) : (
              <TiArrowSortedUp />
            )}
          </div>
        </Tooltip>
        <Tooltip
          key="tool-1"
          placement="top"
          title={_("Visualiser les dossiers du porte-feuille")}
          mouseLeaveDelay={0}
        >
          <Link to={`/${lng}/budget/${item.id}/${year}`}>
            <div className="action">
              <FilterIcon fill="#18a0fb" />
            </div>
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};

export default Item;
