import React from "react";

export const FolderIcon = ({ size = 14, fill = "#29394D" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 6C6.10217 6 5.72064 6.15804 5.43934 6.43934C5.15804 6.72064 5 7.10217 5 7.5V28.5C5 28.8978 5.15803 29.2794 5.43934 29.5607C5.72064 29.842 6.10218 30 6.5 30H30.5C30.8978 30 31.2794 29.842 31.5607 29.5607C31.842 29.2794 32 28.8978 32 28.5V12C32 11.6022 31.842 11.2206 31.5607 10.9393C31.2794 10.658 30.8978 10.5 30.5 10.5H17C16.4985 10.5 16.0301 10.2493 15.7519 9.83205L13.1972 6H6.5ZM3.31802 4.31802C4.16193 3.47411 5.30653 3 6.5 3H14C14.5015 3 14.9699 3.25065 15.2481 3.66795L17.8028 7.5H30.5C31.6935 7.5 32.8381 7.97411 33.682 8.81802C34.5259 9.66193 35 10.8065 35 12V28.5C35 29.6935 34.5259 30.8381 33.682 31.682C32.8381 32.5259 31.6935 33 30.5 33H6.5C5.30652 33 4.16193 32.5259 3.31802 31.682C2.47411 30.8381 2 29.6935 2 28.5V7.5C2 6.30653 2.47411 5.16193 3.31802 4.31802Z"
      fill={fill}
    />
  </svg>
);
