import { useState } from "react";
import { TiArrowSortedDown, TiArrowUnsorted } from "react-icons/ti";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, Sector } from "recharts";
import {
  getLastHourCollaboratorsContribution,
  getPartnerStatistics,
} from "../../../api";
import _ from "../../../i18n";
import { formatNumber } from "../../../utils";
import { AnimatedBar } from "../components/AnimatedBar";
import styles from "./Dashboard.module.scss";

export default function Stats({
  token,
  organization,
  collaborator,
  partner,
  year,
}) {
  const [timeRegistration, setTimeRegistration] = useState([]);
  const [stats, setStats] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [arrivalActive, setArrivalActive] = useState(false);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  useQuery(
    ["lastHourCollaboratorsProductionQuery", token, organization, partner],
    async () => {
      if (token && partner && organization) {
        try {
          const response = await getLastHourCollaboratorsContribution(
            token,
            organization.id,
            partner?.id,
            year
          );
          setTimeRegistration(response.data.data);
        } catch (e) {
          setTimeRegistration(null);
          return e;
        }
      }
    }
  );

  let partnerStats = useQuery(
    ["getPartnerStatistics", token, organization, partner],
    async () => {
      if (token && partner && organization) {
        try {
          const response = await getPartnerStatistics(
            token,
            organization.id,
            partner?.id,
            year
          );
          const data = response.data.data;
          setStats(data);
        } catch (e) {
          setStats(null);
          return e;
        }
      }
    }
  );

  const hours = stats.hours;
  const economic = stats.economic;
  const client = stats?.client;
  const departure = client?.departures;
  const arrival = client?.arrivals;

  return (
    <div className={styles.main_stats}>
      <AnimatedBar data={timeRegistration} />
      {/* Other stats */}
      {partnerStats.isFetching ? (
        <div style={{ margin: "10px auto" }}>Loading...</div>
      ) : (
        partnerStats.isFetched && (
          <div className={styles.main_stats_stats}>
            <div className={styles.main_stats_stats_pie_chart}>
              {stats && (
                <CustomPieChart
                  data={stats}
                  index={activeIndex}
                  action={onPieEnter}
                />
              )}
              <label>
                <strong>{`${stats?.client?.total}`}</strong>
                {_("clientFolders")}
              </label>
            </div>
            <CustomCard
              budget={hours.total}
              real={hours.real}
              unit="H"
              title="hourBudget"
              color={hours.real > hours.total ? "red" : "#06D9B1"}
            />
            <CustomCard
              budget={economic.total}
              real={economic.real}
              unit="€"
              title="economicBudget"
              color={economic.real > economic.total ? "red" : "#EA8124"}
            />
            <div className={styles.main_stats_stats_card}>
              <div className={styles.title}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setArrivalActive(!arrivalActive)}
                >
                  {arrivalActive
                    ? _(`${arrival?.title}`)
                    : _(`${departure?.title}`)}
                  <TiArrowUnsorted />
                </div>
                <div>
                  {arrivalActive
                    ? parseInt(arrival?.total) < 10
                      ? `0${arrival?.total} `
                      : arrival?.total
                    : parseInt(departure?.total) < 10
                    ? `0${departure?.total} `
                    : departure?.total}
                </div>
              </div>
              <div className={styles.departure_info}>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.hours)} h`
                    : `${formatNumber(departure?.hours)} h`}
                </div>
                <div>
                  {arrivalActive
                    ? `${formatNumber(arrival?.economic)} €`
                    : `${formatNumber(departure?.economic)} €`}
                </div>
              </div>
            </div>
            <div className={styles.main_stats_stats_card}>
              <div className={styles.offshoring_stats}>
                <div>
                  {_("Offshoring")}{" "}
                  <span>{`${client.offshoring.toFixed(1)}%`}</span>
                </div>
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client.offshoring}%` }}
                  />
                </div>
              </div>

              <div className={styles.offshoring_stats}>
                <div>
                  {_("horusFolders")}{" "}
                  <span>{`${client.horus.toFixed(1)}%`}</span>
                </div>
                <div className={styles.bar_container}>
                  <div
                    className={styles.bar}
                    style={{ width: `${client.horus}%` }}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

function CustomPieChart({ data, index, action }) {
  const colors = ["#29394D", "#6D7F92", "#B2BCC6", "#18a0fb"];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 5) * cos;
    const my = cy + (outerRadius + 25) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1);
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x="50%" y="90%" dy={2} textAnchor="middle" fill={fill}>
          {_(`${payload.name}`)}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 3}
          outerRadius={outerRadius + 4}
          fill={fill}
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1)}
          y={ey}
          textAnchor={textAnchor}
          fill={fill}
        >{`${value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1)}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          style={{ fontSize: "12px" }}
          fill={fill}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const pieData = data?.client?.children || [];

  return (
    <PieChart width={150} height={150}>
      <Pie
        activeIndex={index}
        activeShape={renderActiveShape}
        data={pieData}
        cx="50%"
        cy="50%"
        innerRadius={30}
        outerRadius={40}
        dataKey="value"
        nameKey="name"
        onMouseEnter={action}
        fill="#29394D"
        minAngle={5}
      >
        {pieData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
    </PieChart>
  );
}

function CustomCard({ title, budget, real, unit, color }) {
  return (
    <div className={styles.main_stats_stats_card}>
      <div className={styles.title}>
        <div style={{ width: "20%" }}>{_(`${title}`)}</div>
        <div>
          {`${formatNumber(budget)} ${unit} `}
        </div>
      </div>
      <div>
        <div className={styles.bar_container}>
          <div
            className={styles.bar}
            style={{
              width:
                real > budget
                  ? "100%"
                  : budget > 0
                  ? `${(real * 100) / budget}%`
                  : 0,
              background: color,
            }}
          />
          <div
            className={styles.bar_container_index}
            style={{
              width:
                budget > 0
                  ? real > budget
                    ? "100%"
                    : `${(real * 200) / budget}%`
                  : "fit-content",
              color: color,
              alignItems: real > budget ? "end" : "center",
            }}
          >
            <TiArrowSortedDown color={color} />
            <div>
            {`${formatNumber(real)} ${unit} `}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
