import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getModelesReparion, saveModel as saveModelApi } from "../api";

export const fetchModelesRepartition = createAsyncThunk(
  "modeles/fetchModelesRepartition",

  async ({ year, clientTypeId }, { dispatch, getState }) => {
    const { token, navCommunity } = getState().auth;
    let organizationId = navCommunity.id;
    const response = await getModelesReparion({
      token,
      organizationId,
      clientTypeId,
      year,
    });
    return response;
  }
);
export const saveModel = createAsyncThunk(
  "modeles/saveModel",
  async (
    id,
    typeId,
    label,
    modele,
    { dispatch, getState, rejectWithValue }
  ) => {
    const organizationId = 4;
    const { token } = getState().auth;
    try {
      const response = await saveModelApi(
        id,
        typeId,
        label,
        modele,
        organizationId,
        token
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
  isSaving: false,
  modeleIsSaving: false,
};

export const modelesSlice = createSlice({
  name: "modeles",
  initialState,
  extraReducers: {
    [fetchModelesRepartition.pending]: (state, action) => {
      state.fetching = true;
    },
    [fetchModelesRepartition.fulfilled]: (state, { payload }) => {
      state.fetching = false;
      state.data = payload.data.data;
    },
    [fetchModelesRepartition.rejected]: (state, { payload }) => {
      state.error = payload;
      state.fetching = false;
      state.data = [];
    },
    [saveModel.pending]: (state, action) => {
      state.modeleIsSaving = true;
    },
    [saveModel.fulfilled]: (state, { payload }) => {
      state.modeleIsSaving = false;
      state.error = null;
    },
    [saveModel.rejected]: (state, { payload }) => {
      state.error = payload;
      state.modeleIsSaving = false;
    },
  },
});

export default modelesSlice.reducer;
