import React from "react";

export const BellIcon = ({ size = 14, fill = "#29394D", className = "" }) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.11204 1.77801C4.87782 1.01224 5.91643 0.582031 6.99939 0.582031C8.08236 0.582031 9.12097 1.01224 9.88675 1.77801C10.6525 2.54379 11.0827 3.5824 11.0827 4.66536C11.0827 6.62108 11.501 7.83557 11.8865 8.54228C12.08 8.89712 12.2685 9.1299 12.3994 9.26809C12.465 9.33734 12.5166 9.38326 12.5476 9.40913C12.5632 9.42207 12.5736 9.43001 12.5781 9.43335C12.5788 9.43388 12.5793 9.43429 12.5797 9.43458C12.7889 9.57823 12.8814 9.84099 12.8077 10.0844C12.7332 10.3304 12.5065 10.4987 12.2494 10.4987H1.74939C1.49234 10.4987 1.26558 10.3304 1.19109 10.0844C1.11739 9.84099 1.20993 9.57823 1.41905 9.43459C1.41946 9.43429 1.42001 9.43388 1.42071 9.43335C1.4252 9.43001 1.43562 9.42207 1.45115 9.40913C1.48219 9.38326 1.53375 9.33734 1.59936 9.26809C1.73028 9.1299 1.91874 8.89712 2.11229 8.54228C2.49777 7.83557 2.91606 6.62108 2.91606 4.66536C2.91606 3.5824 3.34627 2.54379 4.11204 1.77801ZM1.42494 9.43059C1.42499 9.43056 1.42503 9.43053 1.42508 9.4305C1.42508 9.4305 1.42508 9.4305 1.42507 9.4305L1.42494 9.43059ZM3.00317 9.33203H10.9956C10.9512 9.2593 10.9067 9.18233 10.8623 9.10095C10.3728 8.2035 9.91606 6.79298 9.91606 4.66536C9.91606 3.89182 9.60877 3.14995 9.06179 2.60297C8.51481 2.05599 7.77294 1.7487 6.99939 1.7487C6.22585 1.7487 5.48398 2.05599 4.937 2.60297C4.39002 3.14995 4.08273 3.89182 4.08273 4.66536C4.08273 6.79298 3.62602 8.2035 3.1365 9.10095C3.09211 9.18233 3.04755 9.2593 3.00317 9.33203Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.69893 11.7468C5.97761 11.5852 6.33456 11.68 6.49622 11.9587C6.54749 12.0471 6.6211 12.1205 6.70965 12.1715C6.7982 12.2225 6.8986 12.2493 7.0008 12.2493C7.10299 12.2493 7.20339 12.2225 7.29195 12.1715C7.3805 12.1205 7.4541 12.0471 7.50538 11.9587C7.66704 11.68 8.02399 11.5852 8.30267 11.7468C8.58134 11.9085 8.6762 12.2654 8.51455 12.5441C8.36071 12.8093 8.13991 13.0294 7.87425 13.1824C7.60858 13.3355 7.30738 13.416 7.0008 13.416C6.69422 13.416 6.39302 13.3355 6.12735 13.1824C5.86169 13.0294 5.64088 12.8093 5.48705 12.5441C5.3254 12.2654 5.42026 11.9085 5.69893 11.7468Z"
      fill={fill}
    />
  </svg>
);
