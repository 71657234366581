import axios from "axios";
import { TTP_API_URL } from "../config";

export const getModelesReparion = ({
  token,
  organizationId,
  year,
  clientTypeId,
}) => {
  const requestUrl = `${TTP_API_URL}/team/modeles-repartition`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      clientTypeId,
      year,
    },
  });
};

export const saveModel = (id, typeId, label, modele, organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/save-modele-repartition`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("typeId", typeId);
  formData.append("id", id);
  formData.append("label", label);
  formData.append("organizationId", organizationId);
  formData.append("model", JSON.stringify(modele));

  return axios.post(requestUrl, formData);
};
