import React from 'react'

export const ActivitiesNotificationsIcon = () => {
    return (
        <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.50065 0.667969C7.85934 0.667969 8.17779 0.897494 8.29122 1.23778L12.5007 13.8661L14.2101 8.73778C14.3235 8.39749 14.642 8.16797 15.0007 8.16797H18.334C18.7942 8.16797 19.1673 8.54106 19.1673 9.0013C19.1673 9.46154 18.7942 9.83463 18.334 9.83463H15.6013L13.2912 16.7648C13.1778 17.1051 12.8593 17.3346 12.5007 17.3346C12.142 17.3346 11.8235 17.1051 11.7101 16.7648L7.50065 4.13653L5.79122 9.26482C5.67779 9.60511 5.35934 9.83463 5.00065 9.83463H1.66732C1.20708 9.83463 0.833984 9.46154 0.833984 9.0013C0.833984 8.54106 1.20708 8.16797 1.66732 8.16797H4.40002L6.71008 1.23778C6.82351 0.897494 7.14196 0.667969 7.50065 0.667969Z" fill="#C7E8FE"/>
        </svg>
    );
}
