import React from "react";
import _ from "../../../../../i18n";
import "./FileReaderIframe.local.scss";
import { IconClose } from "../../../../common/Icons";

const FileReaderIframe = ({
  hideIframe,
  srcIframe,
  isNotFound,
  loadingIframe,
  onCloseIframe,
}) => {
  if (hideIframe) {
    return null;
  }
  return (
    <div className="fadePage file-reader-iframe">
      <iframe
        id={"annexe-iframe"}
        name={"annexe-iframe"}
        title={"annexe attachment"}
        src={srcIframe || ""}
      />
      <div className="iframe_close" onClick={onCloseIframe}>
        <IconClose size="14" fill="#6D7F92" />
      </div>
      {loadingIframe && (
        <div className="iframe_loading">
          <img src={"/img/Ripple-1s-150px.svg"} alt="" />
        </div>
      )}
      {isNotFound && (
        <div className="no_data" style={{ height: 38 }}>
          <div className="title">{_("Oops")}</div>
          <div className="subTitle">{_("noFileFound")}</div>
        </div>
      )}
    </div>
  );
};

export default FileReaderIframe;
