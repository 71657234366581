import axios from "axios";

import { TTP_API_URL } from "../config";

export const getVolumetryCoefficients = (collaboratorId, organizationId, category, token) => {
  const requestUrl = `${TTP_API_URL}/team/volumetry/coefficients`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      collaborator: collaboratorId,
      category: category,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const saveVolumetryCoefficients = ({
  token,
  collaboratorId,
  organization,
  category,
  software,
}) => {
  const requestUrl = `${TTP_API_URL}/team/volumetry/coefficient`;

  let formData = new FormData();
  let coefficients = { ...software };
  software.id && formData.append("id", software.id);
  formData.append("organization", organization);
  formData.append("collaborator", collaboratorId);
  formData.append("category", category);
  formData.append("access_token", token);
  formData.append("label", software.label);
  delete coefficients.id;
  delete coefficients.label;
  delete coefficients.coefficient;
  formData.append("coefficients", JSON.stringify(coefficients));

  return axios.post(requestUrl, formData);
};

export const getEncodingDocTypes = (organizationId, category, token) => {
  const requestUrl = `${TTP_API_URL}/team/volumetry/doc-types`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      category: category,
      organization_id: organizationId,
      organization: organizationId,
    },
  });
};

export const saveEncodingDocTypes = ({ token, organization, docType }) => {
  const requestUrl = `${TTP_API_URL}/team/volumetry/doc-types`;

  let formData = new FormData();
  docType.id && formData.append("id", docType.id);
  formData.append("organization", organization);
  formData.append("access_token", token);
  formData.append("doc", JSON.stringify(docType));

  return axios.post(requestUrl, formData);
};
