import React, { useState, Fragment } from "react";
import { useQuery } from "react-query";
import _ from "../../../../i18n";
import { CustumedMatrix } from "tamtam-components";
import { RadioButton } from "primereact/radiobutton";

import {
  saveVolumetryCoefficients,
  getVolumetryCoefficients,
  saveEncodingDocTypes,
  getEncodingDocTypes,
} from "../../../../api";

import {
  IconClose,
  IconPencil,
  CheckIcon,
  CheckCircleIcon,
} from "../../../common/Icons";
import "./EncodingProgressionContent.local.scss";
const widthPercentageVar = 10;
const VolumetryConfigContent = ({ onClose, token, collaborator, organization, category }) => {
  //States
  const [formFocus, setFormFocus] = useState(false);
  const [editedSoftware, setEditedSoftware] = useState(null);
  const [editedDocType, setEditedDocType] = useState(null);
  const [coefficients, setCoefficients] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "purchases",
      label: _("purchases"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "sales",
      label: _("sales"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "financial_transactions_coda",
      label: _("coda"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "financial_transactions_noncoda",
      label: _("noncoda"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "caisse",
      label: _("caisse"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "remuneration",
      label: _("remuneration"),
      isEditable: true,
      widthPercentage: widthPercentageVar,
    },
    {
      id: "actions",
      label: "",
      isEditable: true,
      widthPercentage: 10,
    },
  ]);

  let volumetryCoefficientsQuery = useQuery(
    ["collaboratorsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getVolumetryCoefficients(
            collaborator.id,
            organization,
            category.id,
            token
          );
          const coeffResponse = response.data.data;
          setCoefficients(coeffResponse);
        } catch (e) {
          setCoefficients([]);
          return null;
        }
      }
    }
  );

  let docTypesQuery = useQuery(["docTypesQuery", organization], async () => {
    if (token) {
      try {
        const response = await getEncodingDocTypes(
          organization,
          category.id,
          token
        );
        const typeResponse = response.data.data;
        setDocTypes(typeResponse);
      } catch (e) {
        setDocTypes([]);
        return null;
      }
    }
  });

  const handleSaveSoftware = async (software) => {
    if (
      software.label === "" ||
      (software.purchases === 0 &&
        software.sales === 0 &&
        software.financial_transactions_coda === 0 &&
        software.financial_transactions_noncoda === 0 &&
        software.caisse === 0 &&
        software.remuneration === 0)
    ) {
      return;
    }
    try {
      const response = await saveVolumetryCoefficients({
        token,
        collaboratorId: collaborator.id,
        organization,
        category: category.id,
        software,
      });
      let coefficientResponse = response.data.data;
      let newCoefficients = [];
      newCoefficients = [...coefficients].map((coefficient) => {
        if (coefficient.id === software.id) {
          return {
            ...coefficientResponse,
            ...JSON.parse(coefficientResponse.coefficient),
          };
        } else {
          return coefficient;
        }
      });
      setEditedSoftware(null);
      setFormFocus(false);
      setCoefficients(newCoefficients);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleSaveDocType = async (docType) => {
    try {
      const response = await saveEncodingDocTypes({
        token,
        organization,
        docType,
      });
      let docTypeResponse = response.data.data;
      let newDocTypes = [];
      newDocTypes = [...docTypes].map((element) => {
        if (element.id === docType.id) {
          return {
            ...docTypeResponse,
          };
        } else {
          return element;
        }
      });
      setEditedDocType(null);
      setDocTypes(newDocTypes);
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return null;
    }
  };

  const handleCoefficientsColumns = (column, line) => {
    if (column.id === "actions") {
      return editedSoftware && editedSoftware.id === line.id ? (
        <div className={`actions ${formFocus && "focus"}`}>
          <div
            className={`action ${
              editedSoftware.label !== "" &&
              (editedSoftware.purchases > 0 ||
                editedSoftware.sales > 0 ||
                editedSoftware.financial_transactions_coda > 0 ||
                editedSoftware.financial_transactions_noncoda > 0 ||
                editedSoftware.caisse > 0 ||
                editedSoftware.remuneration > 0)
                ? "active"
                : "disabled"
            }`}
            onClick={() => handleSaveSoftware(editedSoftware)}
          >
            <CheckIcon size={12} />
          </div>
          <div className="action" onClick={() => setEditedSoftware(null)}>
            <IconClose size={8} />
          </div>
        </div>
      ) : (
        <div className="actions">
          <div className="action" onClick={() => setEditedSoftware(line)}>
            <IconPencil size={12} />
          </div>
        </div>
      );
    } else {
      return editedSoftware && editedSoftware.id === line.id ? (
        <div className={editedSoftware && "focus"}>
          <input
            value={editedSoftware[column.id]}
            onChange={(event) =>
              setEditedSoftware({
                ...editedSoftware,
                [column.id]: event.target.value,
              })
            }
          />
        </div>
      ) : (
        <div className="matrix_title">{line[column.id]}</div>
      );
    }
  };

  const handleCoefficientsTitle = (title) => {
    return <div className="matrix_title">{_(title.label)}</div>;
  };

  const handleDocColumns = (column, line) => {
    if (column.id === "actions") {
      return editedDocType && editedDocType.id === line.id ? (
        <div className={`actions ${formFocus && "focus"}`}>
          <div
            className={`action ${
              line.doc !== editedDocType.doc ? "active" : "disabled"
            }`}
            onClick={() => handleSaveDocType(editedDocType)}
          >
            <CheckIcon size={12} />
          </div>
          <div className="action" onClick={() => setEditedDocType(null)}>
            <IconClose size={8} />
          </div>
        </div>
      ) : (
        <div className="actions">
          <div className="action" onClick={() => setEditedDocType(line)}>
            <IconPencil size={12} />
          </div>
        </div>
      );
    } else {
      return editedDocType && editedDocType.id === line.id ? (
        <div className={editedDocType && "focus"}>
          <div className="flex align-items-center">
            <RadioButton
              name="doc"
              value={column.id}
              onChange={() =>
                setEditedDocType({ ...editedDocType, doc: column.id })
              }
              checked={column.id === editedDocType.doc}
            />
          </div>
        </div>
      ) : (
        <div className="matrix_title">
          {line.doc === column.id ? (
            <CheckCircleIcon size="20" fill="#18a0fb" />
          ) : (
            ""
          )}
        </div>
      );
    }
  };

  const handleDocTitle = (title) => {
    return <div className="matrix_title">{title.label}</div>;
  };

  const renderCoefficientMatrix = () => {
    return (
      <Fragment>
        {volumetryCoefficientsQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : coefficients.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          <Fragment>
            <div className="matrix_frame">
              <div>{_("coefficientsConfig")} :</div>
              <CustumedMatrix
                columns={columns}
                items={coefficients}
                handleCellule={handleCoefficientsColumns}
                handleTitle={handleCoefficientsTitle}
                headerTagBG={"#F8F9FA"}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  const renderDocTypeMatrix = () => {
    return (
      <Fragment>
        {docTypesQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : docTypes.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("noDataFound")}</div>
            <div className="subTitle">{_("noAttribution")}</div>
          </div>
        ) : (
          <Fragment>
            <div className="matrix_frame second_matrix">
              <div>{_("docTypesConfig")} :</div>
              <CustumedMatrix
                columns={columns}
                items={docTypes}
                handleCellule={handleDocColumns}
                handleTitle={handleDocTitle}
                headerTagBG={"#F8F9FA"}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  return (
    <div style={{ width: 900 }}>
      <div className="sidebar_header">
        <div className="sidebar_header_infos">
          <h3>{_("EncodingSetting")}</h3>
        </div>
        <div className="sidebar_header_action" onClick={onClose}>
          <IconClose size={12} />
        </div>
      </div>
      <div className="sidebar_body vertical_content">
        {renderCoefficientMatrix()}
        {renderDocTypeMatrix()}
      </div>
    </div>
  );
};

export default VolumetryConfigContent;
