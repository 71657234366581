import React from "react";

export const IconArrowRight = ({ size = "16", fill = "#18A0FB" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_59620_68858)">
        <path
          d="M3.82864 8.57107L11.0238 15.7638C11.3395 16.0787 11.851 16.0787 12.1675 15.7638C12.4832 15.4489 12.4832 14.9374 12.1675 14.6225L5.54309 8.00043L12.1667 1.37841C12.4824 1.06349 12.4824 0.551973 12.1667 0.236257C11.851 -0.0786648 11.3387 -0.0786649 11.023 0.236256L3.82784 7.42899C3.51691 7.74066 3.51691 8.26021 3.82864 8.57107Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_59620_68858">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
