import React from "react";

export const AttachmentIcon = ({ size = "14", fill = "#29394D" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.20468 1.39323C8.74021 1.39323 8.29476 1.57774 7.96633 1.90617L2.60549 7.26701C2.05815 7.81435 1.75065 8.55671 1.75065 9.33078C1.75065 10.1048 2.05815 10.8472 2.60549 11.3945C3.15284 11.9419 3.8952 12.2494 4.66926 12.2494C5.44333 12.2494 6.18569 11.9419 6.73304 11.3945L12.0939 6.03371C12.3217 5.80591 12.691 5.80591 12.9188 6.03371C13.1466 6.26152 13.1466 6.63087 12.9188 6.85867L7.55799 12.2195C6.79185 12.9856 5.75275 13.4161 4.66926 13.4161C3.58578 13.4161 2.54667 12.9856 1.78054 12.2195C1.0144 11.4534 0.583984 10.4143 0.583984 9.33078C0.583984 8.24729 1.0144 7.20819 1.78054 6.44205L7.14137 1.08121C7.68859 0.53399 8.43079 0.226562 9.20468 0.226562C9.97857 0.226562 10.7208 0.53399 11.268 1.08121C11.8152 1.62844 12.1226 2.37063 12.1226 3.14453C12.1226 3.91842 11.8152 4.66061 11.268 5.20784L5.90133 10.5687C5.57302 10.897 5.12773 11.0814 4.66343 11.0814C4.19913 11.0814 3.75385 10.897 3.42554 10.5687C3.09723 10.2404 2.91278 9.79508 2.91278 9.33078C2.91278 8.86648 3.09723 8.42119 3.42554 8.09288L8.37828 3.14597C8.60622 2.9183 8.97556 2.91852 9.20324 3.14646C9.43091 3.3744 9.43069 3.74374 9.20275 3.97142L4.25049 8.91784C4.14113 9.02733 4.07945 9.17601 4.07945 9.33078C4.07945 9.48566 4.14098 9.6342 4.25049 9.74371C4.36001 9.85323 4.50855 9.91476 4.66343 9.91476C4.81831 9.91476 4.96685 9.85323 5.07637 9.74371L10.443 4.38288C10.7713 4.05447 10.956 3.60889 10.956 3.14453C10.956 2.68005 10.7715 2.2346 10.443 1.90617C10.1146 1.57774 9.66915 1.39323 9.20468 1.39323Z"
      fill={fill}
    />
  </svg>
);
