import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { useOnClickOutside, renderTeamYears } from "../../utils";

import LigthPageHeader from "../common/PageHeader/LigthPageHeader";
import { TiArrowSortedDown } from "react-icons/ti";

import "./CollaboratorSpace.local.scss";

const CollaboratorSpaceHeader = ({
  collaboratorSpace = null,
  onChangeYear,
  collaborator,
  year,
}) => {
  const refYears = useRef();
  useOnClickOutside(refYears, () => setYearDivIsVisible(false));
  const { language } = useSelector((state) => state.params);

  //Header
  const [yearDivIsVisible, setYearDivIsVisible] = useState(false);

  const renderHeaderAction = () => {
    return (
      <div
        ref={refYears}
        className={
          "customed_select" +
          " " +
          (yearDivIsVisible ? "customed_select_opened" : "")
        }
        style={{
          background: "#6d7f92",
          color: "#ffffff",
        }}
      >
        <div className={"customed_select_header"}>
          <span>{year}</span>
          <div className={"actions"}>
            <div>
              <span
                className={"action"}
                onClick={() => setYearDivIsVisible(!yearDivIsVisible)}
              >
                <TiArrowSortedDown />
              </span>
            </div>
          </div>
        </div>
        {yearDivIsVisible && (
          <div className={"customed_select_body"}>
            <div className={"customed_select_body_options"}>
              {renderTeamYears().map((item, index) => (
                <div
                  key={index}
                  className={"customed_select_body_option"}
                  onClick={() => {
                    onChangeYear(item);
                    setYearDivIsVisible(false);
                  }}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    !collaboratorSpace && (
      <LigthPageHeader
        title={{
          label: "ficheCollaborator",
          value: collaborator ? collaborator.name : "",
        }}
        goBack={`/${language}/collaborators`}
      >
        {renderHeaderAction()}
      </LigthPageHeader>
    )
  );
};

export default CollaboratorSpaceHeader;
