import React, { useEffect, useRef, useState } from "react";
import { toNumber } from "../../utils";

const EditableCell = ({ initialValue, updateCell, synchronize, style }) => {
  const [value, setValue] = useState(initialValue);
  const [originalValue, setOriginalValue] = useState(value);
  const inputRef = useRef(null);

  const onValueChange = (e) => {
    setValue(Number(e.target.value));
    synchronize && synchronize(Number(e.target.value));
  };

  const onKeyDown = (e) => {
    if (
      [9, 13].includes(e.keyCode) &&
      toNumber(originalValue) !== toNumber(value)
    ) {
      const res = updateCell(Number(e.target.value));
      if (res) {
        setOriginalValue(value);
        e.keyCode !== 9 && inputRef.current.blur();
      }
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    setOriginalValue(initialValue);
  }, []);

  return (
    <input
      style={style}
      data-size={value.length + 1}
      ref={inputRef}
      type="number"
      min="0"
      value={value}
      onChange={onValueChange}
      onKeyDown={onKeyDown}
      //   onBlur={e => onKeyDown(e, true)}
    />

    //  <NumberFormat
    //   value={value}
    //   type={"text"}
    //   allowNegative={false}
    //   displayType={"input"}
    //   thousandSeparator={"."}
    //   decimalSeparator={","}
    //   isNumericString={true}
    //   onKeyDown={onKeyDown}
    //   onChange={onValueChange}
    // />
  );
};

export default EditableCell;
