import React from "react";

export const ClockIcon = ({ size = 14, fill = "#29394D" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_60661_73257)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00065 1.7487C4.10116 1.7487 1.75065 4.0992 1.75065 6.9987C1.75065 9.89819 4.10116 12.2487 7.00065 12.2487C9.90015 12.2487 12.2507 9.89819 12.2507 6.9987C12.2507 4.0992 9.90015 1.7487 7.00065 1.7487ZM0.583984 6.9987C0.583984 3.45487 3.45682 0.582031 7.00065 0.582031C10.5445 0.582031 13.4173 3.45487 13.4173 6.9987C13.4173 10.5425 10.5445 13.4154 7.00065 13.4154C3.45682 13.4154 0.583984 10.5425 0.583984 6.9987Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99935 2.91797C7.32152 2.91797 7.58268 3.17914 7.58268 3.5013V6.64078L9.59356 7.64622C9.88171 7.7903 9.99851 8.14069 9.85443 8.42884C9.71035 8.717 9.35996 8.8338 9.07181 8.68972L6.73847 7.52305C6.54085 7.42424 6.41602 7.22225 6.41602 7.0013V3.5013C6.41602 3.17914 6.67718 2.91797 6.99935 2.91797Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_60661_73257">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
