import { Dialog } from "primereact/dialog";
import AnimatedLoadingIcon from "../../../common/Icons/AnimatedLoadingIcon";
import { InputTextarea } from "primereact/inputtextarea";
import styles from './Modal.module.scss';
import { Button } from "primereact/button";

export default function Modal({
  isVisible = false,
  title = "",
  value = "",
  inputLabel = null,
  isSavingChanges = false,
  onValueChanges = () => {},
  onHide = () => {},
  onConfirm = () => {}
}) {
  return (
    <Dialog
      visible={isVisible}
      onHide={onHide}
      className={styles['modal-dialog']}
      header={title}
      footer={
        <div>
          <Button
            size="small"
            severity="success"
            onClick={onConfirm}
            disabled={isSavingChanges}
          >
            {
              isSavingChanges ? (
                <AnimatedLoadingIcon color="#ffffff" />
              ):
              'Enregistrer'
            }
          </Button>
        </div>
      }
    >
      <span className="p-float-label" style={{marginTop: '20px'}}>
        <InputTextarea
          id="textfield"
          className={styles['textarea']}
          rows={5}
          value={value}
          disabled={isSavingChanges}
          onChange={onValueChanges}
        />
        <label htmlFor="textfield">{inputLabel}</label>
      </span>
  </Dialog>
  )
}
