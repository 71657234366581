import axios from "axios";
import $ from "jquery";
import { TTP_API_URL, CLIENT_CREDENTIAL } from "../config";

export const getTTPUser = ({ userId, token }) => {
  const filter = [
    {
      property: "id",
      value: userId,
      operator: "eq",
    },
  ];

  const fields = [
    "*",
    "communities",
    "uid",
    "adminisId",
    "avatar",
    "description",
    "contactSocialNetworks",
    "cover",
    "url",
    "role",
    "accountingUserOrganizations",
    "selectedOrganization"
  ];

  const requestUrl = `${TTP_API_URL}/organization/user`;

  /*let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(",")
  };*/

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${fields.join(
      ","
    )}&filter=${JSON.stringify(filter)}&workspace=ua`,
  });

  /*return axios.get(requestUrl, {
    //cancelToken: getLatestArticlesSourceToken.token,
    params
  });*/
};

export const getClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: CLIENT_CREDENTIAL,
  });
};

export const getSelectedOrganization = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/user/get-selected-organization`;

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${[
      "id",
      "uen",
      "name",
      "url",
      "abbreviation",
    ].join(",")}`,
  });

  /*return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: ["id", "uen", "name", "url", "abbreviation"].join(",")
    }
  });*/
};

export const updateCookieSetting = async ({ token, acceptCookies, id }) => {
  const requestUrl = `${TTP_API_URL}/organization/user`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("acceptCookies", acceptCookies ? 1 : 0);
  formData.append("id", id);

  return axios.post(requestUrl, formData);
};
