import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import { CustumedMatrix } from "tamtam-components";
import { Link } from "react-router-dom";
import { getCollaboratorContribution } from "../../../../api";
import _ from "../../../../i18n";

import { FlagIcon, ShortcutIcon } from "../../../common/Icons";
import CollaboratorCorrectionCard from "./CollaboratorCorrectionCard";
import "./EncodingProgressionContent.local.scss";
import { renderDurationDisplay } from "../../../../utils";

const ProgressionCompensationContent = ({
  id,
  year,
  lng,
  name,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  const [expandedClient, setExpandedClient] = useState(null);
  const [showSideBar, setShowSideBar] = useState(null);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [columns, setColumns] = useState([
    {
      id: "total",
      label: _("total"),
      isEditable: true,
      widthPercentage: 10,
    },
    {
      id: "detail",
      label: _("cpDetails"),
      isEditable: true,
      widthPercentage: 40,
    },
  ]);

  const codes =
    filter.category.label.split(".")[0] === "14"
      ? ["CP1", "CP2", "CP3", "CP4"]
      : ["AD1", "AD2", "AD3"];

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorContribution(
            id,
            organization,
            year,
            token,
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("MM-DD")
              : "01-01",
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("MM-DD")
              : "12-31",
            filter.keyWord,
            filter.category.label.split(".")[0]
          );
          setContribution(response.data.data);
          setFiltredContribution(response.data.data);
        } catch (e) {
          setContribution([]);
          return null;
        }
      }
    }
  );

  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {collaboratorContributionQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !collaboratorContributionQuery.isFetching &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          collaboratorContributionQuery.isFetched && (
            <div className="production-frame_scroll">
              <div className="total">
                {_("folderCount")} : {contribution.length - 1}
              </div>
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const prepareExpandedClient = (id) => {
    if (typeof id === "string" && id.split("-").length > 1) {
      return;
    }
    if (id === "TOTAL") {
      return;
    }
    if (id === expandedClient) {
      const boxes = document.querySelectorAll(
        ".CustumedMatrix_matrix_body_line__11lbb"
      );

      boxes.forEach((box) => {
        box.classList.remove("sub_line");
        box.classList.remove("last_sub_line");
        box.classList.remove("expanded_line");
      });
      setFiltredContribution(contribution);
      setExpandedClient(null);
      setColumns([
        {
          id: "total",
          label: _("total"),
          isEditable: true,
          widthPercentage: 10,
        },
        {
          id: "detail",
          label: _("cpDetails"),
          isEditable: true,
          widthPercentage: 40,
        },
      ]);
    } else {
      let result = [];
      contribution.forEach((item) => {
        result.push(item);
        if (item.id === id) {
          Object.keys(item.children).forEach((child) => {
            result.push(item.children[child]);
          });
        }
      });
      setExpandedClient(id === expandedClient ? null : id);
      setFiltredContribution(result);
      if (document.getElementById("expanded")) {
        setTimeout(() => {
          document
            .getElementById("expanded")
            .parentElement.parentElement.classList.add("expanded_line");
          document
            .getElementsByClassName("sub-line-child")
            .parentElement.parentElement.classList.add("sub_line");
        }, "100");
      }
      setColumns([
        {
          id: "total",
          label: _("total"),
          isEditable: true,
          widthPercentage: 10,
        },
        {
          id: "detail",
          label: _("cpDetails"),
          isEditable: true,
          widthPercentage: 40,
        },
        {
          id: "observation",
          label: _("observation"),
          isEditable: true,
          widthPercentage: 20,
        },
      ]);
    }
  };

  const handleColumns = (column, line) => {
    if (line.title.label === "TOTAL") {
      if (column.id === "total") {
        return (
          <div
            className="tags"
            style={{ flexDirection: "column", justifyContent: "space-around" }}
          >
            <div className="tag no-border sm-margin">
              <span>{_("total")}</span>
            </div>
            <div className="tag no-border sm-margin">
              <div>{renderDurationDisplay(
                parseFloat(line.total.collaborator).toFixed(2))
              }</div>
            </div>
            {/* <div className="tag no-border sm-margin">
              <div>{parseFloat(line.total.client).toFixed(2)}</div>
            </div> */}
          </div>
        );
      } else if (column.id === "observation") {
        return;
      } else {
        return (
          <Fragment>
            <div
              className="tags"
              style={{
                padding: "0",
                margin: "0px 2px",
                justifyContent: "space-around",
              }}
            >
              {codes.map((code, index) => (
                <div
                  className="tag no-border sm-margin"
                  style={{ padding: index === 0 ? "none" : "er" }}
                >
                  <span>{code}</span>
                </div>
              ))}
            </div>
            <div
              className="tags"
              style={{
                padding: "0",
                margin: "0px 2px",
                justifyContent: "space-around",
              }}
            >
              {codes.map((code, index) => (
                <div
                  className="tag no-border sm-margin"
                  style={{ padding: index === 0 ? "none" : "er" }}
                >
                  <div>
                    {renderDurationDisplay(
                      parseFloat(line.details.collaborator[code]).toFixed(2)
                    )}
                  </div>
                </div>
              ))}
            </div>
            {/* <div
              className="tags"
              style={{
                padding: "0",
                margin: "0px 2px",
                justifyContent: "space-around",
              }}
            >
              {codes.map((code, index) => (
                <div
                  className="tag no-border sm-margin"
                  style={{ padding: index === 0 ? "none" : "er" }}
                >
                  <div>{parseFloat(line.details.client[code]).toFixed(2)}</div>
                </div>
              ))}
            </div> */}
          </Fragment>
        );
      }
    } else {
      if (column.id === "total") {
        return line.title.type === "child" ? (
          <div
            className="tags"
            style={{
              flexDirection: "column",
            }}
          >
            <div className="tag no-border sm-margin">
              <div style={{ textAlign: "center", height: "fit-content" }}>
                {line.date}
              </div>
            </div>
          </div>
        ) : (
          <div
            className="tags"
            style={{
              flexDirection: "column",
            }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            <div className="tag no-border sm-margin" style={{ marginTop: 12 }}>
              <div>{renderDurationDisplay(
                parseFloat(line.total.collaborator).toFixed(2)
              )}</div>
            </div>
            {/* <div className="tag no-border sm-margin">
              <div>{parseFloat(line.total.client).toFixed(2)}</div>
            </div> */}
          </div>
        );
      } else if (column.id === "observation") {
        return line.title.type === "child" ? (
          <div className="text-tag">
            <div>
              {line.details.observation ? line.details.observation : "---"}
            </div>
          </div>
        ) : (
          ""
        );
      } else {
        return line.title.type === "child" ? (
          <div
            className="tags"
            style={{
              padding: "0",
              margin: "0px 2px",
              justifyContent: "space-around",
            }}
          >
            {codes.map((code, index) => (
              <div
                className="tag no-border sm-margin"
                style={{ padding: index === 0 ? "none" : "er" }}
              >
                <div>{renderDurationDisplay(
                  parseFloat(line.details[code]).toFixed(2)
                )}</div>
              </div>
            ))}
          </div> 
        ) : (
          <Fragment>
            <div onClick={() => prepareExpandedClient(line.id)}>
              <div
                className="tags"
                style={{
                  padding: "0",
                  margin: "0px 2px",
                  justifyContent: "space-around",
                }}
              >
                {codes.map((code, index) => (
                  <div
                    className="tag no-border sm-margin"
                    style={{ padding: index === 0 ? "none" : "er", marginTop: 12 }}
                  >
                    <div>
                      {renderDurationDisplay(
                        parseFloat(line.details.collaborator[code]).toFixed(2)
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* <div
                className="tags"
                style={{
                  padding: "0",
                  margin: "0px 2px",
                  justifyContent: "space-around",
                }}
              >
                {codes.map((code, index) => (
                  <div
                    className="tag no-border sm-margin"
                    style={{ padding: index === 0 ? "none" : "er" }}
                  >
                    <div>
                      {parseFloat(line.details.client[code]).toFixed(2)}
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </Fragment>
        );
      }
    }
  };

  const handleTitle = (title) => {
    return (
      <div
        onClick={
          title.title.type === "child"
            ? null
            : () => prepareExpandedClient(title.id)
        }
        className={`${
          title.title.label === "TOTAL"
            ? "matrix_title_header sm-header"
            : title.title.type === "child"
            ? "matrix_sub_title"
            : "matrix_title"
        }`}
        id={
          title.title.label === "total"
            ? "total-line"
            : expandedClient === title.id
            ? "expanded"
            : title.title.type === "child"
            ? `sub-line-child`
            : ""
        }
      >
        <div
          className="title_infos"
          style={{ width: title.title.type !== "child" ? "51%" : "100%" }}
        >
          <div>
            {title.title.type === "child"
              ? ""
              : title.title.label}
          </div>
        </div>

        {title.title.label !== "TOTAL" &&
          title.title.type !== "child" &&
          collaborator &&
          !collaborator.isDeleted && (
            <div className="actions">
              <Link to={`/${lng}/fiche-client/${title.id}`}>
                <div className={`title_action action_doh`}>
                  <ShortcutIcon size="16" />
                </div>
              </Link>
              <div
                className={`title_action ${
                  title.title.state !== "danger" && title.title.count === 0
                    ? "action_doh"
                    : ""
                }`}
                style={{ width: title.title.count > 0 ? "" : "51%" }}
                onClick={() => setShowSideBar(title)}
              >
                <FlagIcon />
                <div>{title.title.count > 0 ? title.title.count : "+"}</div>
              </div>
            </div>
          )}

        {title.title.type !== "child" && (
          <div
            className="tags"
            style={{
              flexDirection: "column",
              justifyContent: "space-around",
              marginTop: -5
            }}
          >
            {/* {title.title.label === "TOTAL" ? (
              <>
                <div className="tag no-border sm-margin lg-tag">
                  <div>{name.split(" ")[0]}</div>
                </div>
                <div className="tag no-border sm-margin lg-tag">
                  <div>{_("all")}</div>
                </div>
              </> ) : ""
            } */}
          </div>
        )}
      </div>
    );
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar && "display"}`}>
          <CollaboratorCorrectionCard
            organization={organization}
            category={filter.category}
            token={token}
            year={year}
            loggedAs={loggedAs}
            creator={user}
            onClose={() => {
              document
                .getElementsByClassName("off-canvas-wrapper")[0]
                .classList.remove("off-canvas-wrapper-fixed");
              setShowSideBar(null);
            }}
            client={showSideBar}
            lng={lng}
          />
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {renderPannelFrame()}
      {showSideBar && renderSideBarFrame()}
    </Fragment>
  );
};

export default ProgressionCompensationContent;
