import React from "react";

export const MailIcon = ({ size = 16, fill = "#6D7F92" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66699 3.33333C2.30185 3.33333 2.00033 3.63486 2.00033 4V12C2.00033 12.3651 2.30185 12.6667 2.66699 12.6667H13.3337C13.6988 12.6667 14.0003 12.3651 14.0003 12V4C14.0003 3.63486 13.6988 3.33333 13.3337 3.33333H2.66699ZM0.666992 4C0.666992 2.89848 1.56547 2 2.66699 2H13.3337C14.4352 2 15.3337 2.89848 15.3337 4V12C15.3337 13.1015 14.4352 14 13.3337 14H2.66699C1.56547 14 0.666992 13.1015 0.666992 12V4Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.787561 3.61645C0.998704 3.31481 1.41439 3.24146 1.71602 3.4526L8.00038 7.85165L14.2847 3.4526C14.5864 3.24146 15.0021 3.31481 15.2132 3.61645C15.4243 3.91808 15.351 4.33377 15.0494 4.54491L8.38269 9.21158C8.15314 9.37226 7.84762 9.37226 7.61807 9.21158L0.951408 4.54491C0.649775 4.33377 0.576418 3.91808 0.787561 3.61645Z"
      fill={fill}
    />
  </svg>
);
