export default function EurosSearch({size = 24, color = '#010002'}) {
	return (
		<svg
			height={size}
			width={size}
			version="1.1"
			viewBox="0 0 179.006 179.006"
		>
			<g>
				<path fill={color} d="M41.983,73.14h8.968c-0.054-1.253-0.09-2.53-0.09-3.849c0-1.134,0.036-2.255,0.09-3.389h-10.89
					l1.921-6.504h9.714c1.038-5.591,2.971-10.43,5.812-14.523c4.768-6.904,11.343-10.353,19.744-10.353
					c7.154,0,12.703,2.238,16.654,6.731c3.962,4.487,6.14,9.589,6.587,15.299h-7.476c-0.823-4.332-2.506-7.769-5.03-10.305
					c-2.554-2.536-6.086-3.807-10.627-3.807c-5.597,0-10.09,2.339-13.521,7.011c-1.891,2.56-3.252,5.877-4.081,9.941h25.556
					l-1.921,6.504H58.851c-0.101,1.551-0.137,3.21-0.137,4.947c0,0.788,0.018,1.557,0.048,2.285h26.564l-1.927,6.51H59.436
					c0.74,3.998,2.041,7.459,3.896,10.388c3.091,4.887,7.691,7.333,13.825,7.333c5.651,0,9.959-2.59,12.912-7.739
					c1.569-2.715,2.727-6.289,3.485-10.716h7.476c-0.686,7.083-2.888,13.02-6.641,17.811c-4.499,5.776-10.543,8.658-18.145,8.658
					c-6.57,0-12.065-2.363-16.534-7.095c-4.213-4.457-6.922-10.675-8.091-18.641H40.062L41.983,73.14z M21.517,125.547
					c-28.689-28.689-28.689-75.344,0-104.032c28.689-28.683,75.344-28.683,104.032,0s28.689,75.344,0,104.032
					S50.199,154.235,21.517,125.547z M27.495,119.568c25.383,25.389,66.692,25.383,92.075,0c25.389-25.383,25.389-66.692,0-92.075
					c-25.383-25.389-66.704-25.383-92.075,0C2.118,52.876,2.118,94.185,27.495,119.568z M134.237,121.865l-12.369,12.369h0.006
					l42.21,42.21c3.413,3.413,8.956,3.413,12.363,0c3.413-3.407,3.413-8.95,0-12.363L134.237,121.865L134.237,121.865z M73.47,133.047
					c-13.974-3.347-27.209-10.454-38.104-21.355c-10.89-10.901-18.008-24.13-21.343-38.092c0.012,15.21,5.818,30.419,17.423,42.019
					C43.045,127.229,58.255,133.035,73.47,133.047z"/>
		</g>
	</svg>
	);
}
