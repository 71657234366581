import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DrawerIframe from "../common/DrawerIframe";
import { TTP_TEAM_URL } from "../../config";
const CollaboratorSettings = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const [fiduciaryId, setFiduciaryId] = useState(null);
  useEffect(() => {
    if (auth.navCommunity) {
      setFiduciaryId(auth.navCommunity.id);
    }
  }, [auth.navCommunity]);

  let { name } = useParams();
  const params = [];
  let powerTeamUrl = TTP_TEAM_URL + `/collaborators-settings/${name}`;

  const preferences =
    auth.user && auth.navCommunity && auth.user.userOrganizations.length > 0
      ? auth.user.userOrganizations.find(
          (organization) => organization.id === auth.navCommunity.id
        ).authorisation.preferences
      : {};

  if (fiduciaryId) {
    params.push("without_header");
    params.push("without_menu");
    params.push("organization_id=" + fiduciaryId);
    params.push("name=" + name);
    return <DrawerIframe src={powerTeamUrl} params={params} hasAccess={preferences.allowAccessCollaboratorSetting} />;
  }
  return <></>;
};

export default CollaboratorSettings;
