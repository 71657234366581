import axios from "axios";
import { TTP_API_URL } from "../config";

export const getCollaboratorsEcheance = (token, organizationId, year, zoneId) => {
  const requestUrl = `${TTP_API_URL}/team/collaboratorsBalance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      year: year,
      zoneId: zoneId,
    },
  });
};

export const getCollaboratorBalance = (
  token,
  organizationId,
  year,
  collaboratorId,
  zoneId,
  typeBalance,
  closingDate,
  searchWord
) => {
  const requestUrl = `${TTP_API_URL}/team/balance`;
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      collaboratorId: collaboratorId,
      year: year,
      typeBalance: typeBalance,
      closingDate: closingDate,
      zoneId: zoneId,
    },
  });
};

export const getCollaboratorsBonuses = (token, organizationId, year, zoneId) => {
  const requestUrl = `${TTP_API_URL}/team/collaborators_bonuses`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organizationId,
      year: year,
      zoneId: zoneId,
    },
  });
};