import React from "react";

export const ExclamationMarkIcon = ({ fill = "#EB5757", size = 14 }) => (
  <svg width={size} height={size} fill={fill} viewBox="0 0 301.691 301.691">
    <g>
      <polygon points="119.151,0 129.6,218.406 172.06,218.406 182.54,0 	" />
      <rect x="130.563" y="261.168" width="40.525" height="40.523" />
    </g>
  </svg>
);
