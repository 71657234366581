import React from "react";

export const MoreVerticalIcon = ({color = '#29394D', width = 21, height = 20, className = null}) => (
  <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g id="more-vertical">
      <path id="Vector" d="M10.5013 10.8346C10.9615 10.8346 11.3346 10.4615 11.3346 10.0013C11.3346 9.54106 10.9615 9.16797 10.5013 9.16797C10.0411 9.16797 9.66797 9.54106 9.66797 10.0013C9.66797 10.4615 10.0411 10.8346 10.5013 10.8346Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_2" d="M10.5013 4.9987C10.9615 4.9987 11.3346 4.6256 11.3346 4.16536C11.3346 3.70513 10.9615 3.33203 10.5013 3.33203C10.0411 3.33203 9.66797 3.70513 9.66797 4.16536C9.66797 4.6256 10.0411 4.9987 10.5013 4.9987Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_3" d="M10.5013 16.6667C10.9615 16.6667 11.3346 16.2936 11.3346 15.8333C11.3346 15.3731 10.9615 15 10.5013 15C10.0411 15 9.66797 15.3731 9.66797 15.8333C9.66797 16.2936 10.0411 16.6667 10.5013 16.6667Z" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
  </svg>
);
