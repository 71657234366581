import axios from "axios";
import { TTP_API_URL } from "../../config";

export const getLastHourCollaboratorsContribution = (
  token,
  organization,
  partner,
  year
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/collaborators-contribution`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getPartnerStatistics = (token, organization, partner, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/statistics`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      partner: partner,
      year: year,
    },
  });
};

export const getBinomesList = (token, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/binomes-list`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      partner: data.partner,
      year: year,
      period: data.period,
      start: data.start,
      end: data.end,
    },
  });
};

export const getProductionCapacity = (token, collaborators, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/production-capacity`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      collaborators,
      partner: data.partner,
      year: year,
      period: data.period,
      start: data.start,
      end: data.end,
    },
  });
};

export const countPlans = (token, collaborators, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/count-plans`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      collaborators,
      partner: data.partner,
      year: year,
      start: data.start,
      end: data.end,
    },
  });
};

export const getLastAbsences = (token, collaborators, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/calendar`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      collaborators,
      partner: data.partner,
      year: year,
    },
  });
};

export const getErrorReportCounter = (token, collaborators, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/error-report`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      collaborators,
      partner: data.partner,
      year: year,
    },
  });
};

export const getBalanceProduction = (token, collaborators, data, year) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/balance`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: data.organization.id,
      collaborators,
      partner: data.partner,
      year: year,
    },
  });
};

export const getBinomeFinancials = (
  token,
  collaborators,
  organization,
  partner,
  year,
  start,
  end
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/financial`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborators,
      partner: partner,
      year,
      start,
      end,
    },
  });
};

export const getScheduleProgression = (
  token,
  organization,
  partner,
  year,
  type
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/schedule-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner?.id,
      year: year,
      type: type,
    },
  });
};

export const getProfitProgression = (
  token,
  organization,
  partner,
  year,
  type
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/profit-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner?.id,
      year: year,
      type: type,
    },
  });
};

export const getCPProgression = (token, organization, partner, year, zone) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/production-progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization?.id,
      partner: partner?.id,
      year: year,
      zone: zone,
    },
  });
};

export const getNonBillableContribution = (
  token,
  organization,
  collaborator,
  partner,
  year,
  role
) => {
  const requestUrl = `${TTP_API_URL}/team/dashboard/partner/non-billable-tasks`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organization,
      collaborator: collaborator,
      partner: partner,
      year: year,
      role: role,
    },
  });
};
