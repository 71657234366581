import React from "react";

export const TrelloIcon = ({ size = 16, fill = "#18A0FB" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.667 13.3346C13.0352 13.3346 13.3337 13.0362 13.3337 12.668L13.3337 3.33464C13.3337 2.96645 13.0352 2.66797 12.667 2.66797L3.33366 2.66797C2.96547 2.66797 2.66699 2.96645 2.66699 3.33463L2.66699 12.668C2.66699 13.0362 2.96547 13.3346 3.33366 13.3346L12.667 13.3346ZM14.667 12.668C14.667 13.7725 13.7716 14.668 12.667 14.668L3.33366 14.668C2.22909 14.668 1.33366 13.7725 1.33366 12.668L1.33366 3.33463C1.33366 2.23007 2.22909 1.33464 3.33366 1.33464L12.667 1.33464C13.7716 1.33464 14.667 2.23007 14.667 3.33464L14.667 12.668Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 11.3333C12 11.7015 11.7015 12 11.3333 12L9.33333 12C8.96514 12 8.66667 11.7015 8.66667 11.3333L8.66667 5.33333C8.66667 4.96514 8.96514 4.66667 9.33333 4.66667L11.3333 4.66667C11.7015 4.66667 12 4.96514 12 5.33333L12 11.3333ZM10.6667 10.6667L10.6667 6L10 6L10 10.6667L10.6667 10.6667Z"
      fill={fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.33301 11.3333C7.33301 11.7015 7.03453 12 6.66634 12L4.66634 12C4.29815 12 3.99967 11.7015 3.99967 11.3333L3.99968 8C3.99968 7.63181 4.29815 7.33333 4.66634 7.33333L6.66634 7.33333C7.03453 7.33333 7.33301 7.63181 7.33301 8L7.33301 11.3333ZM5.99967 10.6667L5.99967 8.66667L5.33301 8.66667L5.33301 10.6667L5.99967 10.6667Z"
      fill={fill}
    />
  </svg>
);
