import axios from "axios";
import { TTP_API_URL } from "../config";
import moment from "moment";

export const getCollaborators = ({ token, organizationId, year }) => {
  const requestUrl = `${TTP_API_URL}/team/budget/collaborators`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      year,
    },
  });
};

export const getCollaborator = (id, organizationId, token, uid = null, erpId = null) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organization: organizationId,
      uid: uid,
      erpId: erpId,
      id,
    },
  });
};

export const getCollaboratorsTimeSheetNotification = (
  organizationId,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/gd_notifications`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
    },
  });
};
export const getCollaboratorsPlanner = (
  organizationId,
  collaboratorId,
  year,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/planner`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organizationId,
      collaboratorId: collaboratorId,
      year: year,
    },
  });
};

export const saveCollaboratorsPlanner = (
  id,
  organizationId,
  type,
  title,
  meetingPlace,
  collaboratorId,
  collaborators,
  clientId,
  categoryId,
  status,
  date,
  observation,
  recurrenceDetails,
  duration,
  durationTravel,
  parentId,
  year,
  token,
  meetingId,
  meetingUrl
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/planner-save`;
  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("type", type);
  formData.append("title", title);
  if (meetingPlace !== null && meetingPlace !== undefined) {
    formData.append("meetingPlace", meetingPlace);
  }
  formData.append("collaboratorId", collaboratorId);
  formData.append("collaborators", JSON.stringify(collaborators));
  if (clientId !== null && clientId !== undefined) {
    formData.append("clientId", clientId);
  }
  if (categoryId !== null && categoryId !== undefined) {
    formData.append("categoryId", categoryId);
  }
  formData.append("status", status);
  formData.append("date", date);
  if (observation !== null && observation !== undefined) {
    formData.append("observation", observation);
  }
  formData.append("year", year);
  formData.append("duration", duration);
  formData.append("recurrenceDetails", JSON.stringify(recurrenceDetails));

  formData.append("durationTravel", durationTravel);
  formData.append("organizationId", organizationId);

  if (parentId) {
    formData.append("parentId", parentId);
  }

  if (meetingId) {
    formData.append("meetingId", meetingId);
  }

  if (meetingUrl) {
    formData.append("meetingUrl", meetingUrl);
  }

  return axios.post(requestUrl, formData);
};

export const saveCollaboratorAbsence = (
  token,
  organization,
  planning,
  collaboratorId,
  year,
  month,
  user,
  isUpdating
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator-absence/save`;

  let formData = new FormData();
  formData.append("planning", JSON.stringify(planning));
  formData.append("collaboratorId", collaboratorId);
  formData.append("year", year);
  formData.append("month", month);
  formData.append("user", user);
  formData.append("isUpdating", isUpdating);
  formData.append("access_token", token);
  formData.append("organizationId", organization);
  formData.append("organization_id", organization);

  return axios.post(requestUrl, formData);
};

export const deleteCollaboratorPlanner = (id, organizationId, token) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/planner-delete`;

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  formData.append("organizationId", organizationId);

  return axios.post(requestUrl, formData);
};

export const getCollaboratorCalendar = (
  organizationId,
  collaboratorId,
  year,
  token
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator-absence`;
  let params = {
    access_token: token,
    organizationId: organizationId,
    collaboratorId: collaboratorId,
    year: year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getManagerNotification = (
  uid,
  organizationId,
  token,
  scoop,
  types,
  start,
  limit
) => {
  const requestUrl = `${TTP_API_URL}/team/manager_notifications`;
  let parametres = {
    scoop,
    types,
    start,
    limit,
  };
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      year: moment().year(),
      uid,
      params: JSON.stringify(parametres),
      organizationId,
    },
  });
};

export const getPersonnalDashboard = (
  organizationId,
  token,
  filter,
  collaboratorId
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/personal-dashboard`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      collaboratorId,
      role: filter.role,
      entityClientId: filter.clients,
      sortBy: filter.sortBy,
      keyWord: filter.keyWord,
      year: filter.year,
      isEntity: filter.isEntity,
      deletedStatus: filter.deletedStatus,
      clientCRMGroup: JSON.stringify(filter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(filter.clientCRMTypes),
    },
  });
};

export const getCollaboratorFinancial = (
  id,
  organization,
  token,
  year,
  filter
) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/financial`;
  return axios.get(requestUrl, {
    params: {
      organization: organization,
      organization_id: organization,
      access_token: token,
      collaborator: id,
      sortBy: filter.sortBy,
      role: filter.role,
      attribution: filter.attribution,
      clientCRMGroup: JSON.stringify(filter.clientCRMGroup),
      clientCRMTypes: JSON.stringify(filter.clientCRMTypes),
      deletedStatus: filter.deletedStatus,
      year,
      searchWord: filter.keyWord,
    },
  });
};

export const getCollaboratorEmail = ({
  token,
  collaboratorId,
  organizationId,
}) => {
  const requestUrl = `${TTP_API_URL}/team/collaborator/email`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      collaboratorId,
      organization: organizationId,
    },
  });
};
