import React from "react";

export const CircleIcon = ({
  width = "10",
  height = "10",
  fill = "#6D7F92",
  className = "",
}) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle id="Ellipse 2.12" cx="5" cy="5" r="5" fill={fill} />
  </svg>
);
