import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { CustumedMatrix } from "tamtam-components";
import {
  ComposedChart,
  Line,
  Cell,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

import { Tooltip as TooltipAntd } from "antd";
import { getCollaboratorContribution } from "../../../../api";
import {
  renderDurationDisplay,
  renderDurationMinutesDisplay,
} from "../../../../utils";
import _ from "../../../../i18n";

import {
  ArrowLeftIcon,
  FlagIcon,
  SettingsIcon,
  ShortcutIcon,
} from "../../../common/Icons";
import CollaboratorCorrectionCard from "./CollaboratorCorrectionCard";
import VolumetryConfigContent from "./VolumetryConfigContent";
import "./EncodingProgressionContent.local.scss";

const ENCODING_TYPE = [
  "sales",
  "remuneration",
  "purchases",
  "financial_transactions_coda",
  "financial_transactions_noncoda",
  "caisse",
];
const EncodingProgressionContent = ({
  id,
  year,
  lng,
  token,
  user,
  filter,
  loggedAs,
  collaborator,
  organization,
}) => {
  //Body
  const [expandedClient, setExpandedClient] = useState(null);
  const [showSideBar, setShowSideBar] = useState(null);
  const [showConfigSideBar, setShowConfigSideBar] = useState(null);
  const [progression, setProgression] = useState([]);
  const [filtredProgression, setFiltredProgression] = useState([]);
  const [contribution, setContribution] = useState([]);
  const [filtredContribution, setFiltredContribution] = useState([]);
  const [sortApplication, setSortApplication] = useState(null);
  const [columns, setColumns] = useState([
    // {
    //   id: "volumetry",
    //   label: _("volumetry"),
    //   isEditable: true,
    //   widthPercentage: 20,
    // },
    // {
    //   id: "timesheet",
    //   label: _("timesheet"),
    //   isEditable: true,
    //   widthPercentage: 50,
    // },
  ]);

  setTimeout(() => {
    const chart = document.getElementsByClassName("line_chart");
    for (let item of chart) {
      let xValues = item.getElementsByClassName("recharts-yAxis")[0]
        ? item.getElementsByClassName("recharts-cartesian-axis-tick-value")
        : [];
      for (let element of xValues) {
        element.firstChild.setAttribute("x", "510");
      }
    }
    document.getElementById("total-line") &&
      document
        .getElementById("total-line")
        .parentElement.parentElement.classList.add("non-hover-line");
  }, "500");

  let collaboratorContributionQuery = useQuery(
    ["collaboratorContributionQuery", token, year, filter],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorContribution(
            id,
            organization,
            year,
            token,
            [filter.category.id],
            filter.period && filter.period.id,
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.start
                : filter.subPeriod.start
              : filter.start
              ? moment(filter.start).format("MM-DD")
              : "01-01",
            filter.period
              ? "ANNUAL" === filter.period.id
                ? filter.period.end
                : filter.subPeriod.end
              : filter.end
              ? moment(filter.end).format("MM-DD")
              : "12-31",
            filter.keyWord
          );
          setProgression(response.data.data.chartData);
          setFiltredProgression(response.data.data.chartData.global);
          setContribution(response.data.data.clientsData);
          setFiltredContribution(response.data.data.clientsData);
          setColumns([
            {
              id: "volumetry",
              label: _("volumetry"),
              isEditable: true,
              widthPercentage: 10,
              // filter.period && "MONTHLY" !== filter.period.id ? 20 : 10,
            },
            {
              id: "timesheet",
              label: _("timesheet"),
              isEditable: true,
              widthPercentage: 50,
            },
          ]);
        } catch (e) {
          setProgression([]);
          setContribution([]);
          return null;
        }
      }
    }
  );

  const sortClientBy = (type) => {
    let sortedData = [...filtredContribution].filter((a) => a.id !== "TOTAL");
    let totalData = filtredContribution.filter((a) => a.id === "TOTAL")[0];
    switch (type) {
      case "ecart_asc":
        sortedData.sort((a, b) => {
          return a.timesheet.ecart > b.timesheet.ecart ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setFiltredContribution(sortedData);
        return;
      case "ecart_desc":
        sortedData.sort((a, b) => {
          return a.timesheet.ecart < b.timesheet.ecart ? -1 : 1;
        });
        sortedData.unshift(totalData);
        setFiltredContribution(sortedData);
        return;
      default:
        return;
    }
  };

  //Matrix functions
  const renderPannelFrame = () => {
    return (
      <div className="production-frame">
        {renderAddBubble()}
        {collaboratorContributionQuery.isFetching ? (
          <div className="ripple_loading">
            <img src={"/img/Ripple-1s-150px.svg"} alt="" />
          </div>
        ) : !collaboratorContributionQuery.isFetching &&
          filtredContribution &&
          filtredContribution.length === 0 ? (
          <div className="no_data">
            <div className="title">{_("oops")}</div>
            <div className="subTitle">{_("noCollaboratorProductionFound")}</div>
          </div>
        ) : (
          collaboratorContributionQuery.isFetched && (
            <div className="production-frame_scroll">
              <div className="total">
                {_("folderCount")} : {contribution.length - 1}
              </div>
              <CustumedMatrix
                columns={columns}
                items={filtredContribution}
                handleCellule={handleColumns}
                handleTitle={handleTitle}
                headerTagBG={"#FFFFFF"}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const handleColumns = (column, line) => {
    if (line.title.label === "total") {
      if (column.id === "volumetry") {
        // return filter.period && "MONTHLY" !== filter.period.id ? (
        //   <div style={{ display: "flex", margin: "20px 5px 17px 15px" }}>
        //     <div
        //       style={{
        //         justifyContent: "center",
        //         flexDirection: "column",
        //         display: "flex",
        //         width: "100%",
        //         margin: "3px 15px 0px 0px",
        //       }}
        //     >
        //       <span>{_("realized")}</span>
        //       <div className="progress-bar" style={{ marginTop: "11px" }}>
        //         <div
        //           style={{
        //             height: "28px",
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //             backgroundColor: "transparent",
        //           }}
        //         >
        //           <div
        //             className="progress_container"
        //             style={{
        //               marginTop: "4px",
        //             }}
        //           >
        //             <div
        //               className="progress_bar"
        //               style={{
        //                 width:
        //                   parseFloat(line.volumetry.progression.budget) > 0 &&
        //                   parseFloat(line.volumetry.progression.real) > 0
        //                     ? parseFloat(line.volumetry.progression.real) >
        //                       parseFloat(line.volumetry.progression.budget)
        //                       ? `100%`
        //                       : `${
        //                           (parseFloat(line.volumetry.progression.real) *
        //                             100) /
        //                           parseFloat(line.volumetry.progression.budget)
        //                         }%`
        //                     : 0,
        //                 background:
        //                   parseFloat(line.volumetry.progression.real) >
        //                   parseFloat(line.volumetry.progression.budget)
        //                     ? "#DE4848"
        //                     : "#06D9B1",
        //               }}
        //             />
        //             <div className="progress_total">
        //               {line.volumetry.progression.budget} p
        //             </div>
        //             <div
        //               className="progress_index"
        //               style={{
        //                 width:
        //                   parseFloat(line.volumetry.progression.budget) > 0 &&
        //                   parseFloat(line.volumetry.progression.real) > 0
        //                     ? parseFloat(line.volumetry.progression.real) >
        //                       parseFloat(line.volumetry.progression.budget)
        //                       ? `100%`
        //                       : `${
        //                           (parseFloat(line.volumetry.progression.real) *
        //                             200) /
        //                           parseFloat(line.volumetry.progression.budget)
        //                         }%`
        //                     : 0,
        //               }}
        //             >
        //               <div>{parseInt(line.volumetry.progression.real)}p</div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="tags">
        //       <div className="labled-tag">
        //         <span>{_("ecart")}</span>
        //         <div
        //           className={`tag ${
        //             line.volumetry.ecart > 0
        //               ? "tag-danger"
        //               : line.volumetry.ecart === 0
        //               ? "tag-success"
        //               : "tag-warning"
        //           }`}
        //         >
        //           <div>
        //             {line.volumetry.ecart > 0 && "+"}
        //             {line.volumetry.ecart}
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // ) : (
        //   <div className="tags" style={{ margin: "20px 0px 10px" }}>
        //     <div className="labled-tag no-border">
        //       <span>{_("realized")}</span>
        //       <div className="tag">
        //         <div>{line.volumetry.progression.real} p</div>
        //       </div>
        //     </div>
        //   </div>
        // );
        return (
          <div className="tags" style={{ margin: "20px 0px 10px 10px" }}>
            <div className="labled-tag no-border">
              <span>{_("realized")}</span>
              <div className="tag">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="tags"
            style={{ margin: "20px 10px 10px 10px" }}
            onClick={() => prepareExpandedClient(line.id)}
          >
            {Object.keys(line.timesheet).map((key) => {
              return (
                <div className="labled-tag">
                  <span>
                    {_(key)}
                    {"ecart" === key && (
                      <div
                        style={{
                          padding: "0px",
                          height: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (!sortApplication) {
                            sortClientBy("ecart_asc");
                            setSortApplication("ecart_asc");
                          } else {
                            sortClientBy(
                              sortApplication === "ecart_desc"
                                ? "ecart_asc"
                                : "ecart_desc"
                            );
                            setSortApplication(
                              sortApplication === "ecart_desc"
                                ? "ecart_asc"
                                : "ecart_desc"
                            );
                          }
                        }}
                      >
                        {sortApplication === "ecart_desc" ? (
                          <TiArrowSortedUp />
                        ) : (
                          <TiArrowSortedDown />
                        )}
                      </div>
                    )}
                  </span>
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (line.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                  >
                    <div>
                      {"ecart" === key && (line.timesheet[key] > 0 ? "+" : "-")}
                      {ENCODING_TYPE.includes(line.title.label) && key === "pc"
                        ? "-"
                        : key === "pc"
                        ? line.timesheet[key] + "%"
                        : renderDurationDisplay(line.timesheet[key])}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
    } else {
      if (column.id === "volumetry") {
        return (
          <Fragment>
            <div
              className="tags"
              onClick={() => prepareExpandedClient(line.id)}
            >
              <div className="tag no-border">
                <div>{line.volumetry.progression.real} p</div>
              </div>
            </div>
          </Fragment>
        );
        // return filter.period && "MONTHLY" !== filter.period.id ? (
        //   <Fragment>
        //     <div
        //       onClick={() => prepareExpandedClient(line.id)}
        //       style={{ display: "flex", margin: "0px 5px 0px 15px" }}
        //     >
        //       <div
        //         style={{
        //           justifyContent: "center",
        //           flexDirection: "column",
        //           display: "flex",
        //           alignItems: "center",
        //           width: "100%",
        //           marginRight: 15,
        //         }}
        //       >
        //         <div className="progress-bar" style={{ marginTop: "3px" }}>
        //           <div
        //             style={{
        //               height: "28px",
        //               display: "flex",
        //               justifyContent: "center",
        //               alignItems: "center",
        //               backgroundColor: "transparent",
        //             }}
        //           >
        //             <div className="progress_container">
        //               <div
        //                 className="progress_bar"
        //                 style={{
        //                   width:
        //                     parseFloat(line.volumetry.progression.budget) > 0 &&
        //                     parseFloat(line.volumetry.progression.real) > 0
        //                       ? parseFloat(line.volumetry.progression.real) >
        //                         parseFloat(line.volumetry.progression.budget)
        //                         ? `100%`
        //                         : `${
        //                             (parseFloat(
        //                               line.volumetry.progression.real
        //                             ) *
        //                               100) /
        //                             parseFloat(
        //                               line.volumetry.progression.budget
        //                             )
        //                           }%`
        //                       : 0,
        //                   background:
        //                     parseFloat(line.volumetry.progression.real) >
        //                     parseFloat(line.volumetry.progression.budget)
        //                       ? "#DE4848"
        //                       : "#06D9B1",
        //                 }}
        //               />
        //               <div className="progress_total">
        //                 {line.volumetry.progression.budget} p
        //               </div>
        //               <div
        //                 className="progress_index"
        //                 style={{
        //                   width:
        //                     parseFloat(line.volumetry.progression.budget) > 0 &&
        //                     parseFloat(line.volumetry.progression.real) > 0
        //                       ? parseFloat(line.volumetry.progression.real) >
        //                         parseFloat(line.volumetry.progression.budget)
        //                         ? `100%`
        //                         : `${
        //                             (parseFloat(
        //                               line.volumetry.progression.real
        //                             ) *
        //                               200) /
        //                             parseFloat(
        //                               line.volumetry.progression.budget
        //                             )
        //                           }%`
        //                       : 0,
        //                 }}
        //               >
        //                 <div>{parseInt(line.volumetry.progression.real)}p</div>
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //       <div className="tags">
        //         <div
        //           className={`tag ${
        //             line.volumetry.ecart > 0
        //               ? "tag-danger"
        //               : line.volumetry.ecart === 0
        //               ? "tag-success"
        //               : "tag-warning"
        //           }`}
        //         >
        //           <div>{line.volumetry.ecart}</div>
        //         </div>
        //       </div>
        //     </div>
        //   </Fragment>
        // ) : (
        //   <Fragment>
        //     <div
        //       className="tags"
        //       onClick={() => prepareExpandedClient(line.id)}
        //     >
        //       <div className="tag no-border">
        //         <div>{line.volumetry.progression.real} p</div>
        //       </div>
        //     </div>
        //   </Fragment>
        // );
      } else {
        return (
          <Fragment>
            <div
              className="tags"
              onClick={() => prepareExpandedClient(line.id)}
            >
              {Object.keys(line.timesheet).map((key) => {
                return (
                  <div
                    className={`tag ${
                      "ecart" === key &&
                      (line.timesheet[key] <= 0 ? "tag-success" : "tag-danger")
                    }`}
                  >
                    <div
                      style={{
                        color:
                          key === "pc" && line.timesheet[key] > 15
                            ? "#DE4848"
                            : "#6D7F92",
                      }}
                    >
                      {"ecart" === key && (line.timesheet[key] > 0 ? "+" : "-")}
                      {ENCODING_TYPE.includes(line.title.label) && key === "pc"
                        ? "-"
                        : key === "pc"
                        ? line.timesheet[key] + "%"
                        : renderDurationMinutesDisplay(line.timesheet[key])}
                    </div>
                    {line.title.softwareCalcul === "default" &&
                      key === "theoric" && (
                        <TooltipAntd
                          key={`tab-20}`}
                          placement="top"
                          title={_(
                            line.title.softwareCalcul === line.title.software
                              ? `defaultSoftwareMessage`
                              : `noSoftwareCoefficientsMessage`
                          )}
                        >
                          <div className="notif-pop" />
                        </TooltipAntd>
                      )}
                  </div>
                );
              })}
            </div>
          </Fragment>
        );
      }
    }
  };

  const handleTitle = (title) => {
    return (
      <Fragment>
        <div
          onClick={() => prepareExpandedClient(title.id)}
          className={`${
            title.title.label === "total"
              ? "matrix_title_header"
              : ENCODING_TYPE.includes(title.title.label)
              ? "matrix_sub_title"
              : "matrix_title"
          }`}
          id={
            title.title.label === "total"
              ? "total-line"
              : expandedClient === title.id
              ? "expanded"
              : ENCODING_TYPE.includes(title.title.label)
              ? `sub-line-${title.title.label}`
              : ""
          }
        >
          <div className="title_infos">
            {title.title.label !== "total" &&
              !ENCODING_TYPE.includes(title.title.label) && (
                <div className={title.title.state}>
                  <ArrowLeftIcon width={9} height={7} fill="#FFFFFF" />
                </div>
              )}
            <div>
              {ENCODING_TYPE.includes(title.title.label)
                ? _(title.title.label)
                : title.title.label}
            </div>
          </div>

          {title.title.label !== "total" &&
            !ENCODING_TYPE.includes(title.title.label) &&
            collaborator &&
            !collaborator.isDeleted && (
              <div className="actions">
                <Link to={`/${lng}/fiche-client/${title.id}`}>
                  <div className={`title_action action_doh`}>
                    <ShortcutIcon size="16" />
                  </div>
                </Link>
                <div
                  className={`title_action ${
                    title.title.state !== "danger" && title.title.count === 0
                      ? "action_doh"
                      : ""
                  }`}
                  style={{ width: title.title.count > 0 ? "" : "51%" }}
                  onClick={() => setShowSideBar(title)}
                >
                  <FlagIcon />
                  <div>{title.title.count > 0 ? title.title.count : "+"}</div>
                </div>
              </div>
            )}
        </div>
      </Fragment>
    );
  };

  const prepareExpandedClient = (id) => {
    if (typeof id === "string" && id.split("-").length > 1) {
      return;
    }
    if (id === "TOTAL") {
      return;
    }
    if (id === expandedClient) {
      const boxes = document.querySelectorAll(
        ".CustumedMatrix_matrix_body_line__11lbb"
      );

      boxes.forEach((box) => {
        box.classList.remove("sub_line");
        box.classList.remove("last_sub_line");
        box.classList.remove("expanded_line");
      });
      setFiltredContribution(contribution);
      setFiltredProgression(progression.global);
      setExpandedClient(null);
    } else {
      let result = [];
      contribution.forEach((item) => {
        result.push(item);
        if (item.id === id) {
          Object.keys(item.children).forEach((child) => {
            result.push(item.children[child]);
          });
        }
      });
      setExpandedClient(id === expandedClient ? null : id);
      setFiltredProgression(progression[id]);
      setFiltredContribution(result);
      if (document.getElementById("expanded")) {
        setTimeout(() => {
          document
            .getElementById("expanded")
            .parentElement.parentElement.classList.add("expanded_line");
          document
            .getElementById("sub-line-sales")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-purchases")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-financial_transactions_coda")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-financial_transactions_noncoda")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-caisse")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-remuneration")
            .parentElement.parentElement.classList.add("sub_line");
          document
            .getElementById("sub-line-remuneration")
            .parentElement.parentElement.classList.add("last_sub_line");
        }, "100");
      }
    }
  };

  //Chart functions
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          {Object.keys(payload[0].payload).map((key) => {
            if (!["name", "ecartSign"].includes(key)) {
              return (
                <p>
                  {`${_(key)} : ${
                    !isNaN(payload[0].payload[key])
                      ? renderDurationDisplay(
                          parseFloat(payload[0].payload[key])
                        )
                      : payload[0].payload[key]
                  }`}
                </p>
              );
            }
          })}
        </div>
      );
    }

    return null;
  };

  const renderChartsFrame = () => {
    let dataChart = [];
    if (!collaboratorContributionQuery.isFetching) {
      dataChart = Object.keys(filtredProgression).map((key) => {
        return {
          ...filtredProgression[key],
          label: moment()
            .month(parseInt(key.split("/")[1]) - 1)
            .format("MMM"),
          gap: parseFloat(
            filtredProgression[key].theoric - filtredProgression[key].real
          ).toFixed(2),
        };
      });
    }
    let name = "";
    contribution.forEach((line) => {
      if (line.id === expandedClient) {
        name = line.title.label;
      }
    });

    return (
      <div className="chart-pannel">
        <div className="chart-frame">
          <div className="title">
            {_("gapAnalyse")} {expandedClient ? `: ${name}` : ""}{" "}
          </div>
          <div className="labels">
            <div className="label">
              <div className="dot" />
              {_("theoric")}
            </div>
            <div className="label">
              <div className="dot" style={{ background: "#B2EEE3" }} />
              {_("realized")}
            </div>
            <div className="label">
              <div
                className="dot"
                style={{ background: "#06D9B1", marginRight: 3 }}
              />
              <div className="dot" style={{ background: "#EB5757" }} />
              {_("gap")}
            </div>
            <div className="label">
              <div className="line" />
              {_("Compensation de production")}
            </div>
          </div>
          <div className="body">
            <div className="body_titles">
              <div>
                {_("timesheet")} {"(h)"}
              </div>
              {expandedClient && (
                <div>
                  {_("compensation")} {"(%)"}
                </div>
              )}
            </div>
            <div className="bar_chart">
              <ComposedChart
                width={500}
                height={350}
                data={dataChart}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="1 1" vertical={false} />
                <XAxis
                  dataKey="label"
                  height={60}
                  interval={0}
                  tick={{ fontWeight: 400 }}
                />
                <YAxis type="number" tickLine={false} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="theoric" barSize={26} fill="#D8DDE2" />
                <Bar dataKey="real" stackId="a" barSize={26} fill="#B2EEE3" />
                <Bar dataKey="gap" fill="#EB5757" stackId="a" barSize={26}>
                  {dataChart.length > 0 &&
                    dataChart.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={entry.gap > 0 ? "#06D9B1" : "#EB5757"}
                      />
                    ))}
                </Bar>
              </ComposedChart>
            </div>
            {expandedClient && (
              <div className="line_chart">
                <ComposedChart
                  width={500}
                  height={350}
                  data={dataChart}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <YAxis type="number" tickLine={false} />
                  <XAxis
                    dataKey="labelo"
                    interval={0}
                    height={60}
                    tick={{ fontWeight: 400 }}
                    padding={{ left: 17, right: 17 }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="cp"
                    stroke="#29394D"
                    dot={false}
                  />
                </ComposedChart>
              </div>
            )}
          </div>
        </div>
        <div className="chart-frame">
          <div className="title">
            {_("productionAnalysePerPiece")}
            {expandedClient ? `: ${name}` : ""}
          </div>
          <div className="labels">
            <div className="label">
              <div className="dot" style={{ background: "#06D9B1" }} />
              {_("realized")}
            </div>
            <div className="label">
              <div className="line" />
              {_("cp")}
            </div>

            <div className="label"></div>
            <div className="label"></div>
          </div>
          <div className="body">
            <div className="body_titles">
              <div>{_("timesheet")} (h)</div>
              {expandedClient && <div>{_("compensation")} (%)</div>}
            </div>
            <div className="bar_chart">
              <ComposedChart
                width={500}
                height={350}
                data={dataChart}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid strokeDasharray="1 1" vertical={false} />
                <XAxis
                  dataKey="label"
                  interval={0}
                  height={60}
                  tick={{ fontWeight: 400 }}
                  padding={{ left: 17, right: 17 }}
                />
                <YAxis type="number" tickLine={false} />
                <Line
                  dataKey="real"
                  stroke="#06D9B1"
                  strokeWidth={2}
                  fill="#06D9B1"
                />
              </ComposedChart>
            </div>
            {expandedClient && (
              <div className="line_chart">
                <ComposedChart
                  width={500}
                  height={350}
                  data={dataChart}
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <YAxis type="number" tickLine={false} />
                  <XAxis
                    dataKey="label"
                    iterval={0}
                    height={60}
                    tick=""
                    padding={{ left: 50, right: 50 }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="cp"
                    stroke="#29394D"
                    dot={false}
                  />
                </ComposedChart>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  //Sidebar functions
  const renderAddBubble = () => {
    return (
      <div className="setting-bubbles">
        <div
          className={`circleSvg 
            ${showConfigSideBar ? "active opened" : ""}`}
          onClick={() => setShowConfigSideBar(true)}
        >
          <SettingsIcon size="20" fill="#6D7F92" />
          <span style={{ paddingLeft: "2.5rem" }}>{_("coefficients")}</span>
        </div>
      </div>
    );
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar && "display"}`}>
          <CollaboratorCorrectionCard
            organization={organization}
            category={filter.category}
            token={token}
            year={year}
            loggedAs={loggedAs}
            creator={user}
            onClose={() => {
              setShowSideBar(null);
            }}
            client={showSideBar}
            lng={lng}
          />
        </div>
      </div>
    );
  };

  const renderConfigSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar config-sid ${showConfigSideBar && "display"}`}>
          <VolumetryConfigContent
            collaborator={collaborator}
            organization={organization}
            category={filter.category}
            token={token}
            onClose={() => setShowConfigSideBar(null)}
          />
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      {renderPannelFrame()}
      {renderChartsFrame()}
      {showSideBar && renderSideBarFrame()}
      {showConfigSideBar && renderConfigSideBarFrame()}
    </Fragment>
  );
};

export default EncodingProgressionContent;
