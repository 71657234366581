import React, { Fragment, useState, useRef } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import _ from "../../../i18n";
import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DndContext,
  closestCorners,
  DragOverlay,
  defaultDropAnimation,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove } from "@dnd-kit/sortable";

import { getMonthShortLabelObject, renderAvatar } from "../../../utils";
import {
  getPlans,
  getCollaboratorAvatars,
  saveDevelopementCardPlan,
  saveCorrectionPlan,
  getDevelopementCard,
  sendGenericEmail,
} from "../../../api";
import { BOARD_SECTIONS, TTP_UA_URL } from "../../../config";

import { FilterIcon } from "../../common/Icons";
import CollaboratorPlansBoardFilter from "./CollaboratorPlansBoardFilter";
import PlansBoardColumn from "./PlansBoardColumn";

import "../CollaboratorSpace.local.scss";
import "./CollaboratorPlansBoard.local.scss";
import CollaboratorCorrectionCard from "../ProductionTracking/EncodingProgression/CollaboratorCorrectionCard";
import CollaboratorDevelopmentCard from "../../Collaborators/CollaboratorDevelopmentCard";
import { useSelector } from "react-redux";

const CollaboratorPlansBoard = ({
  id,
  year,
  lng,
  token,
  loggedAs,
  organization,
  hasAccess
}) => {
  //Header
  const [filtersCount, setFiltersCount] = useState(0);
  const [filter, setFilter] = useState({
    filterTab: [
      {
        id: "MONTHLY",
        label: "monthly",
        subTab: getMonthShortLabelObject(lng),
      },
      {
        id: "TERMLY",
        label: "termly",
        subTab: [
          {
            id: 1,
            label: "1er trimestre (du 01/01 au 01/04)",
            start: "01-01",
            end: "03-31",
            disabled: false,
          },
          {
            id: 2,
            label: "2ème trimestre (du 01/04 au 30/06)",
            start: "04-01",
            end: "06-30",
            disabled: moment().quarter() < 2 && moment().year() == year,
          },
          {
            id: 3,
            label: "3ème trimestre (du 01/06 au 01/09)",
            start: "07-01",
            end: "09-30",
            disabled: moment().quarter() < 3 && moment().year() == year,
          },
          {
            id: 4,
            label: "4ème trimestre (du 01/09 au 01/12)",
            start: "10-01",
            end: "12-31",
            disabled: moment().quarter() < 4 && moment().year() == year,
          },
        ],
      },
      { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    ],
    period: { id: "ANNUAL", label: "annual", start: "01-01", end: "12-31" },
    collaborators: id ? [id] : [],
    responsables: id ? [id] : [],
    subPeriod: null,
    countFilter: 0,
  });

  const { user, navCommunity } = useSelector((state) => state.auth);
  const preferences =
    user &&
    navCommunity &&
    user.userOrganizations &&
    user.userOrganizations.length > 0
      ? user.userOrganizations.find(
          (organization) => organization.id === navCommunity.id
        ).authorisation.preferences
      : {};
  //Body
  const [planSections, setPlanSections] = useState([]);
  const [plans, setPlans] = useState([]);
  const [avatarCollaborators, setAvatarCollaborators] = useState([]);
  const [activePlanId, setActivePlanId] = useState(null);
  const [showSideBar, setShowSideBar] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const planSectionsRef = useRef(planSections);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const result = useQuery({
    queryKey: ["getAllPlans", token, filter, year],
    queryFn: async () => {
      let { start, end } =
        "ANNUAL" === filter.period.id ? filter.period : filter.subPeriod;

      if (token) {
        try {
          const response = await Promise.all([
            getPlans(token, organization, filter.responsables, {
              start,
              end,
              year,
              zone: filter.zone ? filter.zone.value : null,
              client: filter.client,
              priority: filter.priority,
              anomaly: filter.anomaly,
              reasons: filter.reasons,
            }),
            getDevelopementCard(filter.collaborators, organization, token, {
              year,
              zone: filter.zone ? filter.zone.value : null,
              priority: filter.priority,
              responsables: filter.responsables,
            }),
          ]);
          const [responsePlans, responseDevPlans] = await response;
          let devPlans = [];
          responseDevPlans.data.data.forEach((item) => {
            item.plans.forEach((plan) => {
              devPlans.push({
                collaborator: item.collaborator,
                card: item.id,
                id: `dev-${plan.id}`,
                plan: plan,
              });
            });
          });
          let plans = responsePlans.data.data.map((item) => {
            return { ...item, id: `cor-${item.id}` };
          });
          if (filter.cardType === "cor_card") {
            setPlans(plans);
            setPlanSections(preparePlansSection(plans));
          } else if (filter.cardType === "dev_card") {
            setPlans(devPlans);
            setPlanSections(preparePlansSection(devPlans));
          } else {
            setPlans([...plans, ...devPlans]);
            setPlanSections(preparePlansSection([...plans, ...devPlans]));
          }
        } catch (e) {
          return [];
        }
      }
    },
  });

  let collaboratorAvatarsQuery = useQuery(
    ["collaboratorAvatarsQuery", organization],
    async () => {
      if (token) {
        try {
          const response = await getCollaboratorAvatars(organization, token);
          setAvatarCollaborators(response.data.data);
        } catch (e) {
          setAvatarCollaborators([]);
          return null;
        }
      }
    }
  );

  const handleSavePlan = async (item) => {
    try {
      const resp = !item.collaborator
        ? await saveCorrectionPlan({
            token,
            organization,
            plan: item.plan,
            card: item.card,
            user: user ? user.firstName + " " + user.lastName : "",
          })
        : await saveDevelopementCardPlan({
            card: item.card,
            year,
            token,
            organization,
            plan: item.plan,
            collaborator: item.collaborator.id,
            user: user ? user.firstName + " " + user.lastName : "",
          });
      return resp.data.statusCode === 200;
      // toast.success(_("saveSuccess"));
    } catch (e) {
      // toast.error(_("savingError"), { autoClose: true });
      return false;
    }
  };

  const preparePlansSection = (data) => {
    const boardSections = {};
    Object.keys(BOARD_SECTIONS).forEach((key) => {
      boardSections[key] = data.filter((plan) => plan.plan.progression === key);
    });
    return boardSections;
  };

  const renderFilterFrame = () => {
    return (
      <div className="filter-frame">
        <div className="principal-line">
          <div className="interval-date-frame" />
          <div className="mini-tabs-frame">
            {filter.filterTab.map((item) => (
              <div
                className={
                  item.id === filter.period.id && "mini-tabs-frame_active"
                }
                onClick={() => {
                  setFilter({
                    ...filter,
                    period: item,
                    subPeriod: "ANNUAL" !== item.id ? item.subTab[0] : null,
                  });
                }}
              >
                {_(item.label)}
              </div>
            ))}
          </div>
          <button
            className={`filter-button ${
              filterVisible ? "filter-button_open" : ""
            }`}
            onClick={() => setFilterVisible(!filterVisible)}
          >
            {_("filter")}
            {filtersCount > 0 ? (
              <span>{filtersCount}</span>
            ) : (
              <FilterIcon className="filter-icon" />
            )}
          </button>
        </div>

        {"ANNUAL" !== filter.period.id && (
          <div className="additional-line">
            {filter.period.subTab.map((tab) => {
              return (
                <div
                  className={`tag ${
                    filter.subPeriod.id === tab.id ? "active" : ""
                  } ${
                    ((tab.disabled && filter.period.id === "TERMLY") ||
                      (filter.period.id === "MONTHLY" &&
                        moment().year() == year &&
                        tab.id > moment().month() + 1)) &&
                    "disabled"
                  }`}
                  onClick={() =>
                    !tab.disabled
                      ? setFilter({ ...filter, subPeriod: tab })
                      : null
                  }
                >
                  {tab.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  const renderBoardFrame = () => {
    const activePlan = activePlanId
      ? plans.find((plan) => plan.id === activePlanId)
      : null;

    return (
      <div>
        <div className="board-frame">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={({ active }) => setActivePlanId(active.id)}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            {Object.keys(BOARD_SECTIONS).map((item) => {
              return (
                <PlansBoardColumn
                  id={item}
                  lng={lng}
                  plans={planSections[item]}
                  avatars={avatarCollaborators}
                />
              );
            })}
            <DragOverlay dropAnimation={defaultDropAnimation}>
              {activePlan ? (
                <div className="card-board">
                  {activePlan.collaborator && (
                    <div className="avatar-frame">
                      <div className="avatar" key={"item.uid"}>
                        {renderAvatar(
                          collaboratorAvatarsQuery[activePlan.collaborator.uid]
                            ? collaboratorAvatarsQuery[
                                activePlan.collaborator.uid
                              ].avatarUrl
                            : null,
                          activePlan.collaborator.name
                        )}
                      </div>
                      <div className="details">
                        <span>{activePlan.collaborator.name}</span>
                        <span>GD</span>
                      </div>
                    </div>
                  )}
                  <div className="card-board_title">
                    {activePlan.collaborator
                      ? activePlan.plan.title
                      : activePlan.reason}
                  </div>
                  {!activePlan.collaborator && (
                    <Fragment>
                      <div className="card-board_sub_title">
                        {activePlan.plan.title}
                      </div>
                      <div className="card-board_infos">
                        {_("folder")}:{" "}
                        <span>{activePlan.client.title.label}</span>
                      </div>
                      <div className="card-board_infos">
                        {_("category")}:{" "}
                        <span>{activePlan.category.label}</span>
                      </div>
                    </Fragment>
                  )}

                  <div className="card-board_description">
                    {activePlan.plan.description}
                  </div>
                  <div className="card-board_lower">
                    <div className="avatars">
                      {activePlan.plan.responsables &&
                        activePlan.plan.responsables.map((item) => (
                          <div className="avatar" key={item.uid}>
                            {renderAvatar(
                              collaboratorAvatarsQuery[item.uid]
                                ? collaboratorAvatarsQuery[item.uid].avatarUrl
                                : null,
                              item.name
                            )}
                          </div>
                        ))}
                    </div>
                    <div className="tag">{activePlan.plan.priorityLevel}</div>
                  </div>
                  <div className="card-board_footer">
                    <div className="ago">
                      {moment(activePlan.plan.createdAt, "YYYY-MM-DD hh:mm:ss")
                        .locale(lng)
                        .fromNow()}
                    </div>
                    <div className="flags"></div>
                  </div>
                </div>
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    );
  };

  const handleDragOver = ({ active, over }) => {
    // Mettez à jour la référence
    planSectionsRef.current = { ...planSections };
    // Find the containers
    const activeColumn = findBoardSectionColumn(planSections, active.id);
    const overColumn = findBoardSectionColumn(planSections, over?.id);
    if (!activeColumn || !overColumn || activeColumn === overColumn) {
      return;
    }
    if (
      !["SUPERADMIN", "ADMIN", "MANAGER"].includes(loggedAs) &&
      activeColumn === "VALIDATION" &&
      overColumn === "DONE"
    ) {
      return;
    }
    if (
      preferences &&
      ((activeColumn === "TODO" &&
        preferences.allowTodragAndDropFromTODO === "1") ||
        (activeColumn === "WIP" &&
          preferences.allowTodragAndDropFromWIP === "1") ||
        (activeColumn === "VALIDATION" &&
          preferences.allowTodragAndDropFromVALIDATION === "1") ||
        (activeColumn === "DONE" &&
          preferences.allowTodragAndDropFromDONE === "1"))
    ) {
      setPlanSections((planSection) => {
        const activeItems = planSection[activeColumn];
        const overItems = planSection[overColumn];

        // Find the indexes for the items
        const activeIndex = activeItems.findIndex(
          (item) => item.id === active.id
        );
        const overIndex = overItems.findIndex((item) => item.id !== over?.id);

        return {
          ...planSection,
          [activeColumn]: [
            ...planSection[activeColumn].filter(
              (item) => item.id !== active.id
            ),
          ],
          [overColumn]: [
            ...planSection[overColumn].slice(0, overIndex),
            planSection[activeColumn][activeIndex],
            ...planSection[overColumn].slice(overIndex),
          ],
        };
      });
    }
  };

  const handleDragEnd = ({ active, over }) => {
    // Utilisez planSectionsRef.current pour les deux containers
    const activeContainer = findBoardSectionColumn(
      planSectionsRef.current,
      active.id
    );
    const overContainer = findBoardSectionColumn(
      planSectionsRef.current,
      over?.id
    );

    const activePlan = activePlanId
      ? plans.find((plan) => plan.id === activePlanId)
      : null;
    if (!overContainer || activeContainer === overContainer) {
      if (activeContainer) {
        setShowSideBar({
          ...activePlan,
          id: parseInt(activePlan.id.split("-")[1]),
        });
      }
      return;
    }
    {
      console.log("activePlan.plan==========", activePlan.plan);
    }
    handleSavePlan({
      collaborator: activePlan.collaborator,
      card: activePlan.card,
      plan: {
        ...activePlan.plan,
        progression: overContainer,
        deadLine: activePlan.plan.deadLine
          ? activePlan.plan.deadLine.date.split(".")[0]
          : null,
      },
    }).then((response) => {
      console.log("response=========pppppp", response);
      if (response === true) {
        console.log("activePlan.plan====pppppp", activePlan.plan);
        if (overContainer == "DONE") {
          sendEmailToResponsable(
            activePlan.plan?.responsables,
            activePlan.client,
            overContainer
          );
        } else if (overContainer == "VALIDATION") {
          let responsables = [
            {
              id: 528409,
              name: "Yousra Berradi",
              email: "yousra.berradi@oam.ma",
            },
            {
              id: 458410,
              name: "Yassine El Bekkali",
              email: "yassine.el.bekkali21@gmail.com",
            },
            {
              id: 470189,
              name: "Safae El Jemli",
              email: "eljemlisafae@gmail.com",
            },
          ];

          sendEmailToResponsable(
            responsables,
            activePlan.client,
            overContainer
          );
        }
        if (
          !activeContainer ||
          !overContainer ||
          activeContainer === overContainer
        ) {
          return;
        }
        const activeIndex = planSections[activeContainer].findIndex(
          (task) => task.id === active.id
        );
        const overIndex = planSectionsRef.current[overContainer].findIndex(
          (task) => task.id === over?.id
        );
        if (activeIndex !== overIndex) {
          setPlanSections((boardSection) => ({
            ...boardSection,
            [overContainer]: arrayMove(
              boardSection[overContainer],
              activeIndex,
              overIndex
            ),
          }));
        }
        setActivePlanId(null);
      } else {
        // toast.error(_("savingError"), { autoClose: true });
        return;
      }
    });
  };

  const sendEmailToResponsable = async (responsables, client, status) => {
    const recipients = {};
    const dynamicFields = {};
    const promises = responsables.map(async (responsable) => {
      console.log("responsable=======", responsable);
      try {
        recipients[responsable.apiId ?? "470189"] = responsable?.email ?? "";
        dynamicFields["{{RECIPIENT}}"] = responsable.name;

        if (status === "DONE") {
          dynamicFields[
            "{{OBJET}}"
          ] = `Bravo ! Votre plan de correction concernant le dossier <u>${client.name}</u> a été validé.`;
        } else if (status === "VALIDATION") {
          dynamicFields[
            "{{OBJET}}"
          ] = `Un plan de correction concernant le dossier <u>${client.name}</u> est en attente de validation.`;
        }
        dynamicFields["{{ORGANIZATION.NAME}}"] = navCommunity.name;
        dynamicFields["FIDUCIARY_LOGO"] = navCommunity.avatarUrl;
        dynamicFields["{{NOTIF_URL}}"] = `${TTP_UA_URL}/user-space`;
      } catch (error) {
        return null;
      }
    });

    await Promise.all(promises);

    sendGenericEmail(
      token,
      recipients,
      "POWERTEAM_NOTIFICATION",
      lng,
      dynamicFields,
      organization
    );
  };

  const findBoardSectionColumn = (boardSections, id) => {
    if (id in boardSections) {
      return id;
    }

    const container = Object.keys(boardSections).find((key) =>
      boardSections[key].find((item) => item.id === id)
    );
    return container;
  };

  const renderSideBarFrame = () => {
    return (
      <div className="fadePage">
        <div className={`sidebar ${showSideBar && "display"}`}>
          {showSideBar.collaborator ? (
            <CollaboratorDevelopmentCard
              lng={lng}
              year={year}
              organization={organization}
              collaborator={showSideBar.collaborator}
              token={token}
              onClose={() => setShowSideBar(null)}
              user={user ? user.firstName + " " + user.lastName : ""}
            />
          ) : (
            <CollaboratorCorrectionCard
              organization={organization}
              category={showSideBar.category}
              token={token}
              year={showSideBar.plan.createdAt.split("-")[0]}
              onClose={() => {
                setShowSideBar(null);
              }}
              creator={user}
              plan={showSideBar.id}
              client={showSideBar.client}
              lng={lng}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {console.log("hasAccess===", hasAccess)}
      { hasAccess ? (
        <div>
        {renderFilterFrame()}
        <div className="production-frame">
          <CollaboratorPlansBoardFilter
            id={id ? id : null}
            year={year}
            organization={organization}
            token={token}
            filterVisible={filterVisible}
            filter={filter}
            updateFilter={setFilter}
            updateFiltersCount={setFiltersCount}
            fetching={result.isFetching}
          />
          {result.isFetching ? (
            <div className="ripple_loading">
              <img src={"/img/Ripple-1s-150px.svg"} alt="" />
            </div>
          ) : (
            renderBoardFrame()
          )}
        </div>
        {showSideBar && renderSideBarFrame()}
      </div>
      ) : ""}
    </Fragment>
  );
};

export default CollaboratorPlansBoard;
