import axios from "axios";
import moment from "moment";

import { TTP_API_URL } from "../config";

export const getClientDetails = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/details/${id}`;
  //TODO

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getClientProgressionBudget = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/budget/client/progression`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId: organization,
      client: id,
      year: data.year,
      category: data.category.id
    },
  });
};

export const getClientActivities = (id, organization, token, data) => {
  const requestUrl = `${TTP_API_URL}/team/client/activities`;

  let params = {
    access_token: token,
    organization_id: organization,
    organizationId: organization,
    year: data.year,
    client: id,
    lng: data.language,
    after: moment().subtract(7, "d").format("YYYY-MM-DD"),
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getVolumetryCliets = ( token, organization, date, filter) => {
  const requestUrl = `${TTP_API_URL}/team/clients/suivi-prestation`;

  let params = {
    access_token: token,
    organizationId: organization,
    collaborator: filter.collaborator,
    date: date,
    period: filter.period.id
  };

  params.startDate = filter.start ? filter.start : "";
  params.endDate = filter.end ? filter.end : "";

  params.limit = filter.pageSize || 10;
  params.start = !filter.paginationPage
    ? 0
    : (filter.paginationPage - 1) * filter.pageSize;

  return axios.get(requestUrl, {
    params,
  });
};

export const printPrestationInvoice = async (token, organizationId, date, number, collaborators) => {
  const requestUrl = `${TTP_API_URL}/team/clients/suivi-prestation/print`;

  const response = await axios.get(requestUrl, {
    params: {
      access_token: token,
      organizationId,
      date,
      invoiceNumber: number,
      collaborators: JSON.stringify(collaborators),
    },
    responseType: "blob",
    headers: { Accept: "application/pdf" }
  });

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: "application/pdf" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Facture M${moment(date).format("m-y")}.pdf`
  );
  document.body.appendChild(link);
  link.click();
};


export const exportClientsVolumetry = async (
  token,
  organizationId,
) => {
  const requestUrl = `${TTP_API_URL}/team/export/clients-volumetry`;

  const response = await axios
    .get(requestUrl, {
      params: {
        access_token: token,
        organizationId,
      },
      responseType: "blob",
      headers: { Accept: "application/vnd.ms-excel" }
    });
  const url_2 = window.URL.createObjectURL(
    new Blob([response.data], { type: response.data.type })
  );
  const link = document.createElement("a");
  link.href = url_2;
  link.setAttribute(
    "download",
    "PowerTeam_Clients_Volumetry.xlsx"
  );
  document.body.appendChild(link);
  link.click();
}

export const getClientContactInfo = ({token, organizationId, clientId}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/${clientId}/contact-info`;

  let params = {
    access_token: token,
    organizationId,
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getNewClients = ({token, organizationId, start, filters}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/new-clients`;
  let params = {
    access_token: token,
    organizationId,
    year: filters.year,
    start,
    limit: filters.pageSize,
    bu: filters.bu,
    partner: filters.partner,
    collaborator: filters.collaborator,
    collaboratorRole: filters.role,
    clientGroup: filters.clientGroup,
  };

  return axios.get(requestUrl, { params });
}

export const saveNewClients = ({token, organizationId, items}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/new-clients`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("items", JSON.stringify(items));

  return axios.post(requestUrl, formData);
}

export const getOldClients = ({token, organizationId, start, filters}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/old-clients`;
  let params = {
    access_token: token,
    organizationId,
    year: filters.year,
    start,
    limit: filters.pageSize,
    bu: filters.bu,
    partner: filters.partner,
    collaborator: filters.collaborator,
    collaboratorRole: filters.role,
    clientGroup: filters.clientGroup,
  };

  return axios.get(requestUrl, { params });
}

export const saveOldClients = ({token, organizationId, items}) => {
  const requestUrl = `${TTP_API_URL}/team/clients/old-clients`;

  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  formData.append("items", JSON.stringify(items));

  return axios.post(requestUrl, formData);
}
